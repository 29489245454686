import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../basecomponent/base.component';
import { UserBookmark } from '../../../models/bookmarks/bookmark.model';
import { FeatureLayerService } from '../../../services/map/feature-layer.service';
import { BookmarksService } from '../../../services/bookmarks/bookmarks.service';
import { UserService } from '../../../services/user/user.service';
import { SharedService } from '../../../services/shared/shared.service';
import { DxTreeViewComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { Location } from '@angular/common';
import { Layer } from '../../../models/layers/layer.model';


@Component({
  selector: 'app-bookmarkadd',
  templateUrl: './bookmarkadd.component.html',
  styleUrls: ['./bookmarkadd.component.scss']
})
export class BookmarkAddComponent extends BaseComponent implements OnInit, OnDestroy {

  saveBookamrkID: any;
  newName: string;
  bookmarksList: UserBookmark[] = [];
  parentBookmarkID: string;
  showBookmarks: boolean = true;
  isSaving: boolean;

  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeBoxValue: any;
  isTreeBoxOpened: boolean;
  selectedTreeBookmark: UserBookmark;
  bookmarksTree: UserBookmark[] = [];

  @ViewChild('validationBookmarkData', { static: true })
  validationBookmarkData: DxValidationGroupComponent;

  constructor(
    private featureLayerService: FeatureLayerService,
    private bookmarksService: BookmarksService,
    private userService: UserService,
    private sharedService: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private location: Location
  ) {
    super();
  }

  ngOnInit() {

    this.parentBookmarkID = this.activatedRoute.snapshot.params['parentid'];
    if (this.parentBookmarkID) {
      this.showBookmarks = false;
    } else {
      this.bookmarksService.getListByRegionUser(this.sharedService.getRegionIDValue(), this.userService.User.id).then(result => {

        this.bookmarksList = result.filter(f => f.userID);

        this.bookmarksList.forEach(bm => {
          this.bookmarksTree.push(bm);
          bm.bookmarks?.forEach(child => {
            this.bookmarksTree.push(child);
          })
        })


      })
    }
    
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks

  }

  goBack() {    
    this.location.back();
  }

  goToBookmarks() {
    this.router.navigate(["/bookmarks"]);
  }

  save() {
    if (this.newName?.length > 0) {
      let bookmark = new UserBookmark();
      bookmark.regionID = this.sharedService.getRegionIDValue();
      bookmark.status = 'Приватний';
      bookmark.name = this.newName;
      bookmark.parentID = this.parentBookmarkID;
      bookmark.isStory = this.parentBookmarkID ? true : false;
      bookmark.userID = this.userService.User.id;
      bookmark = this.featureLayerService.collectBookmark(bookmark);

      this.bookmarksService.saveUserBookmark(bookmark).then(result => {
        notify(`Закладка \"${bookmark.name}\" успішно збережена`, "success", 3000);

        this.bookmarksService.getListByRegionUser(this.sharedService.getRegionIDValue(), this.userService.User.id).then(list => {

          this.bookmarksList = list;
          let item: UserBookmark;

          if (bookmark.parentID) {
            let parentBookmarkIndex = list.findIndex(f => f.id == bookmark.parentID);
            if (parentBookmarkIndex>=0) {
              let i = list[parentBookmarkIndex].bookmarks.findIndex(x => x.id == result.id);
              if (i >= 0) {
                item = list[parentBookmarkIndex].bookmarks[i];
              }
            }
          } else {
            let index = list.findIndex(f => f.id == result.id);
            if (index >= 0) {
              item = list[index];
            }
          }

          if (item) {
            //item = list[index];
            item.isSelected = true;
            this.sharedService.setSelectedBookmark(item);

            let layers = this.sharedService.getLayerList().getValue();

            layers.forEach(_layer => { //.filter(f => !!!f.inFilter)
              _layer.isSelected = item.details.find(x => x.layerID == _layer.id) != null;
              _layer.inBookmark = _layer.isSelected;
              let filter = this.sharedService.getCurrentFilterValue();
              if (filter?.layers?.find(x => x.layerID == _layer.id)) {
                _layer.isSelected = true;
                _layer.inFilter = _layer.isSelected;
                _layer.inBookmark = true;
              }
              this.selectChildren(_layer.layerChildren, item);
            })
          }

          this.sharedService.setUserBookmarkList(list);
          this.sharedService.setUserBookmarksInit(true);
          this.goToBookmarks();
        })
      })

    } else if (this.selectedTreeBookmark) {

      let bookmark = this.selectedTreeBookmark;// this.bookmarksList.find(f => f.id == this.saveBookamrkID);
      if (bookmark) {
        bookmark = this.featureLayerService.collectBookmark(bookmark);
      }

      //bookmark.
      this.bookmarksService.saveUserBookmark(bookmark).then(result => {
        notify(`Зміни закладки \"${bookmark.name}\" успішно збережені`, "success", 3000);
        bookmark.isSelected = true;
        this.sharedService.setSelectedBookmark(bookmark);
        this.bookmarksService.getListByRegionUser(this.sharedService.getRegionIDValue(), this.userService.User.id).then(list => {

          //let item: UserBookmark;

          if (bookmark.parentID) {
            let parentBookmarkIndex = list.findIndex(f => f.id == bookmark.parentID);
            if (parentBookmarkIndex>=0) {
              let i = list[parentBookmarkIndex].bookmarks.findIndex(x => x.id == bookmark.id);
              if (i >= 0) {
                list[parentBookmarkIndex].bookmarks[i] = bookmark;
              }
            }
          } else {
            let index = list.findIndex(f => f.id == result.id);
            if (index >= 0) {
              list[index] = bookmark;
            }
          }
          //let index = list.findIndex(z => z.id == bookmark.id);
          //if (index>=0) {
          //  list[index ]= bookmark;
          //}

          let layers = this.sharedService.getLayerList().getValue();
          layers.forEach(_layer => {  // .filter(f => !!!f.inFilter)
            _layer.isSelected = bookmark.details.find(x => x.layerID == _layer.id) != null;
            _layer.inBookmark = _layer.isSelected;
            let filter = this.sharedService.getCurrentFilterValue();
            if (filter?.layers?.find(x => x.layerID == _layer.id)) {
              _layer.isSelected = true;
              _layer.inFilter = _layer.isSelected;
              _layer.inBookmark = true;
            }
            this.selectChildren(_layer.layerChildren, bookmark);
          })
          this.sharedService.setUserBookmarkList(list);
          this.sharedService.setUserBookmarksInit(true);
          this.goToBookmarks();
        })
      }).catch(ex => {
        notify(`Під час збереження закладки сталася помилка.\r\n Помилка: "${ex._body}"`, "error", 3000);
      })
    } else {
      let result = this.validationBookmarkData.instance.validate();

      if (!result.isValid) {
        return;
      }
      //notify(`Введіть назву або оберіть закладку для збереження`, "warning", 3000);
    }
  }

  treeView_itemSelectionChanged(e) {
    this.treeBoxValue = e.component.getSelectedNodeKeys();
    this.selectedTreeBookmark = e.itemData;
  }

  syncTreeViewSelection() {
    if (!this.treeView) return;

    if (!this.treeBoxValue) {
      this.treeView.instance.unselectAll();
    } else {
      this.treeView.instance.selectItem(this.selectedTreeBookmark);
    }
    let ttt = this.treeView.instance;
  }

  onTreeBoxOptionChanged(e) {
    if (e.name === 'value') {
      this.isTreeBoxOpened = false;
      this.ref.detectChanges();
    }
  }

  selectChildren(layers: Layer[], selectedBookmark) {
    layers.forEach(_layer => {
      _layer.isSelected = selectedBookmark.details.find(x => x.layerID == _layer.id) != null;
      _layer.inBookmark = _layer.isSelected;
      let filter = this.sharedService.getCurrentFilterValue();
      if (filter?.layers?.find(x => x.layerID == _layer.id)) {
        _layer.isSelected = true;
        _layer.inFilter = _layer.isSelected;
        _layer.inBookmark = true;
      }
      this.selectChildren(_layer.layerChildren, selectedBookmark);
    })
  }

}
