import { Component, ReflectiveInjector } from '@angular/core';
import { ApplicationInsightsService } from '../../services/applicationinsights/application-insights.service'
import { ConfigService } from "../../services/shared/utils/config.service";

@Component({
  template: ''
})
export class BaseComponent {

  private myMonitoringService: ApplicationInsightsService;

  constructor() {
    // Manually retrieve the monitoring service from the injector 
    // so that constructor has no dependencies that must be passed in from child 
    //const injector = ReflectiveInjector.resolveAndCreate([ConfigService,
    //  ApplicationInsightsService
    //]);
    //this.myMonitoringService = injector.get(ApplicationInsightsService);
    //this.logNavigation();
  }

  private logNavigation() {
    if (this.myMonitoringService.IsConfigured)
      this.myMonitoringService.logPageView();
  }
} 
