import { Injectable } from '@angular/core';
import { HttpResponse,  HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointFactory } from '../shared/endpoint-factory.service';



@Injectable()
export class BookmarksEndpointService extends EndpointFactory {

  private readonly _getUserBookamrkByRegionUrl: string = "/api/userbookmark/userlistbyregion";
  private readonly _getPublicBookmarkDataUrl: string = "/api/publicbookmark/publicbookmark";
  private readonly _getPublicBookmarsByRegionUrl: string = "/api/publicbookmark/publicbookmarksbyregion";
  private readonly _getPublicBookmarsByParentUrl: string = "/api/publicbookmark/publicbookmarksbyparent";
  private readonly _getUserBookmarkSaveUrl: string = "/api/userbookmark/userbookmark";
  private readonly _getUserBookamrwithDetails: string = "/api/userbookmark/getwithdetail";

  get getUserBookamrkByRegionUrl() { return this.configService.getApiURI() + this._getUserBookamrkByRegionUrl; }
  get getPublicBookmarkDataUrl() { return this.configService.getApiURI() + this._getPublicBookmarkDataUrl; }
  get getPublicBookmarsByRegionUrl() { return this.configService.getApiURI() + this._getPublicBookmarsByRegionUrl; }
  get getPublicBookmarsByParentUrl() { return this.configService.getApiURI() + this._getPublicBookmarsByParentUrl; }
  get getUserBookmarkSaveUrl() { return this.configService.getApiURI() + this._getUserBookmarkSaveUrl; }
  get getUserBookamrwithDetails() { return this.configService.getApiURI() + this._getUserBookamrwithDetails; }

  getUserBookmarkByRegionEndpoint(regionId: string, userId: string): Promise<any> {
    let endpointUrl = `${this.getUserBookamrkByRegionUrl}/${regionId}/${userId}`;

    return this.http.get(endpointUrl, this.getAuthHeader())
      .toPromise().then((response: Response) => {
        return response;
      }).catch(error => {
        return this.handlePromiseError(error, () => this.getUserBookmarkByRegionEndpoint(regionId, userId));
      });
  }

  getPublicBookmarkDataEndpoint(Id: string): Promise<any> {
    let endpointUrl = `${this.getPublicBookmarkDataUrl}/${Id}`;
    let str = JSON.stringify({ Id });
    return this.http.post(endpointUrl, str)
      .toPromise().then((response: Response) => {
        return response;
      }).catch(error => {
        return this.handlePromiseError(error, () => this.getPublicBookmarkDataEndpoint(Id));
      });
  }

  getPublicBookmarskByRegionEndpoint(Id: string): Promise<any> {
    let endpointUrl = `${this.getPublicBookmarsByRegionUrl}/${Id}`;
    let str = JSON.stringify({ Id });
    return this.http.post(endpointUrl, str)
      .toPromise().then((response: Response) => {
        return response;
      }).catch(error => {
        return this.handlePromiseError(error, () => this.getPublicBookmarskByRegionEndpoint(Id));
      });
  }

  getListBookmarkByParentEndpoint(parentId: string): Promise<any> {
    let endpointUrl = `${this.getPublicBookmarsByParentUrl}/${parentId}`;

    let str = JSON.stringify({ parentId });
    return this.http.post(endpointUrl, str)
      .toPromise().then((response: Response) => {
        return response;
      }).catch(error => {
        return this.handlePromiseError(error, () => this.getListBookmarkByParentEndpoint(parentId));
      });
  }

  getSaveUserBookmarkEndpoint(userBookmark: any): Promise<any> {

    return this.http.post<any>(this.getUserBookmarkSaveUrl, JSON.stringify(userBookmark), this.getAuthHeader(true))
      .toPromise().then((response: Response) => {
        return response;
      })
      .catch(error => {
        return this.handlePromiseError(error,
          () => this.getSaveUserBookmarkEndpoint(userBookmark));
      });
  }

  getDeleteUserBookmarkEndpoint(userBookmarkId: string): Promise<any> {

    let endpointUrl = `${this.getUserBookmarkSaveUrl}/${userBookmarkId}`;

    return this.http.delete<any>(endpointUrl, this.getAuthHeader(true))
      .toPromise().then((response: Response) => {
        return response;
      })
      .catch(error => {
        return this.handlePromiseError(error, () => this.getDeleteUserBookmarkEndpoint(userBookmarkId));
      });
  }

  getWitDetails(Id: string): Promise<Response> {

    let endpointUrl = `${this.getUserBookamrwithDetails}/${Id}`;

    return this.http.get<any>(endpointUrl, this.getAuthHeader()).toPromise().then(response => {
      return response;
    })
      .catch(error => {
        return this.handlePromiseError(error, () => this.getWitDetails(Id));
      });
  }
}
