<div class="panel-header">
  <a (click)="goBack()" class="btn-icon">
    <span class="icon-arrow-left"></span>
  </a>
  <div class="dx-field dx-field--title">
    <dx-validation-group #validationFilterData>
      <dx-text-box [(value)]="bookmarkEdit.name" placeholder="Назва закладки" height="48">
        <dx-validator>
          <dxi-validation-rule type="required" message="Назва закладки обов'язкова"></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </dx-validation-group>
  </div>
</div>
<div class="w-scroll">
  <dx-scroll-view direction="vertical">
    <dx-html-editor [(value)]="bookmarkEdit.htmlText">
      <dxo-toolbar [multiline]="true">
        <dxi-item formatName="undo"></dxi-item>
        <dxi-item formatName="redo"></dxi-item>
        <dxi-item formatName="separator"></dxi-item>
        <dxi-item formatName="separator"></dxi-item>
        <dxi-item formatName="bold"></dxi-item>
        <dxi-item formatName="italic"></dxi-item>
        <dxi-item formatName="strike"></dxi-item>
        <dxi-item formatName="underline"></dxi-item>
        <dxi-item formatName="separator"></dxi-item>
        <dxi-item formatName="alignLeft"></dxi-item>
        <dxi-item formatName="alignCenter"></dxi-item>
        <dxi-item formatName="alignRight"></dxi-item>
        <dxi-item formatName="alignJustify"></dxi-item>
        <dxi-item formatName="separator"></dxi-item>
        <dxi-item formatName="orderedList"></dxi-item>
        <dxi-item formatName="bulletList"></dxi-item>
        <dxi-item formatName="separator"></dxi-item>
        <dxi-item formatName="header" [formatValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
        <dxi-item formatName="separator"></dxi-item>
        <dxi-item formatName="color"></dxi-item>
        <dxi-item formatName="background"></dxi-item>
        <dxi-item formatName="separator"></dxi-item>
      </dxo-toolbar>
    </dx-html-editor>
    <div class="bookmark-documents">
      <div class="dx-field">
        <div class="text-field">
          <div class="text-field-label">
            Документи
          </div>
          <div class="text-field-input">
            <app-document-upload (uploadEvent)="refreshFiles($event)" #documentUpload></app-document-upload>
          </div>
        </div>
        <div class="text-field">
          <ul class="bookmark-list">
            <li *ngFor="let doc of listDocuments" class="bookmark-list__item">
              <button type="button" class="btn-icon" (click)="deleteDoc(doc)">
                <span class="esri-icon-trash"></span>
              </button>
              <a (click)="downloadDoc(doc)" class="bookmark-list__link" title="{{doc}}"> {{doc}} </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </dx-scroll-view>
</div>
<div class="bookmarker-buttons">
  <!--<dx-button id="cancelBtn"
              text="Відмінити"
              type="normal"
              (onClick)="goBack()"
              [disabled]="isSaving">
  </dx-button>-->
  <dx-button id="saveBtn"
             width="100%"
              text="Готово"
              type="default"
              (onClick)="save()"
              [disabled]="isSaving">
  </dx-button>
</div>
