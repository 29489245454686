import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { AzureStorageService } from '../../services/azurestorage/azure-storage.service';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document.upload.component.html',
  styleUrls: ['./document.upload.component.scss']
})
export class DocumentUploadComponent  {

  @ViewChild('documentInput', { static: false }) documentInput: ElementRef<HTMLInputElement>;
  @Output() uploadEvent = new EventEmitter<object>();

  containerName: string = "test";
  isDisabled: boolean = false;

  constructor(
    private azureStorageService: AzureStorageService,
    //private layerService: LayersService,
    //private mapLayersService: MapLayersService,
    private sharedService: SharedService,
    //private user: UserService, private router: Router
  ) { }


  fileEvent(e) {
    
    //let layerId = e.target.attributes["layerID"].value;
    //let Id = e.target.attributes["sublayerID"].value;
    
    let filedata: File = e.target.files.item(0);
    if (filedata.size > this.sharedService.RegionSizeFileByte ) {
      notify(`Не можливо завантажити файл розміром більше ${this.sharedService.RegionSizeFile}MB`, "error", 4000);
      return;
    }

    notify("Завантаження файлу почалося", "info", 2500);
    this.azureStorageService.uploadFile(this.containerName, filedata.name, filedata).then(() => {
      this.uploadEvent.emit(null);
    })
    console.log(e);
    //this.importGeodataService.importGeodata(filedata, layerId, Id).subscribe(result => {
    //  notify(result, "info", 4500);
    //  console.log(result);
    //  e.srcElement.value = null;
    //});
  }

  showFileDialog(): void {
    if (!this.isDisabled) {
      this.documentInput.nativeElement.click();
    }    
  }
}
