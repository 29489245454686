import { Injectable } from '@angular/core';
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../shared/utils/config.service";
import { MapLayersEndpoint } from '../map/endpoint-map.layers.service'
import { LayerData, LayerDataWithAttr } from "../../models/layers/layer.data.model";

import { Layer, SelectChildrenType } from "../../models/layers/layer.model";
import { EsriService } from "../esri/js-esri.service";
import { LayerDataAttribute } from "../../models/layers/layer-data-attribute.models";
import { LoadingMapService } from "../loading/loading.service";
import { BookmarksService } from '../bookmarks/bookmarks.service';
import { PublicBookmark } from '../../models/bookmarks/bookmark.model';
import notify from 'devextreme/ui/notify';
import { FilterAttributeService } from '../filterattribute/filter-attribute.service';
import { isFlashType, LegendInfo, LegendItem } from '../../models/layers/legend.model';
import { Filter } from '../../models/filters/filter.model';
import { BehaviorSubject } from 'rxjs';
import { FlashLegendsService } from '../legends/flash-legends.service';
import { FeatureLayerInfo } from '../../models/layers/feature-layer.info.model';

@Injectable()
export class MapLayersService {
  Color: any;
  string: any;
  constructor(public  esriService: EsriService,
    private sharedService: SharedService,
    private configService: ConfigService,
    private mapLayersEndpoint: MapLayersEndpoint,
    private filterAttributeService: FilterAttributeService,
    private loadingMapService: LoadingMapService,
    private bookmarksService: BookmarksService,
    private flashLegendsService: FlashLegendsService
  ) {
    
    
  }
  CurrentExtent: any;


  RemoveAllLayers() {
    let map: __esri.Map = this.sharedService.map;
    let layers = map?.layers.filter(x => x.id != 'customLayerID');
    
    if (layers?.length > 0) {
      map.removeMany(layers.toArray());
    }
    //this.sharedService.map.removeAll();

  }

  private getLayerChildrent(layer: Layer): Layer[] {
    let result = new Array<Layer>();
    layer.layerChildren.forEach(children => {
     
        result.push(children);
        let temp = this.getLayerChildrent(children);
        if (temp.length > 0) {
          result = result.concat(temp);
        }
            
    })
    return result;
  }

/*private*/ getLayerSelectedChildrent(layer: Layer): Layer[] {
    let result = new Array<Layer>();
    layer.layerChildren.forEach(children => {
      if (children.isSelected) {
        children.hasFlash = true;
        result.push(children);
        let temp = this.getLayerSelectedChildrent(children);
        if (temp.length > 0) {
          result = result.concat(temp);
        }
      }
    })
    return result;
  }

  private getLayerParent(layer: Layer, layerId: string): boolean {
    let result = false;
    
    for (var children of layer.layerChildren){
      if (children.id == layerId)
      {
        layer.isSelected = true;
        result = true;
        break;       
      } else {
        if (this.getLayerParent(children, layerId)) {
          layer.isSelected = true;
          result = true;
          break;
        }
      }      
      
    }
    return result;
  }
  /*private*/ prepareLayerList(): Layer[] {
    let layerList = this.sharedService.getLayerList().getValue();    

    let result: Layer[] = new Array<Layer>();
       
    layerList.forEach(layer => {
      result.push(layer);
      let childrent = this.getLayerChildrent(layer);
      if (childrent.length > 0) {
        result= result.concat(childrent);
      }
    });
    return result;
  }

/*private*/ parentLayerID(layerId: string): string {
    let layerList = this.sharedService.getLayerList().getValue();
    let result: string = layerId;

    for (var layer of layerList){
      if (layer.id == layerId)
      {
        result = layer.id;
        break;
      }
      
      if (this.getLayerParent(layer, layerId))
      {
        result = layer.id;
        //this.sharedService.clearLayers();
        //let tmp: Layer[] = new Array<Layer>();
        //Object.assign(tmp, layerList);
        //this.sharedService.setLayers(tmp);
        this.sharedService.setLayers(layerList);
        break;        
      }
    };
  
      
    
    return result;
  }

  private CollapseChildren(layer: Layer) {
    layer.isExpanded = false;
    layer.layerChildren.forEach(_layer => {
      _layer.isExpanded = false;
      this.CollapseChildren(_layer);
    });        
  }
 

  showMapServerEx(layerId: string) {  // TODO old . dont use
    let isCreated = false;
    let iDs = [];
    this.sharedService.mapView.allLayerViews.forEach(result => {
      iDs.push(result.layer.id);
    });
    let layerList = this.prepareLayerList();

    layerId = this.parentLayerID(layerId);

    let selectedLayers: Layer[] = new Array<Layer>();
    let _layer = layerList.find(x => x.id == layerId);
    selectedLayers.push(_layer);

    let _tempChildren = this.getLayerSelectedChildrent(_layer);
    if (_tempChildren.length > 0) {
      _tempChildren = _tempChildren.concat(selectedLayers);
    }
    layerList.forEach(item => {
        if (this.isChildrenSelected(item)) {
          item.selectChildrenType = SelectChildrenType.all;
        }
        //this.sharedService.setLayers(layerList);
      })
    _tempChildren.forEach(x => {
      if (x.isSelected) {
        selectedLayers.push(x);
      }
    })

    let notCreatedList: string[] = new Array<string>();
    iDs.forEach(x => {

      let tmp = this.sharedService.mapView.layerViews.find(z =>
        z.layer.id == x);

      let rLayer: any = tmp ? tmp.layer : null;
      if (rLayer) {
        if ((rLayer as __esri.MapImageLayer).sublayers) {
          (rLayer as __esri.MapImageLayer).sublayers.forEach(subLayer => {
            //let _item = selectedLayers.find(f => rLayer.LayerGuid && f.id == rLayer.LayerGuid);
            let _item = selectedLayers.find(f => subLayer.get<string>("LayerGuid") && f.id == subLayer.get<string>("LayerGuid"));
        if (_item) {
          _item.isShow = true;
          //rLayer.visible = true;
        }
          })
        }
        
      }
    });

    selectedLayers.forEach(x => {
      if (!x.isShow) {
        notCreatedList.push(x.id);
      }
    })

    if (notCreatedList.length <= 0) return;

    this.mapLayersEndpoint.getLayerDataWithAttrByLIdsEndpoint(notCreatedList).then(
      (result) => {
        return <LayerDataWithAttr[]>result
      }
    ).then((result) => {

      let _layers: Array<any> = [];


      let sublayers = [];
      let servicesNames = [];
      result.forEach(d => {

        let _url = this.configService._baseUrlRegionServices + `${d.serviceName}`;
        _url = _url.replace('FeatureServer', 'MapServer');
        let map: __esri.Map = this.sharedService.map;

        let _existLayer = map.layers.find(x => x.type == 'map-image' && (x as __esri.MapImageLayer).url.includes(_url));
        let _layer: __esri.MapImageLayer;
        this.sharedService.setLegends(null);
        if (_existLayer) {
          _layer = _existLayer as __esri.MapImageLayer;
          let subLayer: __esri.Sublayer = _layer.sublayers.find(s => s.id == Number.parseInt(d.layerName));
          if (subLayer) {
            subLayer.visible = true;
          } else {
            subLayer = new this.esriService.SubLayer({
              id: d.layerName,
              visible: true,
              popupTemplate: {
                title: d.name,
                //overwriteActions: true, //TODO
                content: this.GetPopupContentFeaure, //this.getPopupAttachedFiles,
                outFields: this.getAttributes(d.layerAttributes), // ["*"], // 
                actions: [
                  {
                    id: "edit-geodata",
                    className: "esri-icon-edit",
                    title: "Редагувати"
                  },
                  {
                    id: "view-geodata",
                    className: "esri-icon-description",
                    title: "Інфо"
                  },
                  {
                    id: "paint-geodata",
                    className: "esri-icon-expand2",
                    title: "Змінити"
                  },
                  {
                    id: "delete-geodata",
                    className: "esri-icon-trash",
                    title: "Вилучити"
                  }
                  //,
                  //{
                  //  id: "upload-file",
                  //  className: "esri-icon-upload",
                  //  title: "Завантажити файл"
                  //}
                ]
              }
            });
            if (d.layerAttributes.find(a => a.hasFilter)) {
              let groupTypeArray: any[] = [];
              let filter = '';
              d.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
                //if (filter.length > 0) {
                //  filter = filter + ' and ';
                //}
                if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
                  attr.subTypeFilters.forEach(s => {

                  })
                  let visibledSubtypes = [];
                  attr.subTypeFilters.forEach(st => {
                    visibledSubtypes.push(st.subTypeID);
                  })
                  subLayer.set<number[]>("visibledSubtypes", visibledSubtypes);
                  let strFilter = "";
                  attr.subTypeFilters.forEach(s => {
                    if (strFilter.length > 0) {
                      strFilter = strFilter + ','
                    }

                    strFilter = strFilter+ s.subTypeID;
                  })
                  if (filter.length > 0) {
                    filter = filter + ' and ';
                  }
                  filter += attr.name + " in ( " + strFilter + ")";
                } else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
                  let filterKOATUU = attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
                  if (filter.length > 0) {
                    filter = filter + ' and ';
                  }
                  filter += filterKOATUU;//attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
                  subLayer.set<string>("filterKOATUU", filterKOATUU);
                } else if (attr.filterType == this.filterAttributeService.TypeGroup) {
                  let groupFilter = "";
                  attr.groupValueFilters.forEach(g => {
                    if (groupFilter.length > 0) {
                      groupFilter += ',';
                    }
                    //if (attr.fieldType == 'string') {
                    groupFilter += `N'${g.value}'`;
                    //}

                  })

                  if (groupFilter.length > 0) {
                    if (filter.length > 0) {
                      filter = filter + ' and ';
                    }
                    filter += attr.name + " in ( " + groupFilter + ")";
                  }
                  let n = {
                    name: attr.name,
                    group: attr.groupValueFilters
                  }
                  groupTypeArray.push(n);

                }
              })
              if (groupTypeArray && groupTypeArray.length > 0) {
                subLayer.set<any>("filterGroupType", groupTypeArray);
              }
              subLayer.definitionExpression = filter;
            }

            if (!!d.transparency) {

              subLayer.opacity = Number.parseFloat(d.transparency);
            }

            if (!!d.background) {
              var rgba = d.background.split(',');
              subLayer.set<string[]>("layerColor", rgba);
            }
            let editable = d.layerAttributes.find(x => x.editable);
            if (editable)
              subLayer.set<boolean>("editable", true);
            else
              subLayer.set<boolean>("editable", false);
            let showed = d.layerAttributes.find(x => x.showed);

            if (showed) {
              subLayer.set<boolean>("showed", true);
            } else {
              subLayer.set<boolean>("showed", false);
            }
            subLayer.set<string>("LayerGuid", d.layerId);
            subLayer.set<string>("LayerDataGUID", d.id);
            subLayer.set<string>("name", d.name);
            subLayer.set<boolean>("canAddObject", d.canAddObject);
            _layer.sublayers.add(
              subLayer
            );

            var self = this;

            this.sharedService.mapView.whenLayerView(_layer).then(function (layerView) {

              if (layerView.layer.get<string[]>('layerColor')) {
                layerView.layer.set<any>('renderer.symbol.color', layerView.layer.get<string[]>('layerColor'));
              }
              subLayer.set<any>("layerAttributes", d.layerAttributes);
            });
          }


        } else {
          _layer = new this.esriService.MapImageLayer(
            {
              url: _url,
              sublayers: [{
                id: d.layerName,
                visible: true,
                popupTemplate: {
                  title: d.name,
                  // overwriteActions: true, // TODO
                  content: this.GetPopupContentFeaure, //this.getPopupAttachedFiles,                  
                  outFields: this.getAttributes(d.layerAttributes), // ["*"], // 
                  actions: [
                    {
                      id: "edit-geodata",
                      className: "esri-icon-edit",
                      title: "Редагувати"
                    },
                    {
                      id: "view-geodata",
                      className: "esri-icon-description",
                      title: "Інфо"
                    },
                    {
                      id: "paint-geodata",
                      className: "esri-icon-expand2",
                      title: "Змінити"
                    },
                    {
                      id: "delete-geodata",
                      className: "esri-icon-trash",
                      title: "Вилучити"
                    }
                    //,
                    //{
                    //  id: "upload-file",
                    //  className: "esri-icon-upload",
                    //  title: "Завантажити файл"
                    //}
                  ]
                }

              }
              ]
            }
          );

          let sublayer = _layer.sublayers.getItemAt(0);
          if (d.layerAttributes.find(a => a.hasFilter)) {
            let groupTypeArray: any[] = [];
            let filter = '';
            d.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
              //if (filter.length > 0) {
              //  filter = filter + ' and ';
              //}
              if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
                let visibledSubtypes = [];
                attr.subTypeFilters.forEach(st => {
                  visibledSubtypes.push(st.subTypeID);
                })
                sublayer.set<number[]>("visibledSubtypes", visibledSubtypes);
                let strFilter = "";
                attr.subTypeFilters.forEach(s => {
                  if (strFilter.length > 0) {
                    strFilter = strFilter + ','
                  }
                  strFilter = strFilter + s.subTypeID;
                })
                if (filter.length > 0) {
                  filter = filter + ' and ';
                }
                filter += attr.name + " in ( " + strFilter + ")";
              } else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
                //filter += attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
                let filterKOATUU = attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
                if (filter.length > 0) {
                  filter = filter + ' and ';
                }
                filter += filterKOATUU;//attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
                sublayer.set<string>("filterKOATUU", filterKOATUU);
              } else if (attr.filterType == this.filterAttributeService.TypeGroup) {
                let groupFilter = "";
                attr.groupValueFilters.forEach(g => {
                  if (groupFilter.length > 0) {
                    groupFilter += ',';
                  }
                  //if (attr.fieldType == 'string') {
                  groupFilter += `N'${g.value}'`;
                  //}

                })

                if (groupFilter.length > 0) {
                  if (filter.length > 0) {
                    filter = filter + ' and ';
                  }
                  filter += attr.name + " in ( " + groupFilter + ")";
                }
                let n = {
                  name: attr.name,
                  group: attr.groupValueFilters
                }
                groupTypeArray.push(n);

              }
            })
            if (groupTypeArray && groupTypeArray.length > 0) {
              sublayer.set<any>("filterGroupType", groupTypeArray);
            }
            sublayer.definitionExpression = filter;
          }

          if (!!d.transparency) {

            sublayer.opacity = Number.parseFloat(d.transparency);
          }

          if (!!d.background) {
            var rgba = d.background.split(',');
            sublayer.set<string[]>("layerColor", rgba);
          }
          let editable = d.layerAttributes.find(x => x.editable);
          if (editable)
            sublayer.set<boolean>("editable", true);
          else
            sublayer.set<boolean>("editable", false);
          let showed = d.layerAttributes.find(x => x.showed);

          if (showed) {
            sublayer.set<boolean>("showed", true);
          } else {
            _layer.set<boolean>("showed", false);
          }
          sublayer.set<string>("LayerGuid", d.layerId);
          sublayer.set<string>("LayerDataGUID", d.id);
          sublayer.set<string>("name", d.name);
          sublayer.set<boolean>("canAddObject", d.canAddObject);
          //_layer.set<any>("azureStorageService", this.azureStorageService);
          this.sharedService.map.add(_layer);
          var self = this;

          this.sharedService.mapView.whenLayerView(_layer).then(function (layerView) {
            self.loadingMapService.AddToWatch(layerView);
            if (layerView.layer.get<string[]>('layerColor'))
              layerView.layer.set<any>('renderer.symbol.color', layerView.layer.get<string[]>('layerColor'));
            self.GetLegends(layerView.layer);
            let sublayer = (layerView.layer as __esri.MapImageLayer).sublayers.getItemAt(0);
            sublayer.set<any>("layerAttributes", d.layerAttributes);
          });
        }
      });

    });

  }

  isChildrenSelected(layer: Layer): boolean {
    let returnResult: boolean = false;
    let result = layer.isSelected;
    if (result) {
      returnResult = true;
    }

    for (var item of layer.layerChildren) {
      if (item.isSelected) {
        result = this.isChildrenSelected(item);
        if (!result) {
          returnResult = false;
        }
      } else {
        returnResult = false;
      }

    }
    if (returnResult) {
      layer.selectChildrenType = SelectChildrenType.all;
    } else {
      layer.selectChildrenType = SelectChildrenType.none;
    }

    return returnResult;
  }
    public GetLegends(layer: __esri.Layer) {
    let options: __esri.RequestOptions =
      {
        responseType: 'json'
      };
    let url = layer.get<any>("url") + "/legend?f=pjson&token=" + this.configService.getGisToken();
    let jLegend: any;
    var self = this;
    return this.esriService.Request(url, options).then(function (result) {
      jLegend = result.data;  //JSON.parse(result.data);
      layer.set<any>("legendInfo", jLegend);
      return layer;
    }).catch(function (error) {      
      console.log("informative error message: ", error.message);
    });
  }  

  public GetDynamicLegends_(layerUrl: string, layers: any[]) {    
    let options: __esri.RequestOptions =
    {
      responseType: 'json'
    };
    
    let url = layerUrl + "/legend?dynamicLayers=" + encodeURIComponent("[" + layers.toString() + "]")+  "&f=pjson&token=" + this.configService.getGisToken();
    let jLegend: any;
    var self = this;

    return this.esriService.Request(url, options).then(function (result) {
      jLegend = result.data;  //JSON.parse(result.data);      
      return jLegend;
    }).catch(function (error) {
      console.log("informative error message: ", error.message);
    });
  }

  
  private async GetPopupContentFeaure(feature) {

    let result = '*';
    let _feauture = feature.graphic;// as __esri.Graphic; 
    let attributes: LayerDataAttribute[] = (_feauture.sourceLayer as __esri.FeatureLayer).get<any>("layerAttributes");        
    let fields = (_feauture.sourceLayer as __esri.FeatureLayer).fields;
    if (attributes) {
      let sourceLayer = (_feauture.sourceLayer as __esri.FeatureLayer);
      result = '<ul class="esri-popup__list">';
      result += `<span style="display:none">OBJECTID : {OBJECTID}</span>`;
      attributes.forEach(x => {
        if (x.inMini) {
          let field = fields.find(f => f.name == x.name)
          let _value = '';
          if (sourceLayer.typeIdField && sourceLayer.typeIdField == x.name) {
            let _id = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
            let typeValue = sourceLayer.types.find(t => t.id == _id);
            if (typeValue) {
              _value = typeValue.name;
            }
          } else {
            _value = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
            let url;
            let isUrl: boolean = false;
            try {
              url = new URL(_value);
              isUrl = url.protocol === "http:" || url.protocol === "https:";
            } catch (_) {
              isUrl = false;
            }
            if (isUrl) {
              _value = `<a target='blank' href='${_value}'>Більше...</a>`
            }
          }

          result += `<li><span class=esri-popup__text-secondary>${field.alias}</span> : <span class="esri-popup__text-primary">${_value}</span></li>`;
        }

      });
      result += '</ul>';
      //let azureStorageService = (_feauture.sourceLayer.layer as __esri.FeatureLayer).get<any>("azureStorageService");
      //let containerName = (_feauture.sourceLayer as __esri.FeatureLayer).get<string>("LayerDataGUID") + "-" + _feauture.attributes["OBJECTID"];
      //let blobs = await azureStorageService.listBlobsByContainer(containerName);
      //let attachedResult: string = '';
      //blobs.forEach(b => {
      //  attachedResult += `<li><span class=esri-popup__text-secondary>${b}</span> : <span class="esri-popup__text-primary">Name</span></li>`;
      //})

      //if (attachedResult) {
      //  result += '<ul class="esri-popup__list">' + attachedResult + '</ul>' ;
      //}

    }
    return result;
  }

  async getPopupAttachedFiles(feature, azureStorageService) {
    let result = "<ul>";
    let graphic = feature.graphic;// as __esri.Graphic;
    let containerName = (graphic.sourceLayer as __esri.FeatureLayer).get<string>("LayerDataGUID") + "-" + graphic.attributes["ObjecID"];
    let blobs = await azureStorageService.listBlobsByContainer(containerName);
    blobs.forEach(b => {
      result += `<li><span class=esri-popup__text-secondary>${b}</span> : <span class="esri-popup__text-primary">Name</span></li>`;
    })
    result += "</ul>";
    //let ttt = self;
    //let textElement = new this.esriService.TextContent();
    //textElement.text = result;
    return result;
  }

    HideLayerDataByLayerIDEx(layerId: string) {
      let iDs: string[] = new Array<string>();
      iDs.push(layerId);
      let layerList = this.prepareLayerList();
      let layer = layerList.find(x => x.id == layerId);
      let layerChildren = this.getLayerChildrent(layer);
      layerChildren.forEach(n => {
        n.isShow = false;
        iDs.push(n.id);
      });
      this.sharedService.setLegends(null);
      if (layer) {
        layer.isShow = false;
        (this.sharedService.map as __esri.Map).layers.filter(f => !f.get<boolean>("isFlashing")).forEach(x => {
          if (x.type == "map-image" && (x as __esri.MapImageLayer).sublayers) {
            (x as __esri.MapImageLayer).sublayers.filter(l => iDs.includes(l.get<string>("LayerGuid"))).forEach(y => {
              //y.visible = false;
              (x as __esri.MapImageLayer).sublayers.remove(y);


              if ((y as __esri.Sublayer).get<any>("IsFlash") && (y as __esri.Sublayer).get<any>("IsFlash") != isFlashType.none) {

                //if (subType) {
                //  FlashLayerID = FlashLayerID + subType;
                //}

                //this.legendsService.removeFlashLayer(y.id, (y as __esri.Sublayer).get<string>("LayerDataGUID"), )
                

                let flashWatch = this.sharedService.WatchFlashList.find(x => x.flashId == (y as __esri.Sublayer).get<string>("LayerDataGUID"));
                
                if (flashWatch) {
                  let watchHandle = flashWatch?.handle;
                  watchHandle?.remove();
                  let index = this.sharedService.WatchFlashList.indexOf(flashWatch);

                  this.sharedService.WatchFlashList.splice(index, 1);
                }                

                let flashTypes = y.get<any[]>("flashTypes");
                if (flashTypes) {
                  flashTypes.forEach(f => {
                    let flashLayerId = y.get<string>("LayerDataGUID") + f.subTypeID;
                    let flashLayer = (this.sharedService.map as __esri.Map).layers.find(layer => layer.get<string>("FlashLayerID") == flashLayerId);
                    (this.sharedService.map as __esri.Map).layers.remove(flashLayer);
                    this.flashLegendsService.RemoveFlashLayerViewByLayerID(flashLayer?.id);
                    let legend = this.sharedService.publicLegends.find(x => x.layerGuid == y.get<string>("LayerDataGUID") && x.id == (y as __esri.Sublayer).id.toString() && x.defaultValues.find(v => v == f.subTypeID))
                    //: this.legends.find(x => x.layerGuid == layerGuid && x.id == id);
                    if (legend) {
                      legend.isFlash = "none";
                    }
                  })
                } else {
                  let flashLayer = (this.sharedService.map as __esri.Map).layers.find(layer => layer.get<string>("FlashLayerID") == (y as __esri.Sublayer).get<string>("LayerDataGUID"));
                  (this.sharedService.map as __esri.Map).layers.remove(flashLayer);
                  this.flashLegendsService.RemoveFlashLayerViewByLayerID(flashLayer?.id);
                  let legend = this.sharedService.publicLegends.find(x => x.layerGuid == y.get<string>("LayerDataGUID") && x.id == (y as __esri.Sublayer).id.toString())
                  if (legend) {
                    legend.isFlash = "none";
                  }
                }


              }
              let currentLegend: LegendItem[] = this.sharedService.publicLegends.filter(l => l.layerGuid == y.get<string>("LayerDataGUID"));
              currentLegend.forEach(l => {
                l.layerID = null;
              })
            });
            if ((x as __esri.MapImageLayer).sublayers.length == 0) {
              (this.sharedService.map as __esri.Map).layers.remove(x);
            }
          } else if (x.type == "feature") {
            if (iDs.includes(x.get<string>("LayerGuid"))) {
              (this.sharedService.map as __esri.Map).layers.remove(x);

              if (x.get<any>("IsFlash") && x.get<any>("IsFlash") != isFlashType.none) {
                let flashWatch = this.sharedService.WatchFlashList.find(f => f.flashId == x.get<string>("LayerDataGUID"));
                

                if (flashWatch) {
                  let watchHandle = flashWatch?.handle;
                  watchHandle?.remove();
                  let index = this.sharedService.WatchFlashList.indexOf(flashWatch);

                  this.sharedService.WatchFlashList.splice(index, 1);
                } 

                let flashTypes = x.get<any[]>("flashTypes");
                if (flashTypes) {
                  flashTypes.forEach(f => {
                    let flashLayerId = x.get<string>("LayerDataGUID") + f.subTypeID;
                    let flashLayer = (this.sharedService.map as __esri.Map).layers.find(layer => layer.get<string>("FlashLayerID") == flashLayerId);
                    (this.sharedService.map as __esri.Map).layers.remove(flashLayer);
                    this.flashLegendsService.RemoveFlashLayerViewByLayerID(flashLayer?.id);
                    let legend = this.sharedService.publicLegends.find(l => l.layerGuid == x.get<string>("LayerDataGUID") && l.defaultValues.find(v => v == f.subTypeID) == f.subTypeID)
                    if (legend) {
                      legend.isFlash = "none";
                    }

                  })
                } else {
                  let flashLayer = (this.sharedService.map as __esri.Map).layers.find(layer => layer.get<string>("FlashLayerID") == x.get<string>("LayerDataGUID"));
                  (this.sharedService.map as __esri.Map).layers.remove(flashLayer);
                  this.flashLegendsService.RemoveFlashLayerViewByLayerID(flashLayer?.id);
                  let legend = this.sharedService.publicLegends.find(l => l.layerGuid == x.get<string>("LayerDataGUID") && l.layerID == (x as __esri.FeatureLayer).id.toString())
                  if (legend) {
                    legend.isFlash = "none";
                  }
                }

              }

            }            
          }          
        })
        
      }
    }
  getLegendsByFilter(): Array<LegendItem> {
    //let allLayers = (this.sharedService.map as __esri.Map).layers;
    let legends = new Array<LegendItem>();
    //let filteredLayers = allLayers.filter(x => x.visible == true && !x.get<boolean>("isFlashing") && (x.type == "map-image" || x.type == "feature")); // && x.type == "map-image"
    //let iDs = filteredLayers.map((l) => {
    //  return l.id;
    //})
    let iDs: any[] = this.sharedService.getCurrentFilterValue().layers.map(m => { return m.id });

    legends = this.sharedService.publicLegends.filter(f => iDs.includes(f.filterLayerGUID)) // f.isFilter
    return legends;
  }
  getLegendsByLayers(): Array<LegendItem> {
    let allLayers = (this.sharedService.map as __esri.Map).layers;
    let legends = new Array<LegendItem>();
    let filteredLayers = allLayers.filter(x => x.visible == true && !x.get<boolean>("isFlashing") && (x.type == "map-image" || x.type == "feature")); // && x.type == "map-image"
    let iDs = filteredLayers.map((l) => {
      return l.id;
    })
    legends = this.sharedService.publicLegends.filter(f => iDs.includes(f.layerID))
    return legends;
  }
  showPublicBookmark(Id: string, legend: any): Promise<PublicBookmark> {
     return this.bookmarksService.getPublicBookmarkData(Id).then(result => {
       //if (result && result.layers) {
       if (result) {
         var token = {
           'server': this.configService._baseUrlRegionServices,
           'token': result.token
         };
         if (result.token) {
           this.esriService.IdentityManager.registerToken(token);
           this.configService.setGisToken(result.token);
         }         
        let layers = result.layers;
        let _layer: __esri.MapImageLayer;
         if (!this.sharedService.CurrentRegionCode) {
           this.sharedService.CurrentRegionCode = result.code;
         }
        layers.forEach(d => {

          let _url = this.configService._baseUrlRegionServices + `${d.serviceName}`;
          _url = _url.replace('FeatureServer', 'MapServer');
          let map: __esri.Map = this.sharedService.map;

          let _existLayer = map.layers.find(x => x.type == 'map-image' && (x as __esri.MapImageLayer).url.includes(_url));
          let sublayer: __esri.Sublayer ;
          if (_existLayer) {
            _layer = _existLayer as __esri.MapImageLayer;
            sublayer = new this.esriService.SubLayer({
              id: d.layerName,
              visible: true,
              popupTemplate: {
                title: d.name,
                //overwriteActions: true, //TODO
                content: this.GetPopupContentFeaure,
                outFields: this.getAttributes(d.layerAttributes)                 
              }
            });
          } else {
            _layer = new this.esriService.MapImageLayer({
              url: _url,
              sublayers: [
                {
                  id: d.layerName,
                  visible: true,
                  popupTemplate: {
                    title: d.name,
                    //overwriteActions: true, //TODO
                    content: this.GetPopupContentFeaure,
                    outFields: this.getAttributes(d.layerAttributes)                     
                  }
                }
              ]
            });
            sublayer = _layer.sublayers.getItemAt(0);
            this.sharedService.map.add(_layer);
          }

          if (d.layerAttributes.find(a => a.hasFilter)) {
            let filter = '';
            let groupTypeArray: any[] = [];
            d.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
              if (filter.length > 0) {
                filter = filter + ' and ';
              }
              if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
                let visibledSubtypes = [];
                attr.subTypeFilters.forEach(st => {
                  visibledSubtypes.push(st.subTypeID);
                })
                sublayer.set<number[]>("visibledSubtypes", visibledSubtypes);
                sublayer.set<string>("subtypeFieldName", attr.name);
                let strFilter = "";
                attr.subTypeFilters.forEach(s => {
                  if (strFilter.length > 0) {
                    strFilter = strFilter + ','
                  }
                  strFilter = strFilter + s.subTypeID;
                })
                filter += attr.name + " in ( " + strFilter + ")";
              }
              else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
                //filter += attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
                let filterKOATUU = attr.name + " = '" + result.code + "'";
                filter += filterKOATUU;//attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
                sublayer.set<string>("filterKOATUU", filterKOATUU);
              }
              else if (attr.filterType == this.filterAttributeService.TypeGroup) {
                let groupFilter = "";
                attr.groupValueFilters.forEach(g => {
                  if (groupFilter.length > 0) {
                    groupFilter += ',';
                  }
                  //if (attr.fieldType == 'string') {
                    groupFilter += `N'${g.value}'`;
                  //}
                  
                })
                
                if (groupFilter.length > 0) {
                  //if (filter.length > 0) {
                  //  filter = filter + ' and ';
                  //}
                  filter += attr.name + " in ( " + groupFilter + ")";
                }
                let n = {
                  name: attr.name,
                  group: attr.groupValueFilters
                }
                groupTypeArray.push(n);
                
              }
            })
            if (groupTypeArray && groupTypeArray.length > 0) {
              sublayer.set<any>("filterGroupType", groupTypeArray); 
            }
            sublayer.definitionExpression = filter;
          }

          if (!!d.transparency) {

            sublayer.opacity = Number.parseFloat(d.transparency);
          }

          if (!!d.background) {
            var rgba = d.background.split(',');
            sublayer.set<string[]>("layerColor", rgba);
          }          
          sublayer.set<boolean>("editable", false);
          _layer.set<boolean>("showed", false);          
          sublayer.set<string>("LayerGuid", d.layerId);
          sublayer.set<string>("LayerDataGUID", d.id);
          sublayer.set<string>("name", d.name);
          if (_existLayer)
            _layer.sublayers.add(sublayer);
          var self = this;

          this.sharedService.mapView.whenLayerView(_layer).then(function (layerView) {
            self.loadingMapService.AddToWatch(layerView);
            if (layerView.layer.get<string[]>('layerColor'))
              layerView.layer.set<any>('renderer.symbol.color', layerView.layer.get<string[]>('layerColor'));

            sublayer.set<any>("layerAttributes", d.layerAttributes);
          });
          
        });
         
        return result;

      } else {
        return null;
      }
    })
  }

  private getFilterValue(filterType) {
    if (filterType == this.filterAttributeService.TypeKOATUU) {
      return this.sharedService.CurrentRegionCode;
    }

    return '';
  }

  public getAttributes(attributes: LayerDataAttribute[]): any[]  {

    let result = [];
    attributes.forEach(x => {
      result.push(x.name);
    })
    return result;
  }

  async getLayerInfo(layerURL) {
    let options: __esri.RequestOptions =
    {
      responseType: 'json'
    };
    let url = layerURL + "?f=pjson&token=" + this.configService.getGisToken();
    let layerInfo: any;
    let info = this.sharedService.featureLayerInfoList.find(i => i.url == layerURL);

    if (info) {
      return new Promise<any>((resolve, reject) => {
        /*let sub =*/
        info.getLoaded().subscribe(val => {
          if (val) {

            resolve(info.layerInfo);
            //sub.unsubscribe(); // TO DO - CHECK
          }
        });//.unsubscribe();
      })

    } else {
      info = new FeatureLayerInfo();
      info.url = url;
      this.sharedService.featureLayerInfoList.push(info);
    }

    return this.esriService.Request(url, options).then(function (result) {
      layerInfo = result.data;  //JSON.parse(result.data);
      info.layerInfo = layerInfo;
      info.setLoaded(true);
      return layerInfo;
    }).catch(function (error) {
      console.log("informative error message: ", error.message);
    });
  }
  
  selectChildrenLayers(layer) {
    let layerList = this.sharedService.getLayerList().getValue();
    let currentLayer: Layer;
    for (var children of layerList) {
      if (children.id == layer.id) {
        currentLayer = children;
        break;
      } else {
        let item = this.getLayerIfParent(children, layer.id);
        if (item) {
          currentLayer = item;
          break;
        }
      }
    };

    if (currentLayer && !!!currentLayer.isEmpty) {
      currentLayer.isSelected = true;

      let children = this.getLayerChildrent(currentLayer);
      children.filter(f => !!!f.isEmpty).forEach(c => {
        c.isSelected = true;
        c.selectChildrenType = SelectChildrenType.all;
      })
    }


    this.sharedService.setLayers([]);
    this.sharedService.setLayers(layerList);
    //this.showMapServerEx(layer.id);

  }

  unselectChildrenLayers(layer) {
    let layerList = this.sharedService.getLayerList().getValue();
    let currentLayer: Layer = layer;
    //for (var children of layerList) {
    //  if (children.id == layer.id) {
    //    currentLayer = children;
    //    break;
    //  } else {
    //    let item = this.getLayerParent(children, layer.id);
    //    if (item) {
    //      currentLayer = item;
    //      break;
    //    }
    //  }
    //};

    if (currentLayer) {
      currentLayer.isSelected = false;
      currentLayer.selectChildrenType = SelectChildrenType.none;
      let children = this.getLayerChildrent(currentLayer);
      children.forEach(c => {
        c.selectChildrenType = SelectChildrenType.none;
        c.isSelected = false;
      })
    }

    layerList.forEach(item => {
      if (this.isChildrenSelected(item)) {
        item.selectChildrenType = SelectChildrenType.all;
      }
      //this.sharedService.setLayers(layerList);
    })

    this.sharedService.setLayers([]);
    this.sharedService.setLayers(layerList);
    this.HideLayerDataByLayerIDEx(layer.id);

  }

  private getLayerIfParent(layer: Layer, layerId: string): Layer {
    let result: Layer;
    for (var children of layer.layerChildren) {
      if (children.id == layerId) {
        children.isSelected = true;
        result = children;
        break;
      } else {
        let item = this.getLayerIfParent(children, layerId);
        if (item) {
          item.isSelected = true;
          result = item;
          break;
        }
      }
    }
    return result;
  }

  //showFilter(filter: Filter) {    
  //  let layers = filter.layers;
  //  layers.forEach(d => {

  //    let _url = this.configService._baseUrlRegionServices + `${d.serviceName}`;
  //    _url = _url.replace('FeatureServer', 'MapServer');
  //    let map: __esri.Map = this.sharedService.map;

  //    let _existLayer = map.layers.find(x => x.type == 'map-image' && (x as __esri.MapImageLayer).url.includes(_url));
  //    let _layer: __esri.MapImageLayer;
  //    this.sharedService.setLegends(null);
  //    if (_existLayer) {
  //      _layer = _existLayer as __esri.MapImageLayer;
  //      let subLayer: __esri.Sublayer = _layer.sublayers.find(s => s.id == Number.parseInt(d.layerName));
  //      if (subLayer) {
  //        subLayer.visible = true;
  //      } else {
  //        subLayer = new this.esriService.SubLayer({
  //          id: d.layerName,
  //          visible: true,
  //          popupTemplate: {
  //            title: d.name,
  //            //overwriteActions: true, //TODO
  //            content: this.GetPopupContentFeaure, //this.getPopupAttachedFiles,
  //            outFields: this.getAttributes(d.layerAttributes), // ["*"], // 
  //            actions: [
  //              {
  //                id: "edit-geodata",
  //                className: "esri-icon-edit",
  //                title: "Редагувати"
  //              },
  //              {
  //                id: "view-geodata",
  //                className: "esri-icon-description",
  //                title: "Інфо"
  //              },
  //              {
  //                id: "paint-geodata",
  //                className: "esri-icon-expand2",
  //                title: "Змінити"
  //              },
  //              {
  //                id: "delete-geodata",
  //                className: "esri-icon-trash",
  //                title: "Вилучити"
  //              }
  //              //,
  //              //{
  //              //  id: "upload-file",
  //              //  className: "esri-icon-upload",
  //              //  title: "Завантажити файл"
  //              //}
  //            ]
  //          }
  //        });
  //        let isSubTypeFilter: boolean = false;
  //        if (d.layerAttributes.find(a => a.hasFilter)) {
  //          let filter = '';
  //          let groupTypeArray: any[] = [];
  //          d.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
  //            if (filter.length > 0) {
  //              filter = filter + ' and ';
  //            }
  //            if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
  //              isSubTypeFilter = true;
  //              let visibledSubtypes = [];
  //              attr.subTypeFilters.forEach(st => {
  //                if (d.subtypesFilterSelected) {
  //                  let sfs = d.subtypesFilterSelected.find(f => f.id == st.subTypeID);
  //                  if (sfs) {
  //                    visibledSubtypes.push(st.subTypeID);
  //                  }
  //                } else {
  //                  visibledSubtypes.push(st.subTypeID);
  //                }

  //              })
  //              subLayer.set<number[]>("visibledSubtypes", visibledSubtypes);
  //              let strFilter = "";
  //              attr.subTypeFilters.forEach(s => {
  //                if (strFilter.length > 0) {
  //                  strFilter = strFilter + ','
  //                }

  //                strFilter = strFilter + s.subTypeID;
  //              })
  //              filter += attr.name + " in ( " + strFilter + ")";
  //            }  else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
  //              let filterKOATUU = attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
  //              filter += filterKOATUU;//attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
  //              subLayer.set<string>("filterKOATUU", filterKOATUU);
  //            }
  //            ////////////////////
  //            else if (attr.filterType == this.filterAttributeService.TypeGroup) {
  //              let groupFilter = "";
  //              attr.groupValueFilters.forEach(g => {
  //                if (groupFilter.length > 0) {
  //                  groupFilter += ',';
  //                }
  //                //if (attr.fieldType == 'string') {
  //                groupFilter += `N'${g.value}'`;
  //                //}

  //              })

  //              if (groupFilter.length > 0) {
  //                if (filter.length > 0) {
  //                  filter = filter + ' and ';
  //                }
  //                filter += attr.name + " in ( " + groupFilter + ")";
  //              }
  //              let n = {
  //                name: attr.name,
  //                group: attr.groupValueFilters
  //              }
  //              groupTypeArray.push(n);

  //            }
  //            ///////////////////////////////////////////////
  //          })
  //          if (groupTypeArray && groupTypeArray.length > 0) {
  //            subLayer.set<any>("filterGroupType", groupTypeArray);
  //          }
  //          let layerFilter = d.layerFilter ? ` and (${d.layerFilter})` : "";
  //          subLayer.definitionExpression = `(${filter})${layerFilter}`;
  //        } else {
  //          if (d.layerFilter) {
  //            subLayer.definitionExpression = d.layerFilter;
  //          }
  //        }
  //        if (!isSubTypeFilter && d.subtypesFilterSelected) {
  //          let visibledSubtypes = d.subtypesFilterSelected.map(m => { return m.id });
  //          subLayer.set<number[]>("visibledSubtypes", visibledSubtypes);
  //        }

  //        if (!!d.transparency) {

  //          subLayer.opacity = Number.parseFloat(d.transparency);
  //        }
  //        //else if (d.subtypeFiledNameInfo == attr.name && attr.filterType != this.filterAttributeService.TypeSubType) {
  //        //  let visibledSubtypes = d.subtypesFilterSelected.map(m => { return m.id });
  //        //  sublayer.set<number[]>("visibledSubtypes", visibledSubtypes);
  //        //}
  //        //if (!!d.background) {
  //        //  var rgba = d.background.split(',');
  //        //  subLayer.set<string[]>("layerColor", rgba);
  //        //}

  //        let editable = d.layerAttributes.find(x => x.editable);
  //        if (editable)
  //          subLayer.set<boolean>("editable", true);
  //        else
  //          subLayer.set<boolean>("editable", false);
  //        let showed = d.layerAttributes.find(x => x.showed);

  //        if (showed) {
  //          subLayer.set<boolean>("showed", true);
  //        } else {
  //          subLayer.set<boolean>("showed", false);
  //        }
  //        subLayer.set<string>("LayerGuid", d.layerID);
  //        subLayer.set<string>("LayerDataGUID", d.layerDataID);
  //        subLayer.set<string>("name", d.name);
  //        subLayer.set<boolean>("canAddObject", d.canAddObject);
  //        subLayer.set<string>("layerFilterExpression", d.layerFilter);
  //        _layer.sublayers.add(
  //          subLayer
  //        );

  //        var self = this;

  //        this.sharedService.mapView.whenLayerView(_layer).then(function (layerView) {

  //          if (layerView.layer.get<string[]>('layerColor')) {
  //            layerView.layer.set<any>('renderer.symbol.color', layerView.layer.get<string[]>('layerColor'));
  //          }
  //          subLayer.set<any>("layerAttributes", d.layerAttributes);
  //        });
  //      }


  //    } else {
  //      _layer = new this.esriService.MapImageLayer(
  //        {
  //          url: _url,
  //          sublayers: [{
  //            id: d.layerName,
  //            visible: true,
  //            popupTemplate: {
  //              title: d.name,
  //              // overwriteActions: true, // TODO
  //              content: this.GetPopupContentFeaure, //this.getPopupAttachedFiles,                  
  //              outFields: this.getAttributes(d.layerAttributes), // ["*"], // 
  //              actions: [
  //                {
  //                  id: "edit-geodata",
  //                  className: "esri-icon-edit",
  //                  title: "Редагувати"
  //                },
  //                {
  //                  id: "view-geodata",
  //                  className: "esri-icon-description",
  //                  title: "Інфо"
  //                },
  //                {
  //                  id: "paint-geodata",
  //                  className: "esri-icon-expand2",
  //                  title: "Змінити"
  //                },
  //                {
  //                  id: "delete-geodata",
  //                  className: "esri-icon-trash",
  //                  title: "Вилучити"
  //                }
  //                //,
  //                //{
  //                //  id: "upload-file",
  //                //  className: "esri-icon-upload",
  //                //  title: "Завантажити файл"
  //                //}
  //              ]
  //            }

  //          }
  //          ]
  //        }
  //      );

  //      let sublayer = _layer.sublayers.getItemAt(0);
  //      let isSubTypeFilter: boolean = false;
  //      if (d.layerAttributes.find(a => a.hasFilter)) {
  //        let filter = '';
  //        let groupTypeArray: any[] = [];
  //          d.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
  //            if (filter.length > 0) {
  //              filter = filter + ' and ';
  //            }
  //            if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
  //              isSubTypeFilter = true;
  //              let visibledSubtypes = [];
  //              attr.subTypeFilters.forEach(st => {
  //                if (d.subtypesFilterSelected) {
  //                  let sfs = d.subtypesFilterSelected.find(f => f.id == st.subTypeID);
  //                  if (sfs) {
  //                    visibledSubtypes.push(st.subTypeID);
  //                  }
  //                } else {
  //                  visibledSubtypes.push(st.subTypeID);
  //                }

  //              })
  //              sublayer.set<number[]>("visibledSubtypes", visibledSubtypes);
  //              let strFilter = "";
  //              attr.subTypeFilters.forEach(s => {
  //                if (strFilter.length > 0) {
  //                  strFilter = strFilter + ','
  //                }
  //                strFilter = strFilter + s.subTypeID;
  //              })
  //              filter += attr.name + " in ( " + strFilter + ")";
  //            }  else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
  //              //filter += attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
  //              let filterKOATUU = attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
  //              filter += filterKOATUU;//attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
  //              sublayer.set<string>("filterKOATUU", filterKOATUU);
  //            }////////////////////
  //            else if (attr.filterType == this.filterAttributeService.TypeGroup) {
  //              let groupFilter = "";
  //              attr.groupValueFilters.forEach(g => {
  //                if (groupFilter.length > 0) {
  //                  groupFilter += ',';
  //                }
  //                //if (attr.fieldType == 'string') {
  //                groupFilter += `N'${g.value}'`;
  //                //}

  //              })

  //              if (groupFilter.length > 0) {
  //                if (filter.length > 0) {
  //                  filter = filter + ' and ';
  //                }
  //                filter += attr.name + " in ( " + groupFilter + ")";
  //              }
  //              let n = {
  //                name: attr.name,
  //                group: attr.groupValueFilters
  //              }
  //              groupTypeArray.push(n);
  //            }
  //              ///////////////////////////////////////////////
  //          })
  //        if (groupTypeArray && groupTypeArray.length > 0) {
  //          sublayer.set<any>("filterGroupType", groupTypeArray);
  //        }
  //        //sublayer.definitionExpression = `(${filter}) and (${d.layerFilter})`;
  //        let layerFilter = d.layerFilter ? ` and (${d.layerFilter})` : "";
  //        sublayer.definitionExpression = `(${filter})${layerFilter}`;
  //      } else {
  //        if (d.layerFilter) {
  //          sublayer.definitionExpression = d.layerFilter;
  //        }
  //      }

  //      if (!isSubTypeFilter && d.subtypesFilterSelected) {
  //        let visibledSubtypes = d.subtypesFilterSelected.map(m => { return m.id });
  //        sublayer.set<number[]>("visibledSubtypes", visibledSubtypes);
  //      }

  //      if (!!d.transparency) {

  //        sublayer.opacity = Number.parseFloat(d.transparency);
  //      }

  //      //if (!!d.background) {
  //      //  var rgba = d.background.split(',');
  //      //  sublayer.set<string[]>("layerColor", rgba);
  //      //}
  //      let editable = d.layerAttributes.find(x => x.editable);
  //      if (editable)
  //        sublayer.set<boolean>("editable", true);
  //      else
  //        sublayer.set<boolean>("editable", false);
  //      let showed = d.layerAttributes.find(x => x.showed);

  //      if (showed) {
  //        sublayer.set<boolean>("showed", true);
  //      } else {
  //        _layer.set<boolean>("showed", false);
  //      }
  //      sublayer.set<string>("LayerGuid", d.layerID);
  //      sublayer.set<string>("LayerDataGUID", d.layerDataID);
  //      sublayer.set<string>("name", d.name);
  //      sublayer.set<boolean>("canAddObject", d.canAddObject);
  //      sublayer.set<string>("layerFilterExpression", d.layerFilter);
  //      //_layer.set<any>("azureStorageService", this.azureStorageService);
  //      this.sharedService.map.add(_layer);
  //      var self = this;

  //      this.sharedService.mapView.whenLayerView(_layer).then(function (layerView) {
  //        self.loadingMapService.AddToWatch(layerView);
  //        if (layerView.layer.get<string[]>('layerColor'))
  //          layerView.layer.set<any>('renderer.symbol.color', layerView.layer.get<string[]>('layerColor'));
  //        self.GetLegends(layerView.layer);
  //        let sublayer = (layerView.layer as __esri.MapImageLayer).sublayers.getItemAt(0);
  //        sublayer.set<any>("layerAttributes", d.layerAttributes);
  //      });
  //    }
  //  });

  //}

  getViewExtent() {

  }
}



