import { Component, OnDestroy, OnInit } from '@angular/core';
import { DrawPoligonService } from "../../../services/draw/draw-polygon.service";
import { SharedService } from "../../../services/shared/shared.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-polygon',
  templateUrl: './polygon.component.html',
  styleUrls: ['./polygon.component.scss']
})
export class PolygonComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  isShowed: boolean = false;

  constructor(private drawPoligonService: DrawPoligonService, private sharedService: SharedService) { }

  ngOnInit() {

    this.drawPoligonService.getPolygonBtn();
    this.subscription = this.sharedService.isPolygonDrawing().subscribe(value => {
      this.isShowed = value;
    });
    
  }

  startDraw() {
    this.drawPoligonService.startDraw();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

}
