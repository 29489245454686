
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { MapLayersService } from "../map/map.layers.service";
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { Layer } from '../../models/layers/layer.model';
import { LayersEndpoint } from "./layers-endpoint.service";
import { LayerDataWithAttr } from '../../models/layers/layer.data.model';
import { LayerDataAttribute } from '../../models/layers/layer-data-attribute.models';


@Injectable()
export class LayersService {

  constructor(private sharedService: SharedService, private mapLayersService: MapLayersService, private layersEndpoint: LayersEndpoint) {
    
  }


  getPublicLayers(): Observable<Layer[]> {
    return this.layersEndpoint.getPublicLayersEndpoint().pipe(map((result: any) => <Layer[]>result));
  }

  getLayersByRegionIds(regionIds: string[]): Observable<Layer[]> {

    return this.layersEndpoint.getLayersByRegionIds(regionIds).pipe(
      map((result: any) =>
        <Layer[]>result
      ));
  }

  getLayerData(layerDataId: string): Observable<LayerDataWithAttr> {
    return this.layersEndpoint.getLayerDataEndpoint(layerDataId).pipe(
      map((result: any) =>
        <LayerDataWithAttr>result
      ));
  }

  getFakeLayers(): LayerTest[] {
    return layers;
  }

}

export class LayerTest {
  id: string;
  text: string;
  expanded?: boolean;
  selected?: boolean;
  type?: number;
  items?: LayerTest[];
}

var layers: LayerTest[] = [{
  id: "1",
  text: "Інвертизація",
  type:1,
  items: [{
    id: "1_1",
    text: "Будівлі",
    type: 2,
    items: [{
      id: "1_1_1",
      text: "Капітальна",
      type: 3
    }, {
      id: "1_1_2",
      text: "Тимчасова",
      type: 3
    }]
  }, {
      id: "1_2",
      text: "ЛЕП",
      expanded: true,
      type: 2,
      items: [{
        id: "1_2_1",
        text: "Висока напруга",
        type: 3
      }, {
        id: "1_2_2",
        text: "Низька напруга",
        type: 3
      }, {
        id: "1_2_3",
        text: "Будки трансформаторні",
        type: 3
      }, {
        id: "1_2_4",
        text: "Трансформатори на стовпах",
        type: 3
      }, {
        id: "1_2_5",
        text: "Повітряні дротяні",
        type: 3
      }]
    }, {
      id: "1_3",
      text: "Ділянки",
      type: 2,
      items: [{
        id: "1_3_1",
        text: "З кадастровим №",
        type: 2
      }, {
        id: "1_3_2",
        text: "Без кадастрового №",
        type: 2
      }]
    }
   ]
}];



