import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";

@Injectable()
export class BookmarksAnonymousService {

  constructor(private sharedService: SharedService) {
    
  }

}
