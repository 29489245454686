import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { BookmarksEndpointService } from "./bookmarks-endpoint.service";
import { UserBookmark, PublicBookmark } from "../../models/bookmarks/bookmark.model";
//import { FiltersService } from '../filters/filters.service';
import { Layer } from '../../models/layers/layer.model';
import { Observable } from 'rxjs';

@Injectable()
export class BookmarksService {

  constructor(private sharedService: SharedService, private bookmarksEndpointService: BookmarksEndpointService,
  //  private filtersService: FiltersService
   // private mapLayersService: MapLayersService
  ) {
    
  }

  unselectBookmark(bookmark: UserBookmark) {
    this.sharedService.getUserBookmarkList().subscribe(bookmarks => {

      bookmarks.forEach(bookmark => { // .filter(f => f.isSelected)
        bookmark.isSelected = false;
        bookmark?.bookmarks?.forEach(cb => {
          cb.isSelected = false;
        })
      })

      this.sharedService.setSelectedBookmark(null);

      //if (this.sharedService.getCurrentFilterValue()) {
      //  this.filtersService.unselectFilter();
      //}

      //this.selectedBookmark = null;

      let layers = this.sharedService.getLayerList().getValue();

      layers.forEach(_layer => {
        let item = bookmark.details.find(x => x.layerID == _layer.id) != null;
        if (item) {
          _layer.isSelected = false;
          _layer.inBookmark = false;
        }
        //_layer.inBookmark = false;
        this.unselectChildren(_layer.layerChildren, bookmark);
      })

      this.sharedService.setLayers(layers);



    }).unsubscribe();

       
  }

  unselectChildren(layers: Layer[], bookmark: UserBookmark) {
    layers.forEach(_layer => {
      let item = bookmark.details.find(x => x.layerID == _layer.id) != null;
      if (item) {
        _layer.isSelected = false;
        _layer.inBookmark = _layer.isSelected;
      }
      
      this.unselectChildren(_layer.layerChildren, bookmark);
    })
  }


  //get bookmarks by regionId, userId
  getListByRegionUser(regionId?: string, userId?: string) {

    return this.bookmarksEndpointService.getUserBookmarkByRegionEndpoint(regionId, userId).then(result =>
    { return <UserBookmark[]>result; }
    );
  }

  getPublicBookmarkData(Id: string): Promise<PublicBookmark> {
    return this.bookmarksEndpointService.getPublicBookmarkDataEndpoint(Id).then(result => {
      return <PublicBookmark>result;
    })
  }

  getPublicBookmarksByRegion(Id: string): Promise<PublicBookmark[]> {
    return this.bookmarksEndpointService.getPublicBookmarskByRegionEndpoint(Id).then(result => {
      return <PublicBookmark[]>result;
    })
  }

  getListByParentBookmark(parentId?: string) {

    return this.bookmarksEndpointService.getListBookmarkByParentEndpoint(parentId).then(result => { return <PublicBookmark[]>result; }
    );
  }

  saveUserBookmark(userBookmark?: UserBookmark): Promise<UserBookmark> {

    return this.bookmarksEndpointService.getSaveUserBookmarkEndpoint(userBookmark)
      .then((response: any) => { return <UserBookmark>response });
  }

  deleteUserBookmark(userBookmarkID?: string): Promise<UserBookmark> {

    return this.bookmarksEndpointService.getDeleteUserBookmarkEndpoint(userBookmarkID)
      .then((response: any) => { return <UserBookmark>response });
  }

  getUserBookmark(userBookmarkID: string): Promise<UserBookmark> {

    return this.bookmarksEndpointService.getWitDetails(userBookmarkID)
      .then((response: any) => { return <UserBookmark>response });
  }
}
