import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { OverviewmapService } from "../overviewmap/overviewmap.service";
import { ConfigService } from "../shared/utils/config.service";
import { EsriService } from "../esri/js-esri.service";
import { ImageServiceEndpoint } from "./image-service-endpoint.service";
import { ImageService } from "../../models/imageservice/image-service.model";

@Injectable()
export class GalleryMapService {


  constructor(
    private sharedService: SharedService,
    private esriService: EsriService,
    //private overviewmapService: OverviewmapService,
    private configService: ConfigService,
    private imageServiceEndPoint: ImageServiceEndpoint) {
    
  }

  ImageServiceParameters: any;
  ImageryLayer: any;
  public galleryMap: any;

  getGalleryMap(): any {

    this._createBasemapGallery(this.esriService.BasemapGallery, this.esriService.Basemap, this.esriService.SpatialReference, this.esriService.TileLayer);
  }

  _createBasemapGallery( BasemapGallery, Basemap, SpatialReference, MapImageLayer) {

    if (this.galleryMap) {
      return;
    }
    var basemaps = [];       

    var imgeryLayer = new MapImageLayer({
      url: this.configService.WorldImageryMapUrl
    });
    var ImageryBasemap = Basemap.fromId("satellite");
    //  new Basemap({
    //  baseLayers: [imgeryLayer],
    //  title: "Знімки",
    //  thumbnailUrl: this.configService.WorldImageryThumbnailMapUrl
    //});

    var grayLayer = new this.esriService.VectorTileLayer("https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer"); // this.configService.WorldGreyMapURL
    var GrayBasemap: __esri.Basemap = Basemap.fromId("gray-vector");
    //  new this.esriService.Basemap({
    //  baseLayers: [grayLayer],
    //  title: "Біла карта",
    //  thumbnailUrl: this.configService.WorldGreyMapThumbnailURL
    //});
    //var streetsLayer = new MapImageLayer({
    //  url: this.configService.WorldStreetMapUrl
    //});
    var StreetsBasemap = Basemap.fromId("streets-vector");
    //new Basemap({
    //  baseLayers: [streetsLayer],
    //  title: "Вулиці",
    //  thumbnailUrl: this.configService.WorldStreetThumbnailMapUrl
    //});
    var topoLayer = new MapImageLayer(this.configService.WorldTopoMapURL);
    var TopoBasemap = Basemap.fromId("topo-vector");
    //  new Basemap({
    //  baseLayers: [topoLayer],
    //  title: "Топографічні",
    //  thumbnailUrl: this.configService.WorldTopoMapThumbnailURL
    //});

    const osmLayer = new this.esriService.OpenStreetLayer();
    var OpenStreetBasemap = 
      new Basemap({
        baseLayers: [osmLayer],
      title: "OpentStreet",
        thumbnailUrl: this.configService.WorldStreetThumbnailMapUrl
    })


    let localBasemapsSource: __esri.LocalBasemapsSource = new this.esriService.LocalBasemapsSource();
    localBasemapsSource.basemaps.addMany([OpenStreetBasemap, TopoBasemap, GrayBasemap, ImageryBasemap]); //  StreetsBasemap

    let basemap = (Basemap as __esri.Basemap);
    
    //let mapsSource = 
    this.galleryMap = new BasemapGallery({
      showArcGISBasemaps: false,
      source: localBasemapsSource, // [Basemap.fromId("topo-vector"), Basemap.fromId("hybrid")],
      view: this.sharedService.mapView
    }, Constants.basemapId);

    //this.galleryMap.source.basemaps.add(GrayBasemap); 
    var self = this;  

    this.galleryMap.watch("activeBasemap", function () {

      let selected = this.activeBasemap;

      let thumbnailUrl = selected.thumbnailUrl;
      self.sharedService.setThumbnailUrl(thumbnailUrl);
 
      //let isVisibleOverviewMap = self.sharedService.overviewMap.visible;
      //self.sharedService.overviewMap.destroy();
    
      //self.sharedService.overviewMap = null;
      //self.overviewmapService.getOverviewMap(isVisibleOverviewMap);

    });
    
  }

  addPublicMap(Id: string) {
    if (this.galleryMap) {
      return this.imageServiceEndPoint.getImagePublicServiceByIdEndpoint(Id).then(result => {
        return <ImageService>result
      }).then(result => {
        let _imageServices: ImageService = result;
        var ImageBasemap: __esri.Basemap = new this.esriService.Basemap({
          title: _imageServices.name,
          thumbnailUrl: "../../../assets/images/orthophoto.jpg"
        });
        _imageServices.details.forEach(serviceDetail => {
          let imageServiceLayer: any;
          if (serviceDetail.layers.length > 0) {
            let sublayers = serviceDetail.layers.map(x => {
              return { id: x.layerName }
            })
            imageServiceLayer = new this.esriService.MapImageLayer(this.configService._baseUrlRegionServices + serviceDetail.serviceURL, {
              sublayers: sublayers,
              disableClientCaching: false
            });
          } else {
            imageServiceLayer = new this.esriService.TileLayer(this.configService._baseUrlRegionServices + serviceDetail.serviceURL, {              
              disableClientCaching: false
            });
          }
          ImageBasemap.baseLayers.add(imageServiceLayer);
          ImageBasemap.set<string>("ImageServiceGUID", _imageServices.id);
        });
        this.galleryMap.source.basemaps.add(ImageBasemap); 
        this.galleryMap.source.refresh();
      });
    }
  }
  addUsersMaps(regionId: string): any {

    if (this.galleryMap) {
      //var grayLayer = new this.esriService.MapImageLayer(this.configService.WorldGreyMapURL);
      //var GrayBasemap = new this.esriService.Basemap({
      //  baseLayers: [grayLayer],
      //  title: "Біла карта",
      //  thumbnailUrl: this.configService.WorldGreyMapThumbnailURL
      //});
      //this.galleryMap.source.basemaps.add(GrayBasemap);
      var streetsLayer = new this.esriService.MapImageLayer({
      url: this.configService.WorldStreetMapUrl
      });
      var StreetsBasemap = this.esriService.Basemap.fromId("streets-vector");
      //  new this.esriService.Basemap({
      //  baseLayers: [streetsLayer],
      //  title: "Вулиці",
      //  thumbnailUrl: this.configService.WorldStreetThumbnailMapUrl
      //});
     // this.galleryMap.source.basemaps.add(StreetsBasemap);

      if (regionId && regionId != "") {
        this.imageServiceEndPoint.getImageServiceByRegionEndpoint(regionId).then(result => {
          return <ImageService[]>result
        }).then(result => {
          let _imageServices: ImageService[] = result;

          _imageServices.forEach(_service => {
            var ImageBasemap: __esri.Basemap = new this.esriService.Basemap({
              title: _service.name,
              thumbnailUrl: "../../../assets/images/orthophoto.jpg"
            });

            _service.details.forEach(serviceDetail => {
              let imageServiceLayer: any;
              if (serviceDetail.layers.length > 0) {
                let sublayers = serviceDetail.layers.map(x => {
                  return { id: x.layerName }
                })
                imageServiceLayer = new this.esriService.MapImageLayer(this.configService._baseUrlRegionServices + serviceDetail.serviceURL, {
                  sublayers: sublayers,
                  disableClientCaching: false
                });
              } else {
                imageServiceLayer = new this.esriService.TileLayer(this.configService._baseUrlRegionServices + serviceDetail.serviceURL, {
                  disableClientCaching: false
                });
              }
              
              ImageBasemap.baseLayers.add(imageServiceLayer);
              ImageBasemap.set<string>("ImageServiceGUID", _service.id);
            });
            this.galleryMap.source.basemaps.add(ImageBasemap);

          });
          this.galleryMap.source.refresh();

        });
      }
    }

  }
 
  clearBasemaps() {
    if (this.galleryMap) {
      let localStore = (this.galleryMap.source as __esri.LocalBasemapsSource);
      if (localStore.basemaps.length<=4) {
        return;
      }
      let _map = (this.galleryMap as __esri.BasemapGallery);
      let _id = _map.activeBasemap.id;

      let _needrefresh: boolean = false;
      while (localStore.basemaps.length > 4)
      {
        if (localStore.basemaps.getItemAt(4).id == _id) {
          _needrefresh = true;
        }
        localStore.basemaps.removeAt(4);
      }

      this.galleryMap.source.refresh();

      if (_needrefresh)
      {
        _map.activeBasemap = this.galleryMap.source.basemaps.items[0];
      }
    }
    
  }

  selectBaseMap(imageServiceID: string) {
    let localStore = (this.galleryMap.source as __esri.LocalBasemapsSource);
    let baseMap = localStore.basemaps.find(x => x.get<string>("ImageServiceGUID") == imageServiceID);
    if (baseMap) {
      let _map = (this.galleryMap as __esri.BasemapGallery);
      _map.activeBasemap = baseMap;
    }
  }

  getBaseMapImageServiceGUID() {
    let map = (this.galleryMap as __esri.BasemapGallery).activeBasemap;
    if (map.get<string>("ImageServiceGUID")) {
      return map.get<string>("ImageServiceGUID");
    } else {
      return null;
    }
  }
}
