export const environment = {
  production: true,
  instrumentationKey: 'f654bee2-2c5d-41a4-b7b7-277b06abbdcb',
  apiURI: 'https://api.webgis.ua',
  gisToken: 'k8sxJjxC1psB6mGbp-vnuM7ej-hO4dXJCCUTiriENulqcADRAJzjjibqhj7WdGiX', // for IP 52.136.231.77
  printServerUrl: 'https://arcgisserver.xgis.com.ua/gis/rest/services/print/ExportWebMap/GPServer/Export%20Web%20Map', 
  baseUrlRegionServices: 'https://arcgisserver.xgis.com.ua/gis/rest/services/',
  exportGeodataServiceUrl: 'https://arcgisserver.xgis.com.ua/gis/rest/services/utils/TableToExcel/GPServer/Table%20To%20Excel',
  importGeodataServiceUrl: 'https://arcgisserver.xgis.com.ua/gis/rest/services/utils/ExcelToTable/GPServer/Excel%20To%20Table',
  uploadExcelToImportUrl: 'https://arcgisserver.xgis.com.ua/gis/rest/services/utils/ExcelToTable/GPServer/uploads/upload',
  proxyPageUrl: 'https://proxy.xgis.com.ua/proxy.ashx',
  proxyCorsServer: 'proxy.xgis.com.ua',
  cadastreMapCorsServer: 'map.land.gov.ua',
  cadastreMapWMSService: 'https://m1.land.gov.ua/geowebcache/service/wms',
  cadastreGetInfoUrl: 'https://map.land.gov.ua/mapi/get-object-info',
  azureStorageSASUrl: 'https://xgisstorage.blob.core.windows.net/?',
  uploadCadastralXMLUrl: 'https://arcgisserver.xgis.com.ua/gis/rest/services/utils/ConvertXML/GPServer/uploads/upload',
  importCadastralXMLUrl: 'https://arcgisserver.xgis.com.ua/gis/rest/services/utils/ConvertXML/GPServer/Convert%20Cadastre%20XML%204',
};
