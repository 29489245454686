<div class="panel-header">
  <a (click)="goToLayers()" class="btn-icon">
    <span class="icon-arrow-left"></span>
  </a>
  <span id="legendList">
    {{layerTitle}}
  </span>
</div>

<div class="legend-list">
  <dx-list [items]="features"
           [hoverStateEnabled]="false"
           itemTemplate="objectsTemplate"
           noDataText="Нема даних">
    <div *dxTemplate="let feature of 'objectsTemplate'">
      <div class="legend-item " [ngClass]="{'legend-item--active': feature.isSelected}" (click)="goTo(feature)">
        <span>
          {{ feature.name }}
        </span>
      </div>

      </div>
  </dx-list>
</div>
