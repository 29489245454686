import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Location } from "@angular/common";
import { LegendsService } from "../../services/legends/legends.service";
import { SharedService } from "../../services/shared/shared.service";
import { Legend, LegengsIcon, LegendItem, isFlashType } from "../../models/layers/legend.model";
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from "../basecomponent/base.component";
import { ExportGeodataService } from "../../services/exportgeodata/export-geodata.service";
import { UserService } from "../../services/user/user.service";
import notify from 'devextreme/ui/notify';
import { ImportGeodataService } from "../../services/importgeodata/import-geodata.service";
import { EsriService } from '../../services/esri/js-esri.service';
import { MapLayersService } from '../../services/map/map.layers.service';
import { MapLayersEndpoint } from '../../services/map/endpoint-map.layers.service';
import { LayerDataWithAttr } from '../../models/layers/layer.data.model';
import { LayersService } from '../../services/layers/layers.service';
import { FilterAttributeService } from '../../services/filterattribute/filter-attribute.service';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MapService } from '../../services/map/map.service';
import { Constants } from '../../services/shared/data/data.service';
import { CoordwidgetComponent } from '../coordinates/coordinates.component';
import { SquareUnits } from '../../models/draw/square-units.model';
import { DxSliderComponent } from 'devextreme-angular';
import { parseNumber } from 'devextreme/localization';


@Component({
  selector: 'app-legends',
  templateUrl: './legends.component.html',
  styleUrls: ['./legends.component.scss']
})
export class LegendsComponent extends BaseComponent implements OnInit, OnDestroy {
  icons: LegengsIcon[];
  itemHold: any;
  legends: Array<LegendItem> = new Array<LegendItem>();
  subscription: Subscription;
  subscriptionLegend: Subscription;

  constructor(
    private legendsService: LegendsService,
    private sharedService: SharedService,
    private location: Location,
    private exportGeodataService: ExportGeodataService,
    private userService: UserService,
    private importGeodataService: ImportGeodataService,
    private esriService: EsriService,
    private mapLayersService: MapLayersService,
    private layersService: LayersService,
    private filterAttributeService: FilterAttributeService,
    private router: Router,
    private mapService: MapService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private activeRouter: ActivatedRoute,
  ) {
    super();
    
  }

  hasExportPermissions: boolean = false;

  private selectedItems: any[] = new Array<any>();

  ngOnInit() {
    this.hasExportPermissions = this.userService.User.isEdit;
    this.subscription = this.sharedService.getRegionID().subscribe(regionID =>
    {
      //if (this.sharedService.getCurrentFilterValue()) {
      //  this.legends = this.legendsService.getLegendsByFilter();
      //} else {
      //  this.sharedService.getPublicLegendChanged().subscribe(res => {
      //    this.legends = this.legendsService.getLegendsByLayers(); 
      //  })        
      //}
      this.sharedService.getPublicLegendChanged().subscribe(res => {
        this.legends = this.legendsService.getLegendsByLayers();
      })
    }           
    );
  }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
    if (this.subscriptionLegend) {
      this.subscriptionLegend.unsubscribe();
    }
  }

  goBack() {
    this.location.back();
  }
  
  getIcon(type: any): any {
      
    let temp = this.icons[this.icons.findIndex(el => el.type === type)];
    console.log('type of symbol: ', type);
    return temp? temp.icon: '';
  }

  onItemHold(e) {

    this.itemHold = e.itemData;

  }

  reorder(e) {

    if (!!this.itemHold) {
      let _map = this.sharedService.map as __esri.Map;      
      //let _layer = _map.findLayerById(this.itemHold.id);   
      let _layer = _map.findLayerById(this.itemHold.layerID);
      let _index = this.legends.length > 0 ? this.legends.length - e.toIndex - 1 : 0;
      _map.reorder(_layer, _index);
    }
  }


  selectLayerByID(id, layerId, selectValue, layerGuid, defaultValues) {
    let legend = defaultValues ? this.legends.find(x => x.layerGuid == layerGuid && x.id == id && x.defaultValues.some(v => v == defaultValues[0])) :
      this.legends.find(x => x.layerGuid == layerGuid && x.id == id);
    //let isRemoveRed = legend.isFlash == isFlashType.red && selectValue == isFlashType.red;    
    legend.isFlash = selectValue == 'none' ? 'red' : 'none'; //(selectValue == 'red' ? 'flash' : 'none') ;

    if (legend.isFlash != isFlashType.none) {
      
      let subQuery = this.legendsService.getSubQuery(legend);

      if (legend.defaultValues) {        
        if (legend.SubTypeField) {                  
          this.legendsService.flashLayer(id, layerId, legend, subQuery, defaultValues[0]);
        } else {
          this.legendsService.flashLayer(id, layerId, legend, subQuery);
        }
      } else {               
        this.legendsService.flashLayer(id, layerId, legend, subQuery);
      }
    } else {
      legend.visibleCount = null;
      this.legendsService.removeFlashLayer(layerId, layerGuid, defaultValues ? defaultValues[0] : null);
    }
    

  }

  exportData(Id, layerId, legend?) {
    notify("Експорт даних", "info", 3500);

    let subQuery = this.legendsService.getSubQuery(legend)
    this.exportGeodataService.exportGeodataById(Id, layerId, legend.layerURL, subQuery, legend.name);    
  }

  onValueChanged(e, data) {

    let layer = (this.sharedService.map as __esri.Map).findLayerById(data.layerID);
    if (layer?.type == "map-image") {
      let changeLayer = (layer as __esri.MapImageLayer).sublayers.find(x => x.id == data.id);
      changeLayer.opacity = e.value / 100;
    } else if (layer?.type == "feature") {
      (layer as __esri.FeatureLayer).opacity = e.value / 100;      
    }
    data.opacity = e.value;

    //let layerLegends = this.legends.filter(f => f.layerGuid == data.layerGuid);
    //let component = (e.component as DxSliderComponent);
    //component.onValueChanged = null;
    //layerLegends .forEach(item => {
    //  item.opacity = e.value / 100;

    //  let tt = item;
    //})

    //component.onValueChanged.subscribe((ex, dt) => {
    //  this.onValueChanged(ex, dt);
    //});
    //this.legends[this.legends.findIndex(el => el.id === data.id)] = data;
  }

  
  fileEvent(e) {
    notify("Імпорт даних почався", "info", 2500);
    let layerId = e.target.attributes["layerID"].value;
    let Id = e.target.attributes["sublayerID"].value;
    
    let filedata: File = e.target.files.item(0);
    console.log(e);
    this.importGeodataService.importGeodata(filedata, layerId, Id).subscribe(result => {
      notify(result, "info", 4500);
      console.log(result);
      e.srcElement.value = null;
    });
  }

  addObject(data) {
    
    //this.mapLayersService.getLayerInfo(data.layerURL + "/" + data.id).then(result => {
    let url = data.layerURL.replace('FeatureServer', 'MapServer');
    this.mapLayersService.getLayerInfo(url).then(result => {
      let tmp = result;
      let subtypes: any[] = result.subtypes;
      let ids: any[] = [];
      ids.push(data.layerGuid);
      this.layersService.getLayerData(data.layerGuid).subscribe(layerAttr => {
        this.createSketchViewModel(data, result, layerAttr);
      })
      
    })
   
  }

  
  private async refreshRecordCount(legend) {

    let url = legend.layerURL;
    let subQuery = this.legendsService.getSubQuery(legend);
    this.mapService.getRecordCountByUrl(url, subQuery).then(function (count) {
      legend.totalCount = count;     
    })
  }

  private InitCoordWidget(): CoordwidgetComponent {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CoordwidgetComponent);
    let containerRef = this.viewContainerRef;
    containerRef.clear();
    const coordComponent = <CoordwidgetComponent>containerRef.createComponent(componentFactory).instance;
    return coordComponent;
  }
  createSketchViewModel(data: LegendItem, layerInfo, layerAttr: LayerDataWithAttr) {

    let graphicsLayer: __esri.GraphicsLayer = new this.esriService.GraphicsLayer({
      id: "tempLayerID"
    });
    this.sharedService.map.layers.add(graphicsLayer);

    let sketchViewModel = new this.esriService.SketchViewModel({
      view: this.sharedService.mapView,
      updateOnGraphicClick: false,
      layer: graphicsLayer,
      pointSymbol: {
        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        style: "circle",
        color: [20, 146, 220, 1],
        size: "1px",
        outline: { // autocasts as new SimpleLineSymbol()
          color: [20, 146, 220, 1],
          width: 1
        }
      },
      polylineSymbol: {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: [20, 146, 220],
        width: "2",
        cap: "round",
        join: "round"
      },
      polygonSymbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [20, 146, 220, 0.26],
        style: "solid",
        outline: {
          color: [20, 146, 220],
          width: 2
        }
      }
    });
    
    let coordWidget = this.InitCoordWidget();

    switch (layerInfo.geometryType) {
      case "esriGeometryPoint":
        sketchViewModel.create("point");
        break;
      case "esriGeometryPolyline":
        sketchViewModel.create("polyline");
        coordWidget.isPolyline = true;
        break;
      case "esriGeometryPolygon":
        sketchViewModel.create("polygon");
        coordWidget.isPolygon = true;
        break;
    }

    var self = this;
    sketchViewModel.on("create", function (event) {
      if (event.state != "complete") {
        if (event.state == "cancel") {
          self.viewContainerRef.clear();
          return;
        }
        if (event.tool == "polyline" ) {

          var distance = self.esriService.GeometryEngine.geodesicLength(event.graphic.geometry, "meters");
          if (distance < 0) {
            // simplify the polygon if needed and calculate the area again
            var simplifiedPolygon = self.esriService.GeometryEngine.simplify(event.graphic.geometry);
            if (simplifiedPolygon) {
              distance = self.esriService.GeometryEngine.geodesicLength(simplifiedPolygon, "meters");
            }
          }

          coordWidget.lengthLine = distance.toFixed(3);
        }

        if ( event.tool == "polygon") {
          let path: any[] = event.graphic.geometry.rings[0];
          let allPath = Object.assign([], path);
          allPath.splice((path.length - 1), 1);
          let _polyline = new self.esriService.Polyline({
            spatialReference: self.sharedService.mapView.spatialReference,
            hasZ: false,
            paths: allPath
          });

          var distance = self.esriService.GeometryEngine.geodesicLength(_polyline, "meters");
          if (distance < 0) {
            // simplify the polygon if needed and calculate the area again
            var simplifiedPolygon = self.esriService.GeometryEngine.simplify( _polyline);
            if (simplifiedPolygon) {
              distance = self.esriService.GeometryEngine.geodesicLength(simplifiedPolygon, "meters");
            }
          }
          coordWidget.lengthLine = distance.toFixed(3);

          var area = self.esriService.GeometryEngine.geodesicArea(event.graphic.geometry, SquareUnits.meter);
          var areaHa = self.esriService.GeometryEngine.geodesicArea(event.graphic.geometry, SquareUnits.hectares);
          if (area < 0 || areaHa < 0) {
            // simplify the polygon if needed and calculate the area again
            let simplifiedPolygon = self.esriService.GeometryEngine.simplify(event.graphic.geometry);
            if (simplifiedPolygon) {
              area = self.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, SquareUnits.meter);
              areaHa = self.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, SquareUnits.hectares);
            }
          }

          coordWidget.areaPolygon = area.toFixed(3);
        }
        return;
      }
      self.viewContainerRef.clear();

      let strSubType: string;
      if (layerInfo.subtypes && layerInfo.subtypes.length > 0 && layerInfo.subtypeFieldName
        && layerInfo.subtypeFieldName != '' && data.defaultValues && data.defaultValues[0]!='') {
        //let str: string = '{"' + layerInfo.subtypeFieldName + '" : ' + data.defaultValues[0] + '}';
        strSubType = '"' + layerInfo.subtypeFieldName + '" : ' + data.defaultValues[0];        
      }
      let strKOATUU: string;

      layerAttr.layerAttributes.forEach(x => {
        if (x.hasFilter) {
          if (x.filterType == self.filterAttributeService.TypeKOATUU) {
            strKOATUU = '"' + x.name + '" : "' + self.sharedService.CurrentRegionCode + '"';
          }
        }
      })
      let strAttributes: string;

      strAttributes = (strKOATUU ? strKOATUU : '') + (strKOATUU && strSubType ? ' , ' : '') + (strSubType ? strSubType: '');

      if (strAttributes) {
        strAttributes = '{' + strAttributes + '}';
        
        let strObject = JSON.parse(strAttributes);
        event.graphic.attributes = strObject;
      }
      
      
      let _url = data.layerURL;
      _url = _url.replace('MapServer', 'FeatureServer');
      let tempLayer: __esri.FeatureLayer = new self.esriService.FeatureLayer({
        url: _url  //+ "/" + data.id //https://arcgisserver.xgis.com.ua/gis/rest/services/template_custom_layer/template_custom_layer/FeatureServer/0",
      });
      tempLayer.applyEdits({
        addFeatures: [event.graphic]
      }).then(val => {
        self.refreshRecordCount(data);
        let _map = self.sharedService.map as __esri.Map;
        let _layer = _map.layers. find(layer => layer.id == "tempLayerID");
        if (_layer) {
          _map.layers.remove(_layer);
        }


        _map.layers.forEach(layer => {
          if (layer.type=='map-image' && (layer as __esri.MapImageLayer).sublayers) {
            let isSublayer = (layer as __esri.MapImageLayer).sublayers.find(function (sub) { return sub.id == parseInt(data.id) });
            if (isSublayer) {
              (layer as __esri.MapImageLayer).refresh();
            }
          } else if (layer.type == 'feature') {
            let featureLayer = layer as __esri.FeatureLayer;
            if (data.layerID == featureLayer.id) { // data.layerGuid == featureLayer.get<any>("LayerDataGUID") && 
              featureLayer.refresh();              
            }                        
            
          }
        });

      }).catch(ex => {
        notify(`Помилка при збережені даних.\r\n  ${ex._body}`, "error", 3500);
      });
    });
  }

  navigateToLayer(legendGUID) {
    //let _backUrl =

    const url: Observable<string> = this.activeRouter.url.pipe(map(segments => segments.join('/')));
    url.subscribe(_url => {
      this.router.navigate(['/navigationlist', legendGUID, { backUrl: _url/*this.router.routerState.snapshot.url*/ }], { skipLocationChange: true });
    })   

  }

  navigateToRaster(legendGUID) {
    let item = this.legends.find(X => X.legendGUID == legendGUID);
    if (item) {      
      var extent = item.extent;
      let opts = {
        duration: 500
      };
      if (!extent) {
        return;
      }
      var centerPoint = new this.esriService.Point({
        x: (extent.xmin + extent.xmax) / 2,
        y: (extent.ymin + extent.ymax) / 2,
        spatialReference: this.esriService.SpatialReference.WebMercator // extent.spatialReference 
      });
      var geographicCenter = this.esriService.webMercatorUtils.webMercatorToGeographic(centerPoint);
      this.sharedService.mapView.goTo({ center: [geographicCenter.longitude, geographicCenter.latitude] }, opts); 
    }
  }
}
