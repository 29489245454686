import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchService } from "../../services/search/search.service";
import { SharedService } from '../../services/shared/shared.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit, OnDestroy {

  subscription: Subscription;
  subscriptionText: Subscription;
  
  arrSearchLayer: any[] = [ { layerDataGUID:'-1', name: 'Всі', isSelected : true} ];

  searchResult: any[] = [];
  searchText: string = '';
  selectedItem: any;
  showSearchLayer: boolean = false;
  showSearchResult: boolean = false;
  showOnlyLayer: boolean = false;
  _showOnlyLayer: boolean = false;
  isSearching: boolean = false;
  isNotFound: boolean = false;
  isFirstSearch: boolean = true;
  delayKeyUp = (() => {
    let timer: NodeJS.Timeout;
    return (func: Function, ms: number) => {
      timer ? clearTimeout(timer) : null;
      timer = setTimeout(() => func(), ms);
    };
  })();

  constructor(private searchService: SearchService, private sharedService: SharedService, private route: ActivatedRoute) { }

  ngAfterViewInit() {

    this.sharedService.mapView.when(val => {
      this.searchService.getSearch();
      this.subscription = this.searchService.onStartSearch().subscribe(x => {        
        this.isSearching = x;
      })      
    })

    this.subscriptionText = this.sharedService.getSearchInit().subscribe(val => {
      if (val) {
        var self = this;
        setTimeout(function () {          
          self.clearSelctedItem();
          self.searchService.search.clear();
          if (self.arrSearchLayer.length > 1) {
            self.arrSearchLayer.splice(1, self.arrSearchLayer.length - 1);            
          }
          self.searchService.searchSources.forEach(layer => {
            let item = {
              layerDataGUID: layer.searchLayer.layer.LayerDataGUID,
              name: layer.searchLayer.name,
              isSelected: false
            };
            self.arrSearchLayer.push(item);
          });
          let allLayers = self.arrSearchLayer.find(f => f.layerDataGUID == '-1');
          allLayers.isSelected = true;

          if (self.isFirstSearch) {
            self.searchFromQuery();
          }          
          
        }, 500);
      }
    });
    
    this.searchService.onSuggComplete().subscribe(value => {
      if (value) {
        if (value.searchTerm == this.searchText) {
          let result = value.results.filter(f => f.results.length > 0);
          
          this.searchResult = result.map(m => {
            let maxSuggestions = 5;
            if (m.results?.length > 0) {
              maxSuggestions = m.results[0].maxSuggestions;
            }
            return { sourceIndex: m.sourceIndex, name: m.source.name, maxSuggestions: maxSuggestions, results: m.results.map(r => { return { key: r.key, text: this.selectSearchText(r.text), sourceIndex: r.sourceIndex, isSelected: false } }) };
          })
          this.isSearching = false;
          this.showOnlyLayer = this._showOnlyLayer;
          this.isNotFound = result.length == 0;
          if (this.isFirstSearch && !this.isNotFound) {
            this.selectSearchItem(this.searchResult[0].results[0]);
            this.isFirstSearch = false;
          }
        }        
      }
    });

    this.searchService.onClear().subscribe(res => {
      this.searchResult = [];
      this.selectedItem = null;
      this.isSearching = false;
    });

    this.sharedService.getHiddenSearching().subscribe(val => {
      if (val) {
        if (this.showSearchResult) {
          this.showSearchResult = false;
        }
        if (this.showSearchLayer) {
          this.showLayerMenu(false);
        }
      }
    })
  }

  private showMoreByIndex(val, inc) {
    this.clearSelctedItem();    
    this.searchService.IncreaseLimit(val, this.searchText, inc);
  }

  private setCountByIndex(val, inc) {
    this.clearSelctedItem();
    this.searchService.SetSuggestionsLimit(val, this.searchText, inc);
  }

  private showByLayer(val) {
    this.clearSelctedItem();
    this.searchService.ShowByLayer(val, this.searchText);
    this._showOnlyLayer = true;
  }

  private clearSelctedItem() {
    if (this.selectedItem) {
      this.selectedItem.isSelected = false;
      this.selectedItem = null;
      this.searchService.search.search(null);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscriptionText?.unsubscribe();
  }

  async onTextChanged(event) {
    this.isNotFound = false;
    if (event.value == '') {
      this.searchService.search.clear();
    } else {
      this.delayKeyUp(() => { this.suggestSearch(event.value) }, 500);
    }    
  }

  private suggestSearch(value) {
    if (this.showSearchLayer) {
      this.showLayerMenu(false);
    } else {
      this.searchService.search.suggest(value);
    }   
  }

  selectSearchItem(item) {
    if (!item.isSelected) {
      if (this.selectedItem) {
        this.selectedItem.isSelected = false;
      }
      item.isSelected = true;
      this.selectedItem = item;
      item.searchText = this.searchText;
    }
    this.searchService.search.search(item);
  }

  switchValueChanged(event, data) {
    if (event.value) {
      data.isSelected = true;
      if (data.layerDataGUID == '-1') {
        this.arrSearchLayer.filter(f => f.layerDataGUID != '-1' && f.isSelected).forEach(x => {
          x.isSelected = false;
        });
      } else {
        let allLayers = this.arrSearchLayer.find(f => f.layerDataGUID == '-1');
        allLayers.isSelected = false; 
      }                 
    } else {
      data.isSelected = false;
      let allSelected = this.arrSearchLayer.filter(f => f.layerDataGUID != '-1' && f.isSelected);
      if (allSelected.length == 0) {
        let allLayers = this.arrSearchLayer.find(f => f.layerDataGUID == '-1');
        allLayers.isSelected = true;
      }
    }
    
  }

  showLayerMenu(value: boolean) {
    this.showSearchLayer = value;
    if (!value) {      
      let ids = this.arrSearchLayer.filter(f => f.layerDataGUID != '-1' && f.isSelected).map(m => {
        return m.layerDataGUID;
      });
      this.searchService.SetSearchLayers(ids);
      if (this.searchText == '') {
        this.searchService.search.clear()
      } else {
        this.searchService.search.suggest(this.searchText);
      }
    }
  }

  onFocusTextBox(event) {
    this.showSearchResult = true;
  }

  onFocusOutTextBox(event) {
    //if (this.searchText?.replace(' ', '') == '') {
    if (this.searchResult.length == 0) {
      var self = this;
      setTimeout(function () {
          self.showSearchResult = false;
        }, 200);
      ;
    }    
  }

  toAllLayers() {
    this._showOnlyLayer = false;
    this.clearSelctedItem();
    this.searchService.search.activeSourceIndex = -1;
    if (this.searchText == '') {
      this.searchService.search.clear()
    } else {
      this.searchService.search.suggest(this.searchText);
    }
  }

  selectSearchText(value: string) {
    let params: any[] = this.searchText.split(' ');      
    let idx = params.findIndex(f => f == " " || f == "");
    while (idx >= 0) {
      params.splice(idx, 1);
      idx = params.findIndex(f => f == " " || f == "");
    }
    for (let i = 0; i < params.length; i++) {
      let _param: string = params[i].replace(/ /g, "");
      
      var lastIndex = 0;
      var result = '';
      while (true) {
        var index = value.toLowerCase().indexOf(_param.toLowerCase(), lastIndex);
        if (index === -1) break;
        let subStr = value.substr(index, _param.length);
        result += value.substring(lastIndex, index) + `<mark>${subStr}</mark>`;
        lastIndex = index + _param.length ;
      }

      result += value.substring(lastIndex);
      value = result;    
    }

    
    return result;
  }

  isSelectedAllLayer() {
    let index = this.arrSearchLayer.find(f => f.layerDataGUID != '-1' && f.isSelected);    
    return index ? true : false;
  }

  private searchFromQuery() {
    this.route.queryParams.subscribe(params => {
      let searchQuery = params['query'];
      if (searchQuery) {
        this.isFirstSearch = true;
        this.showSearchResult = true;
        this.searchText = searchQuery;
      } else {
        this.isFirstSearch = false;
      }
    });
  }
}
