import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";
import { DrawMeasureService } from "./draw-measure.service";
import { SquareUnits } from "../../models/draw/square-units.model";

@Injectable()
export class DrawPoligonService {

  constructor(private sharedService: SharedService, private esriService: EsriService, private drawMeasureService: DrawMeasureService ) {
    
  }

  private graphics: __esri.Collection<__esri.Graphic>;
  //private unit: string = SquareUnits.meter;
  private polygonBtn: any;
  private self: any = this;
  getPolygonBtn() {    
    this.sharedService.mapView.ui.add("draw-polygon", "bottom-right");    
  }

  startDraw(unit?) {
    this.drawMeasureService.createGraphicsLayers();
    this.graphics = new this.esriService.Collection();
    this.sharedService.setStartedDrawing(true);
    this.sharedService.setPolygonDrawing(true);
    this.sharedService.setPolylineDrawing(false);
    this.enableCreatePolygon(this.sharedService.polygonDraw, this.sharedService.mapView);
  }

  enableCreatePolygon(draw, view) {
    // create() will return a reference to an instance of PolygonDrawAction
    var action = draw.create("polygon", { mode: "click" });
    var self = this;
    // focus the view to activate keyboard shortcuts for drawing polygons
    view.focus();

    // listen to vertex-add event on the action
    action.on("vertex-add", function (env) {
      self.drawPolygon(env, draw )
  });

    // listen to cursor-update event on the action
    action.on("cursor-update", function (env) {
      self.drawPolygon(env, draw)
    }); //this.drawPolygon);

    // listen to vertex-remove event on the action
    action.on("vertex-remove", function (env) {
      self.drawPolygon(env, draw)
    });

    // *******************************************
    // listen to draw-complete event on the action
    // *******************************************
    action.on("draw-complete", function (env) {
      self.drawPolygon(env, draw)
      self.graphics.removeAll();
      self.sharedService.setPolygonDrawing(false);
    });
  }



  drawPolygon(evt, draw) {    
    var vertices = evt.vertices;    

    // create a new polygon
    var polygon = this.createPolygon(vertices);
    ///////////////////////////////////////
    if (evt.type != "draw-complete") {
      let tempGraphicx: any[] = [];
      //remove existing graphic

      this.graphics.forEach(x => {
        if (!x.symbol.get<boolean>("labelType")) {
          tempGraphicx.push(x);
        }
      });
      //this.sharedService.mapView.graphics.removeMany(tempGraphicx);
      this.sharedService.MeasureLayer.removeMany(tempGraphicx);
      this.graphics.removeMany(tempGraphicx);

      // create a new graphic representing the polygon, add it to the view
      var graphic = this.createGraphic(polygon);
      this.graphics.add(graphic);      
      this.sharedService.MeasureLayer.add(graphic);
      // calculate the area of the polygon

      // start displaying the area of the polygon
      if (evt.type == "vertex-add") {
        let _point = polygon.getPoint(0, polygon.rings[0].length - 2);
        let _point2 = polygon.getPoint(0, polygon.rings[0].length - 1);

        if (evt.vertices.length > 1) {
          //evt.preventDefault();
          let centerPoint = this.drawMeasureService.getCenterPoint(_point, _point2);          
          let lastSegment = this.getLastSegment(polygon);
          let distance = this.esriService.GeometryEngine.geodesicLength(lastSegment, "meters");
          this.labelDistance(centerPoint, distance);
        }

        this.labelMarker(_point2);
      }
    } else {
      let _point = polygon.getPoint(0, 0);
      let _point2 = polygon.getPoint(0, polygon.rings[0].length - 1);

      if (evt.vertices.length > 1) {
        //evt.preventDefault();
        let centerPoint = this.drawMeasureService.getCenterPoint(_point, _point2);
        let lastSegment = this.getFinishedSegment(polygon);
        let distance = this.esriService.GeometryEngine.geodesicLength(lastSegment, "meters");
        this.labelDistance(centerPoint, distance);
      }

      this.labelMarker(_point2);
    }    
    // calculate the area of the polygon
    var area = this.esriService.GeometryEngine.geodesicArea(polygon, SquareUnits.meter);
    var areaHa = this.esriService.GeometryEngine.geodesicArea(polygon, SquareUnits.hectares);
    if (area < 0 || areaHa < 0) {
      // simplify the polygon if needed and calculate the area again
      let simplifiedPolygon = this.esriService.GeometryEngine.simplify(polygon);
      if (simplifiedPolygon) {
        area = this.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, SquareUnits.meter);
        areaHa = this.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, SquareUnits.hectares);
      }
    }        
    // start displaying the area of the polygon
    this.labelAreas(polygon, area, areaHa);
  }
  createPolygon(vertices) {
    return new this.esriService.Polygon({
      rings: vertices,
      spatialReference: this.sharedService.mapView.spatialReference
    });
  }

  createGraphic(polygon) {
    let graphic = new this.esriService.Graphic({
      geometry: polygon,
      symbol: {
        type: "simple-fill", // autocasts as SimpleFillSymbol
        color: [255, 74, 255, 0.26],
        style: "solid",
        outline: { // autocasts as SimpleLineSymbol
          color: [255, 74, 255],
          width: 2,
          cap: "round",
          join: "round"
        }
      }
    });
    return graphic;
  }
  //Label polyon with its area
  labelAreas(geom, areaM, AreaHe?) {

    let currentUnit = this.sharedService.currentSquareUnitValue();
    let unit = currentUnit == SquareUnits.meter ? ' м' : ' Га';
    let area = currentUnit == SquareUnits.meter ? areaM.toFixed(2) : AreaHe.toFixed(3);

    var graphic = new this.esriService.Graphic({
      squareM: areaM,
      squreHe: AreaHe,
      labelSquare: true,
      geometry: geom.centroid,
      symbol: {
        type: "text",
        color: [255, 255, 255],
        text: area + unit, //+ "<sup>2</sup>",
        xoffset: 3,
        yoffset: 3,
        font: { // autocast as Font
          size: 18
        }
      }
    });

    this.graphics.add(graphic);
    this.sharedService.MeasureLayer.add(graphic);
    //this.sharedService.mapView.graphics.add(graphic);
  }

  labelDistance(centerpoint, area) {    
    let graphic = this.drawMeasureService.labelDistance(centerpoint, area);
    this.graphics.add(graphic);
    this.sharedService.MeasureLabelLayer.add(graphic);    
  }

  labelMarker(point) {
    
    let symbolGraphic = this.drawMeasureService.labelMarker(point);
    this.graphics.add(symbolGraphic);
    this.sharedService.MeasureMarkerLayer.add(symbolGraphic);    
  }  

  getLastSegment(polyline) {    
    let _point = polyline.getPoint(0, polyline.rings[0].length - 2);
    let _point2 = polyline.getPoint(0, polyline.rings[0].length - 1);

    return new this.esriService.Polyline({
      spatialReference: this.sharedService.mapView.spatialReference,
      hasZ: false,
      paths: [
        [
          [_point2.x, _point2.y],
          [_point.x, _point.y]
        ]
      ]
    });
  }

  getFinishedSegment(polyline) {    
    let _point = polyline.getPoint(0, 0);
    let _point2 = polyline.getPoint(0, polyline.rings[0].length - 1);

    return new this.esriService.Polyline({
      spatialReference: this.sharedService.mapView.spatialReference,
      hasZ: false,
      paths: [
        [
          [_point2.x, _point2.y],
          [_point.x, _point.y]
        ]
      ]
    })
  }

  changeSquareLabels(value: SquareUnits) {
    let added =[];
    let removed = [];
    let currentAdd = [];
    let currentRemoved = [];

    this.sharedService.MeasureLayer.graphics.forEach(x => {
      if (x.get<boolean>("labelSquare")) {
        let clone = x.clone();
        let unit = value == SquareUnits.meter ? ' м' : ' Га';
        let _area = value == SquareUnits.meter ? x.get<number>("squareM") : x.get<number>("squreHe");
        let textarea = value == SquareUnits.meter ? _area.toFixed(2) : _area.toFixed(3);
        clone.set<boolean>("labelSquare", true);
        clone.set<number>("squareM", x.get<number>("squareM"));
        clone.set<number>("squreHe", x.get<number>("squreHe"));
        clone.symbol.set<string>("text", textarea + unit);
        if (this.graphics.includes(x)) {
          this.graphics.add(clone);
          this.graphics.remove(x);
        }
        added.push(clone);
        removed.push(x);                
      }
    });
    this.sharedService.MeasureLayer.addMany(added);
    this.sharedService.MeasureLayer.removeMany(removed);
  }

}
