
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from '../shared/base.service';
import { ConfigService } from '../shared/utils/config.service';
import { User } from "../../models/user/user.model";
import { EsriService } from "../esri/js-esri.service";
import notify from 'devextreme/ui/notify'; 
import { SharedService } from '../shared/shared.service';
import * as Sentry from "@sentry/angular-ivy";

// Add the RxJS Observable operators we need in this app.
//import 'rxjs-operators';

@Injectable()

export class UserService extends BaseService   {

  baseUrl: string = this.configService.getApiURI();;
  User: User;
  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();
  
  private loggedIn = false;

  public redirectUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService, private router: Router, private esriService: EsriService,
    private sharedService: SharedService) {
    super();
    

    var expiresDate: Date = new Date(localStorage.getItem('expires_in'));
    var currentDate: Date = new Date();
    try {
      this.loggedIn = !!localStorage.getItem('auth_token') && expiresDate > currentDate;
      if (this.loggedIn) {
        let user = <User>JSON.parse(localStorage.getItem('user'));
        this.User = new User(user.id, user.userName, user.lastName, user.firstName, user.middleName, user.email, user.role, user.isSuperAdmin, user.isEdit, user.isPublish, user.isTool);
        //var token = {
        //  'server': this.configService._baseUrlRegionServices,
        //  'token': localStorage.getItem('gis_token')
        //};
        //this.esriService.IdentityManager.registerToken(token);
        this.registerToken();
        Sentry.setUser({ email: this.User.userName });
      }
      // ?? not sure if this the best way to broadcast the status but seems to resolve issue on page refresh where auth status is lost in
      // header component resulting in authed user nav links disappearing despite the fact user is still logged in
      this._authNavStatusSource.next(this.loggedIn);
      //this.baseUrl = configService.getApiURI();
      //if (!this.loggedIn)
      //  this.router.navigate(['/basemap']);
    } catch(e)
    {
      this.loggedIn = false;
      this.User = null;
      this._authNavStatusSource.next(this.loggedIn);
      localStorage.removeItem('auth_token');
      localStorage.removeItem('expires_in');
      localStorage.removeItem('user');
      localStorage.removeItem('gis_token');
      Sentry.setUser(null);
    }
    
  }

  login(userName, password) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }) 
    }
    return this.http
      .post<any>(
      this.baseUrl + '/api/authorize/authorize',
        JSON.stringify({ userName, password }), httpOptions
      )    
      .pipe(     
        map(res =>
          res
        ),
      map(res => {
        localStorage.setItem('auth_token', res.auth_token);
        localStorage.setItem('expires_in', res.expires_in);
        localStorage.setItem('gis_token', res.gis_token);
        this.loggedIn = true;

        var user = res.user;
        this.User = new User(user.Id, user.UserName, user.LastName, user.FirstName, user.MiddleName, user.Email, user.Role, user.IsSuperAdmin, user.IsEdit, user.IsPublish, user.ISTool );;
        localStorage.setItem('user', JSON.stringify(this.User));
        //this.configService.setGisToken(res.gis_token);
        this.registerToken();
        this._authNavStatusSource.next(true);
        this.sharedService.setShowBookmark(false);
        let redirect = this.redirectUrl ? this.router.parseUrl(this.redirectUrl) : '/layers';
        this.redirectUrl = null;
        Sentry.setUser({ email: this.User.userName });
        this.router.navigateByUrl(redirect);
        return true;
      }),
      catchError(this.handleError),);

  }

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('user');
    localStorage.removeItem('gis_token');
    Sentry.setUser(null);
    this.loggedIn = false;
    this._authNavStatusSource.next(false);
    this.router.navigate(['/basemap']);
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  registerToken() {

    var token = {
      'server': this.configService._baseUrlRegionServices,
      'token': localStorage.getItem('gis_token')
    };

    if (localStorage.getItem('gis_token').length <= 0) {
      localStorage.setItem('gis_token', this.configService.getGisToken());
      token.token = this.configService.getGisToken();
    } else {
      this.configService.setGisToken(localStorage.getItem('gis_token'));
    }
    if (this.esriService.IdentityManager) {
      this.esriService.IdentityManager.registerToken(token);
    }    
      //if (this.esriService.IdentityManager.tokenValidity <= 0) {
      //  notify(`Не вдається підключитись до Gis Server.\r\n `, "error", 5000);
      //} else {
      //  localStorage.setItem('gis_token', this.configService.getGisToken());
      //}
    }  
  //get currentUser(): User {

  //  let user = <User>JSON.parse(localStorage.getItem('user'));
    
  //  return user;
  //}
}

