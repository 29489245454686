export class UserLayer {
  
  constructor(
    id?: string,
    name?: string,
    layerData?: string,
    userId?: string,
    regionId?: string
    ) {

    this.id = id;
    this.layerData = layerData;
    this.name = name;
    this.userId = userId;
    this.regionId = regionId;
  }

  public id: string;
  public name: string;
  public layerData: string;
  public userId: string;
  public regionId: string;  
}
