import { Component, OnInit } from '@angular/core';
import { SharedService } from "../../services/shared/shared.service";
import { PrintMapService } from "../../services/print/print-map.service";

@Component({
  selector: 'app-print-map',
  templateUrl: './print-map.component.html',
  styleUrls: ['./print-map.component.scss']
})
export class PrintMapComponent implements OnInit {

  isShowed: boolean = false;

  constructor(private sharedService: SharedService, private printMapService: PrintMapService) { }

  ngOnInit() {
    this.printMapService.getPrintBtn();
  }

  printMap() {
    this.isShowed = !this.isShowed;
  }

}
