<div class="panel-header">

  <a *ngIf="backToBookmark" (click)="goToBookmark()" class="btn-icon">
    <span class="icon-arrow-left"></span>
  </a>
  <span id="legendList" class="title">
   {{bookamrkTitle}}
  </span>
</div>

  
  <div class="w-scroll">

    <dx-scroll-view direction="vertical">
      <dx-accordion #accordion
                    [dataSource]="userBookmarks"
                    [collapsible]="false"
                    [multiple]="false"
                    [animationDuration]="300"
                    [selectedItems]="[selectedBookmark]"
                    (onSelectionChanged)="selectBookmark($event)">
        <div *dxTemplate="let bookmark of 'title'">
          <div class="item__title">
            {{bookmark.name}}
          </div>
        </div>
        <div *dxTemplate="let bookmark of 'item'">

          <div *ngIf="bookmark.imageSource?.length>0" class="item__gallery">
            <dx-gallery #gallery
                        id="gallery"
                        [dataSource]="bookmark.imageSource"
                        [width]="294"
                        [height]="220"
                        [loop]="true"
                        [showNavButtons]="bookmark.imageSource?.length>1"
                        [showIndicator]="bookmark.imageSource?.length>1"></dx-gallery>
          </div>
          <div class="item__text" [innerHTML]="bookmark.htmlText"></div>
        </div>
      </dx-accordion>
    </dx-scroll-view>
  </div>


