import { Component, OnInit } from '@angular/core';
import { locale, loadMessages } from 'devextreme/localization';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor() {
    locale('ua');
  }

  ngOnInit() {
    loadMessages({
      ua: {
        "Yes": "Так",
        "No": "Ні",
        "dxList-nextButtonText": "Більше",
        "Select": "Вибрати...",
        "dxCollectionWidget-noDataText": "Нема даних",
        //"dxFilterBuilder-and": "And",
        //"dxFilterBuilder-or": "Or",
        //"dxFilterBuilder-notAnd": "Not And",
        //"dxFilterBuilder-notOr": "Not Or",        
        "dxFilterBuilder-addCondition": "Додати умову",
        "dxFilterBuilder-addGroup": "Додати групу умов",
        "dxFilterBuilder-enterValueText": "<введіть значення>",
        "dxFilterBuilder-filterOperationEquals": "дорівнює",
        "dxFilterBuilder-filterOperationNotEquals": "не дорівнює",
        "dxFilterBuilder-filterOperationLess": "менше",
        "dxFilterBuilder-filterOperationLessOrEquals": "менше або дорівнює",
        "dxFilterBuilder-filterOperationGreater": "більше",
        "dxFilterBuilder-filterOperationGreaterOrEquals": "більше або дорівнює",
        "dxFilterBuilder-filterOperationStartsWith": "починається з",
        "dxFilterBuilder-filterOperationContains": "містить",
        "dxFilterBuilder-filterOperationNotContains": "не містить",
        "dxFilterBuilder-filterOperationEndsWith": "закінчується на",
        "dxFilterBuilder-filterOperationIsBlank": "порожнє",
        "dxFilterBuilder-filterOperationIsNotBlank": "не порожнє",
        "dxFilterBuilder-filterOperationBetween": "між значеннями",
        "dxFilterBuilder-filterOperationAnyOf": "будь-яке з",
        "dxFilterBuilder-filterOperationNoneOf": "не дорівнює жодному значенню",

        "dxHtmlEditor-dialogColorCaption": "Змінити колір шрифта",
        "dxHtmlEditor-dialogBackgroundCaption": "Змінити колір фону",
        "dxHtmlEditor-normalText": "Звичайний текст",
        "dxHtmlEditor-background": "Колір фону",
        "dxHtmlEditor-bold": "Bold",
        "dxHtmlEditor-color": "Колір тексту",
        "dxHtmlEditor-font": "Шрифт",
        "dxHtmlEditor-italic": "Italic",
        "dxHtmlEditor-undo": "Відмінити",
        "dxHtmlEditor-redo": "Повернути",
      }
    });
  }
}


