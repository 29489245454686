
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointFactory } from '../shared/endpoint-factory.service';

@Injectable()
export class LayersEndpoint extends EndpointFactory {

  private readonly _getPublicLayerServiceUrl: string = "/api/layers/publiclayers";
  private readonly _getListLayerServiceUrl: string = "/api/layers/regions";
  private readonly _getLayerDataUrl: string = "/api/layerdata/item";
  
  get getPublicLayerServiceUrl() { return this.configService.getApiURI() + this._getPublicLayerServiceUrl; }
  get getListLayerServiceUrl() { return this.configService.getApiURI() + this._getListLayerServiceUrl; }
  get getLayerDataUrl() { return this.configService.getApiURI() + this._getLayerDataUrl; }

  getPublicLayersEndpoint(): Observable<HttpResponse<any>> {

    let endpointUrl = `${this.getPublicLayerServiceUrl}`;

    return this.http.get<any>(endpointUrl, this.getAuthHeader()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getPublicLayersEndpoint());
      }),);
  }

  getLayersByRegionIds(regionIds: string[]): Observable<Response> {

    let endpointUrl = `${this.getListLayerServiceUrl}/${regionIds}`;

    var params = new HttpParams();

    for (var i = 0; i < regionIds.length; i++) {
      params.append("regionIds", regionIds[i]);
    }

    return this.http.get<any>(endpointUrl, this.getAuthHeader(false, params)).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getLayersByRegionIds(regionIds));
      }),);
  }

  //get layerdata
  getLayerDataEndpoint(layerDataId: string): Observable<Response> {

    let endpointUrl = `${this.getLayerDataUrl}/${layerDataId}`;

    return this.http.get<any>(endpointUrl, this.getAuthHeader()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getLayerDataEndpoint(layerDataId));
      }),);
  }


}
