<div class="panel-header">
  <a (click)="goBack()" class="btn-icon">
    <span class="icon-arrow-left"></span>
  </a>
  <div class="dx-field dx-field--title">
    <dx-validation-group #validationFilterData>
      <dx-text-box [(value)]="filterEdit.name" placeholder="Назва фільтру" height="48">
        <dx-validator>
          <dxi-validation-rule type="required" message="Назва фільтру обов'язкова"></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </dx-validation-group>
  </div>
</div>
<div class="filters-add">
  <div class="filters-add__map">
    <a class="btn-icon" id="createFilterID" (click)="createFilterExtent()" title="Додати фільтр на мапі">
      <span class="icon-map-edit"></span>
    </a>
    <dx-popover target="#createFilterID"
                position="bottom"
                [width]="144"
                [(visible)]="withShadingOptionsVisible">
      <div *dxTemplate="let data of 'content'">
        <button class="btn-icon btn-icon--popover" id="pointButton" (click)="drawPoint()" type="button" title="Маркер">
          <span class="esri-icon-blank-map-pin"></span>
        </button>
        <button class="btn-icon btn-icon--popover" id="polylineButton" type="button" (click)="drawPolyline()" title="Лінія">
          <span class="esri-icon-polyline"></span>
        </button>
        <button class="btn-icon btn-icon--popover" id="polygonButton" type="button" (click)="drawPolygon()" title="Полігон">
          <span class="esri-icon-polygon"></span>
        </button>
      </div>
    </dx-popover>
  </div>
  <div class="filters-add__select">
    <dx-select-box #listBox [dataSource]="layerDataSource"
                   displayExpr="name"
                   valueExpr="id"
                   placeholder="Додати шар до фільтру"
                   (onValueChanged)="onValueChangedLayer($event)"
                   noDataText="Немає даних">
    </dx-select-box>
  </div>
</div>
<div class="filters-list">
  <dx-scroll-view direction="vertical">
    <div *ngFor="let layer of filterEdit.layers" class="filters-section">
      <a (click)="removeLayerFilter(layer)" class="btn-icon btn-icon--esri">
        <span class="esri-icon-close"></span>
      </a>
      <span class="filters-section__title"> {{layer.name}}</span>
     
      <dx-filter-builder #filterbuilder [fields]="layer.fields"
                         [disabled]="layer.fields.length==0"
                         [(value)]="layer.textFilterData"
                         [groupOperations]="groupOperations"
                         [groupOperationDescriptions]="groupOperationDescriptions"
                         [customOperations]="customOperations"
                         (onInitialized)="updateTexts($event, layer.id)"
                         (onValueChanged)="onValueChanged($event, layer)"
                         (onOptionChanged)="onOptionChanged($event, layer)"
                         (onEditorPreparing)="onEditorPreparing($event)"
                         maxGroupLevel="1">
        <dx-tag-box *dxTemplate="let condition of 'tagBoxTemplate'"
                    [value]="condition.value"
                    [dataSource]="datasourceDomains[layer.layerDataID][condition.field.dataField]"
                    displayExpr="name"
                    valueExpr="id"
                    (onValueChanged)="tagBoxChanged($event, condition)"
                    [searchEnabled]="true"
                    [searchExpr]="['name']"
                    >
        </dx-tag-box>
        <dx-select-box *dxTemplate="let condition of 'listTemplate'"
                       [dataSource]="datasourceTst['adminpodil']['21']"
                       displayExpr="name"
                       valueExpr="id"
                       [value]="condition.value"
                       placeholder="Вибрати..."
                       (onValueChanged)="listBoxChanged($event, condition)"
                       noDataText="Немає даних">
        </dx-select-box>
        <dx-tag-box *dxTemplate="let condition of 'uniqueValuesTemplate'"
                    [value]="condition.value"
                    [dataSource]="datasourceTst[layer.layerDataID][condition.field.dataField]" displayExpr="name" valueExpr="id"
                    (onValueChanged)="tagBoxChanged($event, condition)"
                    [searchEnabled]="true"
                    [searchExpr]="['name']"
                    >
        </dx-tag-box>
      </dx-filter-builder>
      
    </div>
    <div *ngFor="let extentFilter of filterEdit.extents" class="filters-section">
      <a (click)="removeExtentFilter(extentFilter)" class="btn-icon btn-icon--esri">
        <span class="esri-icon-close"></span>
      </a>
      <span class="filters-section__title"> {{extentFilter.filterExtentType}} </span>
    </div>
  </dx-scroll-view>
</div>
<div class="filters-crossing">
  <button class="btn-icon" id="createFilter" (click)="createFilterCross()" type="button" title="Створити фільтр з яким будуть пересікатись вибрані шари">
    <span class="icon-filter-move"></span>
  </button>
</div>
<div class="filters-btn">
  <div>
    <dx-button id="applyBtn"
               width="100%"
               text="Застосувати"
               type="default"
               (onClick)="apply()">
    </dx-button>
  </div>
  <div *ngIf="!filterEdit.isSelected">
    <dx-button id="saveBtn"
               width="100%"
               text="Зберегти"
               type="default"
               (onClick)="save()">
    </dx-button>
  </div>
  <div *ngIf="filterEdit.isSelected">
    <dx-button id="unselectBtn"
               width="100%"
               text="Вимкнути фільтр"
               type="default"
               (onClick)="unselectFilter()">
    </dx-button>
  </div>
</div>
