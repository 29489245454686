
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointFactory } from '../shared/endpoint-factory.service';

@Injectable()
export class CustomLayerEndpoint extends EndpointFactory {

  private readonly _getUserLayerSaveServiceUrl: string = "/api/userlayer/savedata";
  private readonly _getListUserLayerServiceUrl: string = "/api/userlayer/userlayer";


  get getUserLayerSaveServiceUrl() { return this.configService.getApiURI() + this._getUserLayerSaveServiceUrl; }
  get getListUserLayerServiceUrl() { return this.configService.getApiURI() + this._getListUserLayerServiceUrl; }



  private readonly _userRegionUrl: string = "/api/userregion/userregion";

  get userRegionUrl() { return this.configService.getApiURI() + this._userRegionUrl; }


  getSaveDataServiceEndpoint(userLayerObject: any): Observable<HttpResponse<any>> {

    return this.http.post<any>(this.getUserLayerSaveServiceUrl, JSON.stringify(userLayerObject), this.getAuthHeader(true)).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getSaveDataServiceEndpoint(userLayerObject));

      }),);
  }

  getCustomLayerDataEndpoint(regionId: string): Observable<Response> {

    let endpointUrl = `${this.getListUserLayerServiceUrl}/${regionId}`;

    return this.http.get<any>(endpointUrl, this.getAuthHeader()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getCustomLayerDataEndpoint(regionId));
      }),);
  }


  getUserRegionsEndpoint(userId?: string): Observable<Response> {
    let endpointUrl = `${this.userRegionUrl}/${userId}`;

    return this.http.get<any>(endpointUrl, this.getAuthHeader()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getUserRegionsEndpoint(userId));
      }),);
  }

}
