
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { SharedService } from "../shared/shared.service";
import { Filter } from '../../models/filters/filter.model';
import { MapLayersEndpoint } from '../map/endpoint-map.layers.service';
import { LayerDataWithAttr } from '../../models/layers/layer.data.model';
import { FilterEndpointService } from './filters-endpoint.service';
import { MapLayersService } from '../map/map.layers.service';
import { Layer } from '../../models/layers/layer.model';
import { FilterLayer } from '../../models/filters/filter.layer.model';
import { ConfigService } from '../shared/utils/config.service';
import { FeatureLayerService } from '../map/feature-layer.service';
import { isFlashType } from '../../models/layers/legend.model';
import { layer } from 'esri/views/3d/support/LayerPerformanceInfo';
import { UserBookmark } from '../../models/bookmarks/bookmark.model';

@Injectable()
export class FiltersService {

  constructor(private sharedService: SharedService, private mapLayersEndpoint: MapLayersEndpoint,
    private filterEndpoint: FilterEndpointService, private mapLayersService: MapLayersService,
    private featureLayerService: FeatureLayerService,
    private configService: ConfigService) {
    
  }

  getAllFilters(regionId, userId: string): Promise<Filter[]>
  {
    return this.filterEndpoint.getFiltersEndpoint(regionId, userId).then(result => {
      return <Filter[]>result;
    })
  }

  getLayers(regionID) {
    return this.mapLayersEndpoint.getLayerDataByRegionEndpoint(regionID).then(result => {
      let layers = <LayerDataWithAttr[]>result

      return layers;

    })
  }

  addFilter(filter: Filter): Observable<Filter> {
    filter.layers.forEach(fl => {
      fl.textFilter = JSON.stringify(fl.textFilterData);
    })
    return this.filterEndpoint.getAddFilterEndpoint(filter).pipe(map((result: any) => {
      return <Filter>result;
    }))
  }

  updateFilter(filter: Filter): Observable<Filter> {    
    filter.layers.forEach(fl => {
      fl.textFilter = JSON.stringify(fl.textFilterData);
      fl.textFilterData = null;
    })
    return this.filterEndpoint.getUpdateFilterEndpoint(filter).pipe(map((result: any) => {
      return <Filter>result;
    }))
  }

  getFilterByID(id): Promise<Filter> {

    return this.filterEndpoint.getFilterByIdEndpoint(id).then(result => {
      let filter = <Filter>result;
      filter.layers.forEach(fl => {
        if (fl.textFilter) {
          fl.textFilterData = JSON.parse(fl.textFilter);
        }
        
      })
      return filter;
    })
  }

  removeFilter(id) {
    return this.filterEndpoint.getDeleteFilterEndpoint(id).pipe(map((result: any) => {
      return <Filter>result;
    }))
  }

  selectFilter(id, bookamrk? : UserBookmark) {
    this.getFilterByID(id).then(async result => {
      let filter = result;
      let arrayPromisse: Promise<any>[] = [];


      let prepareList = this.mapLayersService.prepareLayerList();
      let list: any[] = [];
      filter.layers.forEach(f => {
        let p = prepareList.find(l => l.id == f.layerID);
        if (p) {

        } else {
          list.push(f);
        }        
      })

      list.forEach(f => {
        let index = filter.layers.findIndex(i => i.id == f.id);
        if (index >= 0) {
          filter.layers.splice(index, 1);
        }
      })
      

      let urls: any[] = filter.layers.map((l: FilterLayer) => {
        
        let _url = l.serviceName + "/" + l.layerName;
        let layerUrl = {
          layerID : l.layerID,
          url: _url,
          layerDataID: l.layerDataID
        }
        return layerUrl;
      })

      urls = urls.filter((v, i) => { return urls.indexOf(v) == i });

      urls.forEach(async url => {
        let _promisse: Promise<any> = this.getLayerInfo(url);
        arrayPromisse.push(_promisse);
      })

      let temp = await Promise.all(arrayPromisse).then(result => {

        result.forEach(layerFilterInfo => {
          // let layerFilter = filter.layers.find(fl => fl.layerID == layerFilterInfo.layerID);
          let layerFilter = filter.layers.find(fl => fl.layerDataID == layerFilterInfo.layerDataID);
          // layerDataID
          if (layerFilter) {
            layerFilter.subtypeFiledNameInfo = layerFilterInfo.subtypeFiledName;
            layerFilter.subtypesInfo = layerFilterInfo.subtypes;
            layerFilter.fields = layerFilterInfo.fields;
          }
        })

      }).catch(function (error) {
        console.log("informative error message: ", error.message);
      });

      filter.layers.forEach(filterLayer => {
        let _filter = filterLayer.textFilter ? JSON.parse(filterLayer.textFilter) : null;
        if (_filter) {
          let expression = this.parseExpression(_filter, filterLayer);
          filterLayer.layerFilter = expression;
        }
      });

      let layers = this.sharedService.getLayerList().getValue();

      layers.forEach(_layer => {
        _layer.isSelected = filter.layers.find(x => x.layerID == _layer.id) != null;
        _layer.inFilter = _layer.isSelected;
        this.selectChildren(_layer.layerChildren, filter);

      })

      if (bookamrk) {
        
      } else {
        layers.forEach(l => {
          this.mapLayersService.HideLayerDataByLayerIDEx(l.id);
          this.hideAllLayers(l.layerChildren);
        });
      }   

      filter.layers.forEach(x => {
        this.parentLayerID(x.layerID);
      })

      //this.mapLayersService.showFilter(filter);
      this.featureLayerService.showFeatureByFilter(filter);
      this.sharedService.setCurrentFilter(filter);
    });
  }

  async prepareFilter(id, inBookmark) {

    return this.getFilterByID(id).then(async result => {
      let filter = result;
      let arrayPromisse: Promise<any>[] = [];


      let prepareList = this.mapLayersService.prepareLayerList();
      let list: any[] = [];
      filter.layers.forEach(f => {
        let p = prepareList.find(l => l.id == f.layerID);
        if (p) {

        } else {
          list.push(f);
        }
      })

      list.forEach(f => {
        let index = filter.layers.findIndex(i => i.id == f.id);
        if (index >= 0) {
          filter.layers.splice(index, 1);
        }
      })


      let urls: any[] = filter.layers.map((l: FilterLayer) => {

        let _url = l.serviceName + "/" + l.layerName;
        let layerUrl = {
          layerID: l.layerID,
          url: _url,
          layerDataID: l.layerDataID
        }
        return layerUrl;
      })

      urls = urls.filter((v, i) => { return urls.indexOf(v) == i });

      urls.forEach(async url => {
        let _promisse: Promise<any> = this.getLayerInfo(url);
        arrayPromisse.push(_promisse);
      })

      let temp = await Promise.all(arrayPromisse).then(result => {

        result.forEach(layerFilterInfo => {
          // let layerFilter = filter.layers.find(fl => fl.layerID == layerFilterInfo.layerID);
          let layerFilter = filter.layers.find(fl => fl.layerDataID == layerFilterInfo.layerDataID);
          // layerDataID
          if (layerFilter) {
            layerFilter.subtypeFiledNameInfo = layerFilterInfo.subtypeFiledName;
            layerFilter.subtypesInfo = layerFilterInfo.subtypes;
            layerFilter.fields = layerFilterInfo.fields;
          }
          
        })

      }).catch(function (error) {
        console.log("informative error message: ", error.message);
      });

      filter.layers.forEach(filterLayer => {
        let _filter = filterLayer.textFilter ? JSON.parse(filterLayer.textFilter) : null;
        if (_filter) {
          let expression = this.parseExpression(_filter, filterLayer);
          filterLayer.layerFilter = expression;
        }
      });

      let layers = this.sharedService.getLayerList().getValue();

      layers.forEach(_layer => {
        if (filter.layers.find(x => x.layerID == _layer.id)) {
          _layer.isSelected = true;
          _layer.inFilter = _layer.isSelected;
          _layer.inBookmark = inBookmark;
        }
        
        this.selectChildrenOnlyFilter(_layer.layerChildren, filter, inBookmark );

      })

      //if (bookamrk) {

      //} else {
      //  layers.forEach(l => {
      //    this.mapLayersService.HideLayerDataByLayerIDEx(l.id);
      //    this.hideAllLayers(l.layerChildren);
      //  });
      //}

      filter.layers.forEach(x => {
        this.parentLayerID(x.layerID);
      })
      this.sharedService.setCurrentFilter(filter);
      return filter;
    });
  }

  private parseExpression(expr, filterLayer: FilterLayer) {
    let result = '';

    if (expr && Array.isArray(expr)) {

      if (Array.isArray(expr[0])) {
        for (let i = 0; i < expr.length; i++) {
          result += this.parseExpression(expr[i], filterLayer);
        }
        result = `(${result})`;
      } else if (Array.isArray(expr) && expr.length > 1) {
        let attr = expr[0];
        let condition = expr[1];
        let value = expr[2];
        let field = filterLayer.fields.find(f => f.dataField == attr);
        let fieldType = field && field.dataType ? field.dataType : null;
        let subtypesInfo = filterLayer.subtypesInfo;
        result += attr + this.parseCondition(condition, value, filterLayer, field, subtypesInfo);
        if (!value && fieldType=='string') {
          let exResupt = attr + this.parseCondition(condition, '', filterLayer, field, subtypesInfo);
          result = '(' + result + ' or ' + exResupt + ')';
        }
              
      }
    } else {
      result += ` ${expr} `;
    }
    return result;
  }

  private parseCondition(condition, value, filterLayer: FilterLayer, field, subtypesInfo) {
    let result = "";
    //  "=", "<>", ">", ">=", "<", "<=", "startswith", "endswith", "contains", "notcontains", "between", "isblank", "isnotblank"
    // between 
    switch (condition) {
      case "contains":
        result = ` like N'%${value.replace("'", "''")}%'`;
        break;
      case "startswith":
        result = ` like N'${value.replace("'", "''")}%'`;
        break;
      case "endswith":
        result = ` like N'%${value.replace("'", "''")}'`;
        break;
      case "notcontains":
        result = ` not like N'%${value.replace("'", "''")}%'`;
        break;
      case "between":
        result = ` between ${value[0]} and ${value[1]} `;
        break;
      case "=":
      case "someof":
        if (value == null) {
          result = ' is null';
        }
        else if (field?.dataType && field?.dataType == 'number') {
          result = ` = ${value}`;
        } 
        else {
          result = ` = N'${value.replace("'", "''")}'`;
        }
        
        break;
      case "<>":
        if (value == null) {
          result = ' is not null';
        }
        else if (field?.dataType && field?.dataType == 'number') {
          result = ` <> ${value}`;
        }
        else {
          result = ` <> N'${value.replace("'", "''")}'`;
        }
        break;
      case ">":
        result = ` > ${value}`;
        break;
      case ">=":
        result = ` >= ${value}`;
        break;
      case "<":
        result = ` < ${value}`;
        break;
      case "<=":
        result = ` <= ${value}`;
        break;
      case "anyof":        
        let ids = '';
        value.forEach(x => {
          if (field.dataField == filterLayer.subtypeFiledNameInfo && subtypesInfo?.length>0) {
            let _subtype = subtypesInfo?.find(s => s.id == x);
            ids += _subtype.id + ',';
            if (filterLayer.subtypesFilterSelected) {

            } else {
              filterLayer.subtypesFilterSelected = [];
            }
            filterLayer.subtypesFilterSelected.push({ id: _subtype.id, name: _subtype.name })
          } else if (field.domain) {
            let item = field.domain.codedValues.find(f => f.code == x);
            if (field?.dataType == 'number') {
              ids += `${item.code},`;
            } else {
              ids += `N'${item.code.replace("'", "''")}',`;
            }
            if (field.dataField == filterLayer.subtypeFiledNameInfo) {
              if (filterLayer.subtypesFilterSelected) {

              } else {
                filterLayer.subtypesFilterSelected = [];
              }
              filterLayer.subtypesFilterSelected.push({ id: item.code, name: item.name })
            }            
          }
        })
        ids = ids.slice(0, ids.length - 1);
        result = ` in (${ids})`;
        break;
      case "anyofunq":
        let unqIds = '';
        value.forEach(v => {
          if (field?.dataType == 'number') {
            unqIds += `${v}` + ',';
          } else {
            unqIds += `N'${v.replace("'", "''")}'` + ',';
          }
          
        });
        unqIds = unqIds.slice(0, unqIds.length - 1);

        result = ` in (${unqIds})`;
        break;
      case "blank":
        result = " is null";
        break;
      default:
        result = '';
        break;

    }

    return result;
  }

  unselectFilter(filter?: Filter) {
    let layers = this.sharedService.getLayerList().getValue();

    this.unselectChildren(layers);

    layers.forEach(result => {
      this.hideLayers(result.layerChildren);
      if (!result.isSelected) {
        this.mapLayersService.HideLayerDataByLayerIDEx(result.id);
      }
    });
    this.sharedService.setCurrentFilter(null);
  }

  private unselectChildren(layers: Layer[]) {
    layers.forEach(_layer => {
      _layer.isSelected = false;
      _layer.inFilter = false;
      this.unselectChildren(_layer.layerChildren);
    })
  }

  private selectChildren(layers: Layer[], filter: Filter) {
    layers.forEach(_layer => {
      _layer.isSelected = filter.layers.find(x => x.layerID == _layer.id) != null;
      _layer.inFilter = _layer.isSelected;
      this.selectChildren(_layer.layerChildren, filter);
    })

  }

  private selectChildrenOnlyFilter(layers: Layer[], filter: Filter, inBookmark: boolean) {
    layers.forEach(_layer => {
      if (filter.layers.find(x => x.layerID == _layer.id)) {
        _layer.isSelected = true;
        _layer.inFilter = _layer.isSelected;
        _layer.inBookmark = inBookmark;
      }
      
      this.selectChildrenOnlyFilter(_layer.layerChildren, filter, inBookmark);
    })

  }



  private hideLayers(layers: Layer[]) {
    layers.forEach(_layer => {
      this.hideLayers(_layer.layerChildren);
      if (!_layer.isSelected)
        this.mapLayersService.HideLayerDataByLayerIDEx(_layer.id);

    })
  }

  private hideAllLayers(layers: Layer[]) {
    layers.forEach(_layer => {
      this.hideLayers(_layer.layerChildren);
      this.mapLayersService.HideLayerDataByLayerIDEx(_layer.id);

    })
  }

  private parentLayerID(layerId: string): string {
    let layerList = this.sharedService.getLayerList().getValue();
    let result: string = layerId;

    for (var layer of layerList) {
      if (layer.id == layerId) {
        result = layer.id;
        break;
      }

      if (this.getLayerParent(layer, layerId)) {
        result = layer.id;
        //this.sharedService.clearLayers();
        //let tmp: Layer[] = new Array<Layer>();
        //Object.assign(tmp, layerList);
        //this.sharedService.setLayers(tmp);
        this.sharedService.setLayers(layerList);
        break;
      }
    };



    return result;
  }

  private getLayerParent(layer: Layer, layerId: string): boolean {
    let result = false;
    for (var children of layer.layerChildren) {
      if (children.id == layerId) {
        layer.isSelected = true;
        result = true;
        break;
      } else {
        if (this.getLayerParent(children, layerId)) {
          layer.isSelected = true;
          result = true;
          break;
        }
      }

    }
    return result;
  }

  getLayerInfo(layerUrl): Promise<any> {

    return this.mapLayersService.getLayerInfo(this.configService._baseUrlRegionServices + layerUrl.url).then(layerInfo => {
      let filterLayerInfo = {
        layerID: layerUrl.layerID,
        subtypes: null,
        subtypeFiledName: null,
        fields: null,
        layerDataID: layerUrl.layerDataID
      }
      if (layerInfo.subtypes) {
        filterLayerInfo.subtypes = layerInfo.subtypes.map(m => {
          return { id: m.code, name: m.name }
        })
        filterLayerInfo.subtypeFiledName = layerInfo.subtypeField;
      } else {
        filterLayerInfo.subtypeFiledName = layerInfo.typeIdField;
      }
      let fields = layerInfo.fields?.map(field => {
        switch (field.type) {
          case "esriFieldTypeSmallInteger":
          case "esriFieldTypeInteger":
          case "esriFieldTypeSingle":
          case "esriFieldTypeDouble":
            return {
              dataField: field.name,
              caption: field.alias,
              domain: field.domain, 
              dataType: "number"
            };
          default:
            return {
              dataField: field.name,
              caption: field.alias,
              domain: field.domain,
              dataType : "string"
            };
        }
      })
      filterLayerInfo.fields = fields;


      return filterLayerInfo;
    })
  }

  async showFeatureByFilter(filter: Filter) {

    let arrayPromisse: Promise<any>[] = [];

    let urls: any[] = filter.layers.map((l: FilterLayer) => {
      let _url = l.serviceName + "/" + l.layerName;
      let layerUrl = {
        layerID: l.layerID,
        url: _url,
        layerDataID: l.layerDataID

      }
      return layerUrl;
    })

    urls = urls.filter((v, i) => { return urls.indexOf(v) == i });

    urls.forEach(async url => {
      let _promisse: Promise<any> = this.getLayerInfo(url);
      arrayPromisse.push(_promisse);
    })

    let temp = await Promise.all(arrayPromisse).then(result => {

      result.forEach(layerFilterInfo => {
        //let layerFilter = filter.layers.find(fl => fl.layerID == layerFilterInfo.layerID);
        let layerFilter = filter.layers.find(fl => fl.layerDataID == layerFilterInfo.layerDataID);
        if (layerFilter) {
          layerFilter.subtypeFiledNameInfo = layerFilterInfo.subtypeFiledName;
          layerFilter.subtypesInfo = layerFilterInfo.subtypes;
          layerFilter.fields = layerFilterInfo.fields;
        }
        
      })

    }).catch(function (error) {
      console.log("informative error message: ", error.message);
    });

    filter.layers.forEach(filterLayer => {
      let _filter = filterLayer.textFilter ? JSON.parse(filterLayer.textFilter) : null;
      if (_filter) {
        let expression = this.parseExpression(_filter, filterLayer);
        filterLayer.layerFilter = expression;
      }
    });
    this.featureLayerService.showFeatureByFilter(filter)
  }
}
