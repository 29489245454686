import { Injectable } from '@angular/core'; 
import { ConfigService } from "../shared/utils/config.service";
import { BlobServiceClient, StorageSharedKeyCredential, ContainerClient, ContainerCreateResponse } from "@azure/storage-blob";
import { resolveAny } from 'dns';
import * as FileSaver from 'file-saver';
import { log } from 'console';
import { AzureStorageEndpointService } from './azure-storage-endpoint.service';

@Injectable() 
export class AzureStorageService {


  private blobServiceClient: BlobServiceClient;  

  constructor(private configService: ConfigService, private azureStorageEndpointService: AzureStorageEndpointService) {

    this.getSASToken();
  }

  getSASToken() {
    if (!this.blobServiceClient) {
      return this
        .azureStorageEndpointService.getSASToken().then(result => {

          this.blobServiceClient
            = new BlobServiceClient(
              this.configService.AzureStorageSASUrl + result
            );

        }).catch(e => {
          console.log(e);
        })
    }
     
  }

  async uploadFile(containerName, blobName, content) {
    const containerClient: ContainerClient = this.blobServiceClient.getContainerClient(containerName);
    let isExists = await containerClient.exists();
    if (!isExists) {
      let response: ContainerCreateResponse = await containerClient.create();

    }
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.upload(content, content.size);
  }

  async listBlobsByContainer(containerName) {
    const containerClient: ContainerClient = await this.blobServiceClient.getContainerClient(containerName);
    
    let documentsList = [];
    let isExists = await containerClient.exists();
    if (!isExists) {
      return documentsList;
    } else {
      let blobs =   containerClient.listBlobsFlat();
      let i = 0;
      for await (const blob of blobs) {
        documentsList.push(blob.name);
      }
    }
    return documentsList;
  }

  async listBlobsUrlsByContainer(containerName) {
    const containerClient: ContainerClient = await this.blobServiceClient.getContainerClient(containerName);

    let documentsList = [];
    let isExists = await containerClient.exists();
    if (!isExists) {
      return documentsList;
    } else {
      let blobs = containerClient.listBlobsFlat();
      let i = 0;
      for await (const blob of blobs) {
        let blobFile = await containerClient.getBlobClient(blob.name);
        
        documentsList.push(blobFile.url);
      }
    }
    return documentsList;
  }

  async getBlob(containerName, blobName) {
    const containerClient = this.blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);

    let body = await blobClient.download();
    FileSaver.saveAs(await body.blobBody, blobName);    
    return;
  }

  async getBlobOnly(containerName, blobName) {
    const containerClient = this.blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);
    
    let body = await blobClient.download();
    
    return await body.blobBody;
  }

  async getUrlBase64(containerName, blobName) {
    const containerClient = this.blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);

    let body = await blobClient.download();
    let blob = await body.blobBody;

    var base64String = await this.blobToBase64(blob);
    return base64String;
  }

  async blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async getBlobUrl(containerName, blobName) {
    const containerClient = this.blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);
    return blobClient.url;
  }

  async deleteBlob(containerName, blobName) {
    const containerClient = this.blobServiceClient.getContainerClient(containerName);
    const blobClient = await containerClient.deleteBlob(blobName);

    return;
  }

  async deleteContainer(containerName) {
    const containerClient = this.blobServiceClient.getContainerClient(containerName);
    let response = await containerClient.deleteIfExists();
    if (response.succeeded) {
      console.debug("contaner " + containerName + " deleted");
    }
  }

  async ExistAnyBlob(containerName) {
    let result = false;
    try {
      const containerClient: ContainerClient = await this.blobServiceClient.getContainerClient(containerName);

      let documentsList = [];
      let isExists = await containerClient.exists();
      if (!isExists) {
        return result;
      } else {
        let blobs = containerClient.listBlobsFlat();
        for await (const blob of blobs) {
          result = true;
          break;
        }
      }
    } catch (e) {
      console.debug("contaner " + containerName + " : " + e);
    }    
    return result;
  }

} 
