
export class FilterExtent {
  constructor(
    id?: string,
    filterID?: string,
    extent?: any,
    filterExtentType?: FilterExtentType,
    regionID?: string,
    ) {

    this.id = id;
    this.filterID = filterID;
    this.regionID = regionID;
    this.filterExtentType = filterExtentType;
    this.extent = extent;
  }

  public id: string;
  public regionID: string;
  public filterID: string;
  public extent: any;
  public filterExtentType: FilterExtentType
  public name: string;
}

const FilterExtentType = {
  point: 'point' as 'Маркер',
  polyline: 'line' as 'Лінія',
  polygon: 'polygon' as 'Полігон'
}
type FilterExtentType = (typeof FilterExtentType)[keyof typeof FilterExtentType];
export { FilterExtentType };
