import { Component, OnInit, ViewChild } from '@angular/core';
import { LocationsService } from "../../services/locations/locations.service";
import notify from 'devextreme/ui/notify';
import { UserRegions } from "../../models/userregions/user-regions.model";
import { UserService } from "../../services/user/user.service";
import { LayersService } from "../../services/layers/layers.service";
import { SharedService } from "../../services/shared/shared.service";
import { MapLayersService } from "../../services/map/map.layers.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  DxDropDownBoxComponent
} from 'devextreme-angular';
import { HomebtnService } from "../../services/homebtn/homebtn.service";
import { SketchViewService } from "../../services/customlayer/sketch-view.service";
import { SelectChildrenType, Layer } from '../../models/layers/layer.model';
import { MapLayersEndpoint } from '../../services/map/endpoint-map.layers.service';
import { LayerDataWithAttr } from '../../models/layers/layer.data.model';
import { SearchService } from '../../services/search/search.service';
import { FiltersService } from '../../services/filters/filters.service';
import { FeatureLayerService } from '../../services/map/feature-layer.service';
import { layer } from 'esri/views/3d/support/LayerPerformanceInfo';
import { BookmarksService } from '../../services/bookmarks/bookmarks.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
 
  userRegions: UserRegions[];
  userRegionIds: any[] = [];
  @ViewChild(DxDropDownBoxComponent) listBox;
  private _initSearch = new BehaviorSubject<boolean>(false);

  constructor(
    private locationsService: LocationsService,
    private userService: UserService,
    private layersService: LayersService,
    private sharedService: SharedService,
    private mapLayersService: MapLayersService,
    private homeButtonService: HomebtnService,
    private sketchViewService: SketchViewService,
    private searchService: SearchService,
    private filtersService: FiltersService,
    private featureLayerService: FeatureLayerService,
    private bookmarksService: BookmarksService,
    //, private mapLayersEndpoint: MapLayersEndpoint
  )
    {
     
  }

  ngOnInit() {
    this.getUserRegions(this.userService.User.id).then(result => {      
      this.searchService.InitSearch(result.regionID).then(i => {
        this._initSearch.next(true);
      })
      this.layersService.getLayersByRegionIds([result.regionID])
        .subscribe((results) => {
          results.forEach(l => { this.initLayers(l); });                   
          ////results.forEach(l => { l.selectChildrenType = SelectChildrenType.none });
          //this.sharedService.setLayers(results);
          let subscription = this._initSearch.asObservable().subscribe(res => {
            if (res) {
              results.forEach(l => { this.disableEmptyLayers(l) });
              this.sharedService.setLayers(results);

              results.filter(f => f.isSelected).forEach(result => {                
                this.featureLayerService.showFeatureServer(result.id);
              });
              subscription?.unsubscribe();
              this._initSearch.next(false);
            }
          })

       }, (error) => {
         notify(`Під час загрузки шарів регіонів сталася помилка.\r\n Помилка: "${error._body}"`, "error", 3000);
        });
    })
  }

  getUserRegions(userId: string): Promise<UserRegions> {
     return this.locationsService.getUserRegions(userId).pipe(
      map((results) => {

        this.userRegions = results.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          if (a.name = b.name) return 0;
        });

        //select all regions
        if (results.length > 0) {
          this.userRegionIds = [results[0].regionID];
          this.sharedService.CurrentRegionCode = results[0].code;
          this.sharedService.setRegionID(this.userRegionIds[0]);
          this.sharedService.RegionAmountFiles = results[0].amountFiles;
          this.sharedService.RegionSizeFile = results[0].sizeFile;
          this.sharedService.RegionSizeStorage = results[0].sizeStorage;
          
          this.sharedService.mapView.when(x => {
            
            this.GoTo(results[0]);
          })
        }

        return results[0];
      }, (error) => {
        notify(`Під час загрузки регіонів сталася помилка.\r\n Помилка: "${error._body}"`, "error", 3000);
        return null;
      }

       )
     ).toPromise()
      
      
    //  .toPromise();

      
  }
  

  onSelectionChanged(e) {
    let _addedItem = e.addedItems;
    let _removedItem: any[] = e.removedItems;
    let _ids: any[] = _addedItem.map(x => x.regionID);
    let layers = this.sharedService.getLayerList().getValue();
    this.sketchViewService.removeGraphics();
    this.sharedService.setIsCheckedCustomLayer(false);    
    if (_removedItem.length > 0) {
      let layer = layers.filter(x => _removedItem.find(z => z.regionID == x.regionID));
      if (layer.length > 0)
        layer.forEach(x => {
          this.mapLayersService.HideLayerDataByLayerIDEx(x.id);
          layers.splice(layers.indexOf(x), 1);          
        })
        
      this.sharedService.setLayers(layers);
    }

    if (_addedItem.length > 0) {

      
      //this.sharedService.setUserBookmarkList([]);
      this.sharedService.setUserBookmarksInit(false);
      this.sharedService.CurrentRegionCode = _addedItem[0].code;
      this.sharedService.setRegionID(_ids[0]);
      this.sharedService.RegionAmountFiles = _addedItem[0].amountFiles;
      this.sharedService.RegionSizeFile = _addedItem[0].sizeFile;
      this.sharedService.RegionSizeStorage = _addedItem[0].sizeStorage;
      
      let selectedBookmark = this.sharedService.getSelectedBookmarkValue();
      if (selectedBookmark) {
        this.sharedService.setSelectedBookmark(null);
        this.bookmarksService.unselectBookmark(selectedBookmark);
      }

     
      
      if (this.sharedService.getCurrentFilterValue()) {
        this.filtersService.unselectFilter();
      }

      this.sharedService.publicLegends = [];
      //let _initSearch = new BehaviorSubject<boolean>(false);
      this._initSearch.next(false);
      this.searchService.InitSearch(_ids[0]).then(s => {
        this._initSearch.next(true);
      })
      this.layersService.getLayersByRegionIds(_ids)
        .subscribe((results) => {

          results.forEach(l => { this.initLayers(l) });
          
          layers = layers.concat(results);
          
          this.sharedService.setLayers(layers);

          let subscription = this._initSearch.asObservable().subscribe(res => {
            if (res) {
              results.forEach(l => { this.disableEmptyLayers(l) });
              this.sharedService.setLayers(results);

              results.filter(f => f.isSelected).forEach(result => {
                result.isExpanded = true;
                this.featureLayerService.showFeatureServer(result.id);
              });
              subscription?.unsubscribe();
            }           
          })
          //this.sharedService.getlayerData().subscribe(res => {

          //})
        }, (error) => {
          notify(`Під час загрузки шарів регіонів сталася помилка.\r\n Помилка: "${error._body}"`, "error", 3000);
        });
      this.GoTo(_addedItem[0]);
    }
    
    this.listBox.instance.close();
  }

  private initLayers(layer: Layer) {
    layer.selectChildrenType = SelectChildrenType.none;
    if (layer.isSelected) {
      layer.isExpanded = true;
    }
    if (layer.layerChildren && layer.layerChildren.length > 0) {
      for(var item of layer.layerChildren) {
        this.initLayers(item);
      }
    }
  }

  private disableEmptyLayers(layer: Layer) {
    if ((layer.layerChildren && layer.layerChildren.length > 0)) {
      for (var item of layer.layerChildren) {
        this.disableEmptyLayers(item);
      }
    } else {
      let layerData = this.sharedService.getlayerDataValue();
      let data = layerData.find(f => f.layerId == layer.id);

      if (data) {

      } else {
        layer.isEmpty = true;
      }
      return layer;
    }
    return layer;
  }

  //private InitSearch(regionID) {
  //  this.mapLayersEndpoint.getLayerDataByRegionEndpoint(regionID).then(result => {
  //    let layers = <LayerDataWithAttr[]>result.json();
  //    layers.forEach(x => {
  //      console.log("layer : " + x.serviceName + " - " + x.layerName)
  //    })
  //  })
  //}

  private GoTo(region: UserRegions) {
    let opts = {
      duration: 500  
    };
    let _zoom: number = region.zoom && region.zoom != 0 ? region.zoom : 15;
    if (region.latitude && region.longitude)
      this.sharedService.mapView.goTo({ center: [region.longitude, region.latitude], zoom: _zoom }, opts).then(() => {
        this.homeButtonService.reInit();
      });
    
  }
}
