<div class="map-components">
  <app-profile></app-profile>
  <app-gallerymap></app-gallerymap>
  <app-locations  *ngIf="(isLoggedIn | async)&& !showBookmark"></app-locations>
  <app-publicbookmarklist *ngIf="(isGroupBookmark | async)"></app-publicbookmarklist>
  <app-locatebtn></app-locatebtn>
  <app-homebtn></app-homebtn>
  <app-scalebar></app-scalebar>
  <app-polygon></app-polygon>
  <app-polyline></app-polyline>  
  <app-print-map></app-print-map>
</div>
<div #map id="mapNode">
  <app-overviewmap></app-overviewmap>
</div>
