import { Injectable } from '@angular/core';
import { FilterLayer } from '../../models/filters/filter.layer.model';
import { SharedService } from '../shared/shared.service';
import { FilterAttributeService } from './filter-attribute.service';

@Injectable()
export class FilterLayerHelperService {

  constructor(private filterAttributeService: FilterAttributeService, private sharedService: SharedService) {

  }

  getDefenitionExprression(filterLayer: FilterLayer) {
    if (filterLayer.layerAttributes.find(a => a.hasFilter)) {
      let groupTypeArray: any[] = [];
      let filter = '';
      filterLayer.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
        if (filter.length > 0) {
          filter = filter + ' and ';
        }
        let visibledSubtypes = [];
        if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
          
          attr.subTypeFilters.forEach(st => {
            if (filterLayer.subtypesFilterSelected) {
              let sfs = filterLayer.subtypesFilterSelected.find(f => f.id == st.subTypeID);
              if (sfs) {
                visibledSubtypes.push(st.subTypeID);
              }
            } else {
              visibledSubtypes.push(st.subTypeID);
            }

          });
          let strFilter = "";
          attr.subTypeFilters 
            .forEach(s => {
              if (strFilter.length > 0) {
                strFilter = strFilter + ','
              }
              strFilter = strFilter + s.subTypeID;
            })
          filter += attr.name + " in ( " + strFilter + ")";
        } else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
          let filterKOATUU = attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
          filter += filterKOATUU;
        }
        else if (attr.filterType == this.filterAttributeService.TypeGroup) {
          let groupFilter = "";
          attr.groupValueFilters.forEach(g => {
            if (groupFilter.length > 0) {
              groupFilter += ',';
            }
            groupFilter += `N'${g.value}'`;            
          })

          if (groupFilter.length > 0) {            
            filter += attr.name + " in ( " + groupFilter + ")";
          }
          let n = {
            name: attr.name,
            group: attr.groupValueFilters
          }
          groupTypeArray.push(n);
        }
      })
      return filter;
    }
  }

  private getFilterValue(filterType) {
    if (filterType == this.filterAttributeService.TypeKOATUU) {
      return this.sharedService.CurrentRegionCode;
    }

    return '';
  }
}
