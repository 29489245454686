
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointFactory } from '../shared/endpoint-factory.service';



@Injectable()
export class MapLayersEndpoint extends EndpointFactory {

  private readonly _getPublicLayerServiceUrl: string = "/api/layers/publiclayers";
  private readonly _getLayerDataByLayerUrl: string = "/api/layerdata/layerdata";
  private readonly _getLayerDataByLIdsUrl: string = "/api/layerdata/getlayerdata";
  private readonly _getLayerDataWithAttrByLIdsUrl: string = "/api/layerdata/listwithattribute";
  private readonly _getLayerDataWithAttrByRegion: string = "/api/layerdata/listbyregionclient";

  get getPublicLayerServiceUrl() { return this.configService.getApiURI() + this._getPublicLayerServiceUrl; }
  get getLayerDataByLayerUrl() { return this.configService.getApiURI() + this._getLayerDataByLayerUrl; }
  get getLayerDataByLIdsUrl() { return this.configService.getApiURI() + this._getLayerDataByLIdsUrl; }
  get getLayerDataWithAttrByLIdsUrl() { return this.configService.getApiURI() + this._getLayerDataWithAttrByLIdsUrl; }
  get getLayerDataWithAttrByRegion() { return this.configService.getApiURI() + this._getLayerDataWithAttrByRegion; }

  getPublicLayersEndpoint(): Observable<Response> {

    let endpointUrl = `${this.getPublicLayerServiceUrl}`;

    return this.http.get<any>(endpointUrl, this.getAuthHeader()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getPublicLayersEndpoint());
      }),);
  }

  getLayerDataByLayerEndpoint(layerId: string): Promise<any> {   
    layerId = layerId == "" ? null : layerId;
    let endpointUrl = `${this.getLayerDataByLayerUrl}/${layerId}`;

    return this.http.get(endpointUrl, this.getAuthHeader())
      .toPromise().then((response: HttpResponse<any>) => {
        return response;
      })
      .catch(error => {
        return this.handlePromiseError(error, () => this.getLayerDataByLayerEndpoint(layerId));
      });
  }

  getLayerDataByLIdsEndpoint(layerId: string[]): Promise<any> {
    var params = new HttpParams();

    for (var i = 0; i < layerId.length; i++) {
      params.append("layerIDs", layerId[i]);
    }

    let endpointUrl = `${this.getLayerDataByLIdsUrl}/${layerId}`;

    return this.http.get<any>(endpointUrl, this.getAuthHeader(false, params))
      .toPromise().then((response: Response) => {
        return response;
      })
      .catch(error => {

        return this.handlePromiseError(error, () => this.getLayerDataByLIdsEndpoint(layerId));
    });
  }

  getLayerDataWithAttrByLIdsEndpoint(layerId: string[]): Promise<any> {
    var params = new HttpParams();

    for (var i = 0; i < layerId.length; i++) {
      params.append("layerIDs", layerId[i]);
    }

    let endpointUrl = `${this.getLayerDataWithAttrByLIdsUrl}/${layerId}`;

    return this.http.get(endpointUrl, this.getAuthHeader(false, params))
      .toPromise().then((response: Response) => {
        return response;
      })
      .catch(error => {
        return this.handlePromiseError(error, () => this.getLayerDataWithAttrByLIdsEndpoint(layerId));
    });
  }

  getLayerDataByRegionEndpoint(regionId: string): Promise<any> {
    
    let endpointUrl = `${this.getLayerDataWithAttrByRegion}/${regionId}`;

    return this.http.get(endpointUrl, this.getAuthHeader())
      .toPromise().then((response: Response) => {
        return response;
      })
      .catch(error => {
        return this.handlePromiseError(error, () => this.getLayerDataByRegionEndpoint(regionId));
    });
  }
}
