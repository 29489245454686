import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";

@Injectable()
export class HomebtnService {

  constructor(private sharedService: SharedService, private esriService: EsriService) {
    
  }


  getHomeBtn() {

    if (!this.sharedService.homeButton) {
      this._createHomeButton();      
    }        
  }


  _createHomeButton() {
    this.sharedService.homeButton = new this.esriService.HomeButton({
      view: this.sharedService.mapView
    }, Constants.homebtnId);

    this.sharedService.mapView.ui.add(this.sharedService.homeButton,
      "bottom-right"
    );
    //home end
    

    //this.sharedService.homeButton.on("error",
    //  msg => {
    //    console.log("Home btn error:  ", msg);
    //  });
  }

  reInit() {
    let viewPoint = new this.esriService.ViewPoint({
      targetGeometry: this.sharedService.mapView.extent
    });
    this.sharedService.homeButton.viewModel.viewpoint = viewPoint;
  }

}

