import { FilterLayer } from "./filter.layer.model";
import { FilterExtent } from "./filter.extent.model";
import { FilterGroup } from "./filter.group.model";

export class Filter {
  constructor(
    id?: string,
    regionID?: string,
    name?: string,
    type?: FilterType,
    userID?: string
    ) {

    this.id = id;
    this.regionID = regionID;
    this.name = name;
    this.type = type;
    this.userID = userID;
    
  }

  public id: string;
  public regionID: string;
  public name: string;
  public type: FilterType;
  public userID: string;
  public isSelected: boolean = false;
  public layers: FilterLayer[] = [];
  public extents: FilterExtent[] = [];
  public groups: FilterGroup[] = [];
  public intersectLayers: FilterLayer[] = [];
}

const FilterType = {
  region: 'region' as 'region',
  user: 'user' as 'user'
}
type FilterType = (typeof FilterType)[keyof typeof FilterType];
export { FilterType };

