import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";

@Injectable()
export class ScalebarService {

  constructor(private sharedService: SharedService, private esriService: EsriService) {
    
  }

  private scalebar: any;
 
  getScalebar() {
    if (!this.scalebar) {
      this.__createScalaber();
    }
  }

  __createScalaber() {
    this.scalebar = new this.esriService.Scalebar({
      view: this.sharedService.mapView, 
      // "dual" displays both miles and kilometers
      // "english" is the default, which displays miles
      // use "metric" for kilometers
      unit: "metric"
    });

    this.sharedService.mapView.ui.add(this.scalebar,
      "bottom-left"
    );
    console.log("getScalebar");
  }

}
