import { Injectable } from '@angular/core'; 
import { AppInsights } from 'applicationinsights-js'; 
import { ConfigService } from "../shared/utils/config.service";

@Injectable() 
export class ApplicationInsightsService { 

  private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: this.configService.AppInsightsKey 
	}; 

    constructor(private configService: ConfigService) { 
		if (!AppInsights.config) { 
          AppInsights.downloadAndSetup(this.config);          
		} 
	} 

	logPageView(name?: string, url?: string, properties?: any, 
			measurements?: any, duration?: number) { 
	AppInsights.trackPageView(name, url, properties, measurements, duration); 
	} 

	logEvent(name: string, properties?: any, measurements?: any) { 
		AppInsights.trackEvent(name, properties, measurements); 
	} 

    get IsConfigured() { return (this.config.instrumentationKey && this.config.instrumentationKey!='') }
} 
