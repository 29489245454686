import { Injectable } from '@angular/core';
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../shared/utils/config.service";
import { EsriService } from "../esri/js-esri.service";
import { LayerDataAttribute } from "../../models/layers/layer-data-attribute.models";
import { LoadingMapService } from "../loading/loading.service";
import { BookmarksService } from '../bookmarks/bookmarks.service';
import { PublicBookmark, UserBookmark, UserBookmarkData } from '../../models/bookmarks/bookmark.model';
import notify from 'devextreme/ui/notify';
import { FilterAttributeService } from '../filterattribute/filter-attribute.service';
import { LegendInfo, LegendItem } from '../../models/layers/legend.model';
import { MapService } from './map.service';
import { LegendsService } from '../legends/legends.service';
import { BehaviorSubject } from 'rxjs';
import { MapLayersService } from './map.layers.service';
import { Layer } from '../../models/layers/layer.model';
import { LayerDataWithAttr } from '../../models/layers/layer.data.model';
import { MapLayersEndpoint } from './endpoint-map.layers.service';
import { Filter } from '../../models/filters/filter.model';
import { FilterLayer } from '../../models/filters/filter.layer.model';
import { GalleryMapService } from '../gallerymap/gallerymap.service';

@Injectable()
export class FeatureLayerService {
  Color: any;
  string: any;
  constructor(public esriService: EsriService,
    private sharedService: SharedService,
    private configService: ConfigService,
    private filterAttributeService: FilterAttributeService,
    private loadingMapService: LoadingMapService,
    private bookmarksService: BookmarksService,
    private mapService: MapService,
    private mapLayerServise: MapLayersService,
    private legendsService: LegendsService,
    private mapLayersEndpoint: MapLayersEndpoint,
    private galleryMapService: GalleryMapService
  ) {
    

  }
  CurrentExtent: any;

  private GetPopupContentFeaure(feature) {

    let result = '*';
    let _feauture = feature.graphic;// as __esri.Graphic; 
    let attributes: LayerDataAttribute[] = (_feauture.sourceLayer as __esri.FeatureLayer).get<any>("layerAttributes");
    let fields = (_feauture.sourceLayer as __esri.FeatureLayer).fields;
    if (attributes) {
      let sourceLayer = (_feauture.sourceLayer as __esri.FeatureLayer);
      result = '<ul class="esri-popup__list">';
      result += `<span style="display:none">OBJECTID : {OBJECTID}</span>`;
      attributes.forEach(x => {
        if (x.inMini) {
          let field = fields.find(f => f.name == x.name)
          let _value = '';
          if (field.domain) {
            let values: any[] = field.domain.get('codedValues');
            let domainValue = values.map(val => {
              return { id: val.code, name: val.name };
            }).find(f => f.id == _feauture.attributes[x.name]);
            _value = domainValue? domainValue?.name : '';
          }else
          if (sourceLayer.typeIdField == x.name || sourceLayer.get<string>("subTypeField") == x.name) {
            let _id = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
            let typeValue = sourceLayer.types.find(t => t.id == _id);
            if (typeValue) {
              _value = typeValue.name;
            }
          } else {
            _value = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
            let url;
            let isUrl: boolean = false;
            try {
              url = new URL(_value);
              isUrl = url.protocol === "http:" || url.protocol === "https:";
            } catch (_) {
              isUrl = false;
            }
            if (isUrl) {
              _value = `<a target='blank' href='${_value}'>Більше...</a>`
            }
          }

          result += `<li><span class=esri-popup__text-secondary>${field.alias}</span> : <span class="esri-popup__text-primary">${_value}</span></li>`;
        }

      });
      result += '</ul>';
      //let azureStorageService = (_feauture.sourceLayer.layer as __esri.FeatureLayer).get<any>("azureStorageService");
      //let containerName = (_feauture.sourceLayer as __esri.FeatureLayer).get<string>("LayerDataGUID") + "-" + _feauture.attributes["OBJECTID"];
      //let blobs = await azureStorageService.listBlobsByContainer(containerName);
      //let attachedResult: string = '';
      //blobs.forEach(b => {
      //  attachedResult += `<li><span class=esri-popup__text-secondary>${b}</span> : <span class="esri-popup__text-primary">Name</span></li>`;
      //})

      //if (attachedResult) {
      //  result += '<ul class="esri-popup__list">' + attachedResult + '</ul>' ;
      //}

    }
    return result;
  }

  async getPopupAttachedFiles(feature, azureStorageService) {
    let result = "<ul>";
    let graphic = feature.graphic;// as __esri.Graphic;
    let containerName = (graphic.sourceLayer as __esri.FeatureLayer).get<string>("LayerDataGUID") + "-" + graphic.attributes["ObjecID"];
    let blobs = await azureStorageService.listBlobsByContainer(containerName);
    blobs.forEach(b => {
      result += `<li><span class=esri-popup__text-secondary>${b}</span> : <span class="esri-popup__text-primary">Name</span></li>`;
    })
    result += "</ul>";
    return result;
  }

  showPublicBookmarkByData(result: PublicBookmark): Promise<PublicBookmark> {   
    const promise = new Promise<PublicBookmark>((resolve, reject) => {
      if (result) {
        var token = {
          'server': this.configService._baseUrlRegionServices,
          'token': result.token ? result.token : this.configService.getGisToken()
        };
        //if (result.token) {
          this.esriService.IdentityManager.registerToken(token);
          this.configService.setGisToken(token.token);
        //}
        let layers = result.layers;

        if (!this.sharedService.CurrentRegionCode) {
          this.sharedService.CurrentRegionCode = result.code;
        }
        layers.forEach(d => {
          let layerID: any;
          let _layer: __esri.FeatureLayer | __esri.Sublayer;
          let _url = this.configService._baseUrlRegionServices + `${d.serviceName}`;
          //_url = _url.replace('MapServer', 'FeatureServer');
          let map: __esri.Map = this.sharedService.map;
          let layerType: 'feature' | 'map-image' | null;
          if (_url.toUpperCase().includes("FEATURESERVER")) {
            layerType = 'feature';
          } else {
            layerType = 'map-image';
          }
          let layerUrl = _url + '/' + d.layerName;
          if (layerType == 'map-image') {
            let mapLayer: __esri.MapImageLayer;
            let _existLayer = map.layers.find(x => x.type == 'map-image' && (x as __esri.MapImageLayer).url.includes(_url));
            if (_existLayer) {
              mapLayer = _existLayer as __esri.MapImageLayer;
              _layer = new this.esriService.SubLayer({
                id: d.layerName,
                visible: true,
                popupTemplate: {
                  title: d.name,
                  //overwriteActions: true, //TODO
                  content: this.GetPopupContentFeaure,
                  outFields: this.getAttributes(d.layerAttributes)
                }
              });
              mapLayer.sublayers.add(_layer as __esri.Sublayer);
            } else {

              mapLayer = new this.esriService.MapImageLayer({
                url: _url,
                sublayers: [
                  {
                    id: d.layerName,
                    visible: true,
                    popupTemplate: {
                      title: d.name,
                      //overwriteActions: true, //TODO
                      content: this.GetPopupContentFeaure,
                      outFields: this.getAttributes(d.layerAttributes)
                    }
                  }
                ]
              });
              _layer = mapLayer.sublayers.getItemAt(0);
              this.sharedService.map.add(mapLayer);
              this.sharedService.mapView.whenLayerView((mapLayer)).then(function (layerView) {
                self.loadingMapService.AddToWatch(layerView);
              }).catch(ex => {
                if (ex.name == "cancelled:layerview-create") {

                } else {
                  notify(`error on load layer.\r\n  ${ex.message}`, "error", 3500);
                }
              })
            }
            layerID = mapLayer.id;
          }
          else {
            _layer = new this.esriService.FeatureLayer(
              {
                url: layerUrl,
                visible: true,
                popupTemplate: {
                  title: d.name,
                  // overwriteActions: true, // TODO
                  highlightEnabled: true,
                  content: this.GetPopupContentFeaure,
                  outFields: this.getAttributes(d.layerAttributes),
                }
              }
            );
            layerID = _layer.id;
            this.sharedService.map.add(_layer);
            this.sharedService.mapView.whenLayerView((_layer as __esri.Layer)).then(function (layerView) {
              self.loadingMapService.AddToWatch(layerView);
              layerView.layer.set<any>("layerView", layerView);
            }).catch(ex => {
              if (ex.name == "cancelled:layerview-create") {

              } else {
                notify(`error on load layer.\r\n  ${ex.message}`, "error", 3500);
              }
            })

          }

          let subTypeField: string; // TODO let subTypeField: string = d.subtypeFiledNameInfo; 

          if (d.layerAttributes.find(a => a.hasFilter)) {
            let groupTypeArray: any[] = [];
            let filter = '';

            d.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
              if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
                let visibledSubtypes = [];
                attr.subTypeFilters.forEach(st => {
                  visibledSubtypes.push(st.subTypeID);
                });
                (_layer as __esri.Layer).set<number[]>("visibledSubtypes", visibledSubtypes);
                let strFilter = "";
                attr.subTypeFilters.forEach(s => {
                  if (strFilter.length > 0) {
                    strFilter = strFilter + ','
                  }
                  strFilter = strFilter + s.subTypeID;
                })
                if (filter.length > 0) {
                  filter = filter + ' and ';
                }
                filter += attr.name + " in ( " + strFilter + ")";
                subTypeField = attr.name;
              } else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
                let filterKOATUU = attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
                if (filter.length > 0) {
                  filter = filter + ' and ';
                }
                filter += filterKOATUU;
                (_layer as __esri.Layer).set<string>("filterKOATUU", filterKOATUU);
              } else if (attr.filterType == this.filterAttributeService.TypeGroup) {
                let groupFilter = "";
                attr.groupValueFilters.forEach(g => {
                  if (groupFilter.length > 0) {
                    groupFilter += ',';
                  }
                  groupFilter += `N'${g.value}'`;
                })

                if (groupFilter.length > 0) {
                  if (filter.length > 0) {
                    filter = filter + ' and ';
                  }
                  filter += attr.name + " in ( " + groupFilter + ")";
                }
                let n = {
                  name: attr.name,
                  group: attr.groupValueFilters
                }
                groupTypeArray.push(n);

              }
            })
            if (groupTypeArray && groupTypeArray.length > 0) {
              (_layer as __esri.Layer).set<any>("filterGroupType", groupTypeArray);
            }
            (_layer as __esri.FeatureLayer).definitionExpression = filter;
          }
          let subTypeFilters: any[] = d.layerAttributes.find(a => a.filterType == this.filterAttributeService.TypeSubType && a.subTypeFilters)?.subTypeFilters;
          var self = this;
          let isStartedLoad = false;
          let loadedLayerInfo = new BehaviorSubject<any>(null);

          if (!!!d.hideLegend) {
            this.addLegendItem(_url, d.layerName, subTypeFilters).then(legends => {
              legends.forEach(async (legend: LegendItem) => {
                this.sharedService.publicLegends.push(legend);
                legend.opacity = Number.parseFloat(d.transparency) * 100;
                legend.layerID = layerID;
                legend.name = legend.name ? legend.name : _layer.get<string>('name');
                legend.canAddObject = false;
                legend.SubTypeField = subTypeField;
                legend.layerGuid = d.id;
                legend.layerURL = _layer.get<any>('url');
                legend.filterKOATUU = _layer.get<string>('filterKOATUU');
                legend.filterGroupType = _layer.get<any>("filterGroupType");
                //(_layer as __esri.Sublayer). TO DO
                //legend.layerType = (_layer as __esri.Layer).type == 'feature' ? 'feature' : ((_layer as __esri.Layer).type == 'map-image' ? 'map-image' : null);
                legend.layerType = (_layer as __esri.Layer).type == 'feature' ? 'feature' : 'map-image';
                legend.layerDefinitionExpression = (_layer as __esri.FeatureLayer).definitionExpression;
                if (!legend.isRaster) {
                  if (legend.defaultValues && !!!legend.SubTypeField) {
                    if (isStartedLoad) {
                      let subj = loadedLayerInfo.subscribe(val => {
                        if (val) {
                          legend.SubTypeField = val;
                          subj.unsubscribe();
                          self.mapService.getRecordCountByUrl(layerUrl, self.legendsService.getSubQuery(legend)).then(recordCount => {
                            legend.totalCount = recordCount;
                          })
                        }
                      })
                    } else {
                      isStartedLoad = true;
                      this.getLayerInfo(layerUrl).then(layerInfo => {
                        if (layerInfo.subtypes) {
                          subTypeField = layerInfo.subtypeFieldName;
                          legend.SubTypeField = subTypeField;
                          loadedLayerInfo.next(subTypeField);
                        }
                        self.mapService.getRecordCountByUrl(layerUrl, self.legendsService.getSubQuery(legend)).then(recordCount => {
                          legend.totalCount = recordCount;
                        })
                      });

                    }
                  } else {
                    self.mapService.getRecordCountByUrl(layerUrl, self.legendsService.getSubQuery(legend)).then(recordCount => {
                      legend.totalCount = recordCount;
                    })
                  }
                } else {
                  this.getLayerInfo(layerUrl).then(layerInfo => {
                    legend.extent = layerInfo.extent;
                  })
                }
                
              })
            });

          }
          
          if (!!d.transparency) {

            _layer.opacity = Number.parseFloat(d.transparency);
          }

          if (!!d.background) {
            var rgba = d.background.split(',');
            (_layer as __esri.Layer).set<string[]>("layerColor", rgba);
          }
          (_layer as __esri.Layer).set<boolean>("editable", false);
          (_layer as __esri.Layer).set<boolean>("showed", false);
          (_layer as __esri.Layer).set<string>("LayerGuid", d.layerId);
          (_layer as __esri.Layer).set<string>("LayerDataGUID", d.id);
          (_layer as __esri.Layer).set<string>("name", d.name);
          (_layer as __esri.Layer).set<any>("layerAttributes", d.layerAttributes);
        });

        //return result;
        resolve(result);

      } else {
        //return null;
        reject();
      }
    }
    )
    return promise;
  }

  private addLegendItem(url, id, visibledSubtypes?: any[]) {
    let legends: Array<LegendItem> = new Array<LegendItem>();
    return this.GetLegendInfo(url).then(legendInfo => {
      let layerInfo = legendInfo.layers.find(x => x.layerId == id);
      let arrSubTypeFilter: any[];
      if (visibledSubtypes) {
        arrSubTypeFilter = [];
        layerInfo.legend.forEach(f => {
          if (f.values?.length > 0) {
            let vs = visibledSubtypes.find(vs => vs.subTypeID == f.values[0]);
            if (vs) {
              arrSubTypeFilter.push(f);
            }
          }
        })
      } else {
        arrSubTypeFilter = layerInfo.layerType == 'Raster Layer' ?
          [{
            label: layerInfo.label,
            isRaster: true
          }]:
          layerInfo.legend;
      }
      arrSubTypeFilter.
        forEach(tmpLegend => {
          let imageData = 'data:image/png;base64,' + tmpLegend.imageData;
          let legend = new LegendItem();
          legend.legendGUID = this.legendsService.generateGuid();
          legend.id = id;
          legend.name = (tmpLegend.label) ? tmpLegend.label : null;
          legend.image = imageData;
          legend.defaultValues = tmpLegend.values ?? [''];
          legend.isRaster = tmpLegend.isRaster ?? false;
          legends.push(legend);
        });
      return legends;
    }).catch(ex => {
      notify(`Помилка додавання легенди.\r\n  ${ex._body}`, "error", 3500);
      return legends;
    });
  }

  public GetLegendInfo(url) {
    url = url.replace('FeatureServer', 'MapServer');
    let info = this.sharedService.LegendInfoList.find(i => i.url == url);
    
    if (info) {
      return new Promise<any>((resolve, reject) => {
        /*let sub =*/
        info.getLoaded().subscribe(val => {
          if (val) {
            
            resolve(info.legendInfo);
            //sub.unsubscribe(); // TO DO - CHECK
          }
        });//.unsubscribe();
      })
      
    } else {
      info = new LegendInfo();
      info.url = url;
      this.sharedService.LegendInfoList.push(info);
    }
    let options: __esri.RequestOptions =
    {
      responseType: 'json'
    };
    url += "/legend?f=pjson&token=" + this.configService.getGisToken(); 
    let jLegend: any;
    return this.esriService.Request(url, options).then(function (result) {
      jLegend = result.data;
      info.legendInfo = jLegend;
      info.setLoaded(true);
      return jLegend;
    }).catch(function (error) {
      console.log("informative error message: ", error.message);
    });
  }

  private getFilterValue(filterType) {
    if (filterType == this.filterAttributeService.TypeKOATUU) {
      return this.sharedService.CurrentRegionCode;
    }

    return '';
  }

  public getAttributes(attributes: LayerDataAttribute[]): any[] {

    let result = [];
    attributes.forEach(x => {
      result.push(x.name);
    })
    return result;
  }

  async getLayerInfo(layerURL) {

    let url = layerURL.replace('FeatureServer', 'MapServer');
    return await this.mapLayerServise.getLayerInfo(url);
  }

  showFeatureServer(layerId: string) {   
    let iDs = [];
    this.sharedService.mapView.allLayerViews.forEach(result => {
      iDs.push(result.layer.id);
    });
    let layerList = this.mapLayerServise.prepareLayerList();

    layerId = this.mapLayerServise.parentLayerID(layerId);

    let selectedLayers: Layer[] = new Array<Layer>();
    let _layer = layerList.find(x => x.id == layerId);
    selectedLayers.push(_layer);

    let _tempChildren = this.mapLayerServise.getLayerSelectedChildrent(_layer);
    if (_tempChildren.length > 0) {
      _tempChildren = _tempChildren.concat(selectedLayers);
    }

    _tempChildren.forEach(x => {
      if (x.isSelected) {
        selectedLayers.push(x);
      }
    })

    let notCreatedList: string[] = new Array<string>();
    iDs.forEach(x => {

      let tmp = this.sharedService.mapView.layerViews.find(z =>
        z.layer.id == x);

      let rLayer: any = tmp ? tmp.layer : null;
      if (rLayer) {
        if ((rLayer as __esri.FeatureLayer)) {
          let _item = selectedLayers.find(f => rLayer.LayerGuid && f.id == rLayer.LayerGuid);
          if (_item) {
            rLayer.visible = true;
          }
        }

      }
    });

    selectedLayers.forEach(x => {
      if (!x.isShow) {
        notCreatedList.push(x.id);
      }
    })
    if (notCreatedList.length <= 0) return;

    this.sharedService.getlayerData().subscribe(result => {
      let layers = result.filter(f => notCreatedList.findIndex(i => i == f.layerId) >= 0);
      let filter = this.sharedService.getCurrentFilterValue();
      if (filter) {
        //let filter = this.sharedService.getCurrentFilterValue();        
        //let filterLayers = layers.filter(f => filter.layers.findIndex(i => i.layerID == f.layerId) >= 0);
        let delArr: any[] = [];
        layers.forEach(lr => {
          let fLayers = filter.layers.filter(f => f.layerID == lr.layerId);
         // let fLayers = filterLayers.filter(f => f.id == lr.id);
          if (fLayers?.length > 0) {
            let existsLayer = fLayers.find(f => f.layerDataID == lr.id);
            if (existsLayer) {

            } else {
              delArr.push(lr.id);
            }
          }
        })

        delArr.forEach(id => {
          let index = layers.findIndex(l => l.id == id);
          layers.splice(index, 1);
        })
      }
      var self = this;
      layers.forEach(async d => {
        let layerID: any;
        let _layer: __esri.FeatureLayer | __esri.Sublayer;
        let _url = this.configService._baseUrlRegionServices + `${d.serviceName}`;
        //_url = _url.replace('MapServer', 'FeatureServer');
        let map: __esri.Map = this.sharedService.map;
        let layerType: 'feature' | 'map-image' | null;
        if (_url.toUpperCase().includes("FEATURESERVER")) {
          layerType = 'feature';
        } else {
          layerType = 'map-image';
        }
        let layerUrl = _url + '/' + d.layerName;
        let popupTemplateObj = {
          title: d.name,
          // overwriteActions: true, // TODO
          highlightEnabled: true,
          content: this.GetPopupContentFeaure,
          outFields: this.getAttributes(d.layerAttributes),
          actions: this.getActions()
        }
        let layerInfo = await this.getLayerInfo(layerUrl).then(res => {
          return res;
        });
        let subTypeField: string = layerInfo.subtypeField;

        let mapLayer: __esri.MapImageLayer;
        let _existMapLayer = map.layers.find(x => x.type == 'map-image' && (x as __esri.MapImageLayer).url.includes(_url));                
        if (layerType == 'map-image') {
          
          if (_existMapLayer) {
            mapLayer = _existMapLayer as __esri.MapImageLayer;

            let subLayer: __esri.Sublayer = mapLayer.sublayers.find(s => s.id == Number.parseInt(d.layerName));
            if (subLayer) {
              subLayer.visible = true;
              return;
            }

            _layer = new this.esriService.SubLayer({
              id: d.layerName,
              visible: true,
              popupTemplate: popupTemplateObj
            });
            let res = this.initLayerByAttributes(d, _layer);
            if (res.subTypeField) {
              subTypeField = res.subTypeField;
            }
            mapLayer.sublayers.add(_layer as __esri.Sublayer);
          } else {
            mapLayer = new this.esriService.MapImageLayer({
              url: _url,
              sublayers: [
                {
                  id: d.layerName,
                  visible: true,
                  popupTemplate: popupTemplateObj
                }
              ]
            });

            _layer = mapLayer.sublayers.getItemAt(0);
            let res = this.initLayerByAttributes(d, _layer);
            if (res.subTypeField) {
              subTypeField = res.subTypeField;
            }
            this.sharedService.map.add(mapLayer);
            this.sharedService.mapView.whenLayerView((mapLayer)).then(function (layerView) {
              self.loadingMapService.AddToWatch(layerView);
            }).catch(ex => {
              if (ex.name == "cancelled:layerview-create") {

              } else {
                notify(`error on load layer.\r\n  ${ex.message}`, "error", 3500);
              }
            })
          }
          layerID = mapLayer.id;
        } else if (layerType == 'feature') {
          let _existLayer = map.layers.find(x => x.type == 'feature' && (x as __esri.FeatureLayer).url.includes(_url) && (x as __esri.FeatureLayer).layerId == Number.parseInt(d.layerName));
          if (_existLayer) {
            return;
          }
          _layer = new this.esriService.FeatureLayer(
            {
              url: layerUrl,
              visible: true,
              popupTemplate: popupTemplateObj
            }
          );
          layerID = _layer.id;
          let res = this.initLayerByAttributes(d, _layer);
          if (res.subTypeField) {
            subTypeField = res.subTypeField;
          }
          this.sharedService.map.add(_layer);
          this.sharedService.mapView.whenLayerView((_layer as __esri.Layer)).then(function (layerView) {
            self.loadingMapService.AddToWatch(layerView);
            layerView.layer.set<any>("layerView", layerView);
          }).catch(ex => {
            if (ex.name == "cancelled:layerview-create") {

            } else {
              notify(`error on load layer.\r\n  ${ex.message}`, "error", 3500);
            }
          })
        }

        this.InitLegendItems(_layer, d, _url, layerUrl, layerID, subTypeField);        

        if (!!d.transparency) {

          _layer.opacity = Number.parseFloat(d.transparency);
        }

        if (!!d.background) {
          var rgba = d.background.split(',');
          (_layer as __esri.Layer).set<string[]>("layerColor", rgba);
        }
        let editable = d.layerAttributes.find(x => x.editable);
        (_layer as __esri.Layer).set<boolean>("editable", editable ? true : false);
        let showed = d.layerAttributes.find(x => x.showed);
        (_layer as __esri.Layer).set<boolean>("showed", showed ? true : false);
        (_layer as __esri.Layer).set<string>("LayerGuid", d.layerId);
        (_layer as __esri.Layer).set<string>("LayerDataGUID", d.id);
        (_layer as __esri.Layer).set<string>("name", d.name);
        (_layer as __esri.Layer).set<any>("layerAttributes", d.layerAttributes);
        (_layer as __esri.Layer).set<boolean>("canAddObject", d.canAddObject);
        //(_layer as __esri.Layer).set<string>("subTypeField", subTypeField);  // TODO check must recomment
      });

    })
      .unsubscribe();
  }

  showFeatureByFilter(filter: Filter) {

    this.showFeatureByFilterLayers(filter.layers)
  }

  registerToken(result) {
    var token = {
      'server': this.configService._baseUrlRegionServices,
      'token': result.token ? result.token : this.configService.getGisToken()
    };
    //if (result.token) {
    this.esriService.IdentityManager.registerToken(token);
    this.configService.setGisToken(token.token);
  }
  //private
  showFeatureByFilterLayers(layers: FilterLayer[]) {
    layers.forEach(d => {
      let layerID: any;
      let _layer: __esri.FeatureLayer | __esri.Sublayer;
      let _url = this.configService._baseUrlRegionServices + `${d.serviceName}`;
      //_url = _url.replace('MapServer', 'FeatureServer');
      let map: __esri.Map = this.sharedService.map;
      let layerType: 'feature' | 'map-image' | null;
      if (_url.toUpperCase().includes("FEATURESERVER")) {
        layerType = 'feature';
      } else {
        layerType = 'map-image';
      }
      let layerUrl = _url + '/' + d.layerName;
      let popupTemplateObj = {
        title: d.name,
        // overwriteActions: true, // TODO
        highlightEnabled: true,
        content: this.GetPopupContentFeaure,
        outFields: this.getAttributes(d.layerAttributes),
        actions: this.getActions()
      }
      if (layerType == 'map-image') {
        let mapLayer: __esri.MapImageLayer;

        let _existLayer = map.layers.find(x => x.type == 'map-image' && (x as __esri.MapImageLayer).url.includes(_url));
        if (_existLayer) {
          mapLayer = _existLayer as __esri.MapImageLayer;

          let subLayer: __esri.Sublayer = mapLayer.sublayers.find(s => s.id == Number.parseInt(d.layerName));
          if (subLayer) {
            subLayer.visible = true;
            return;
          }

          _layer = new this.esriService.SubLayer({
            id: d.layerName,
            visible: true,
            popupTemplate: popupTemplateObj
          });
          mapLayer.sublayers.add(_layer as __esri.Sublayer);
        } else {

          mapLayer = new this.esriService.MapImageLayer({
            url: _url,
            sublayers: [
              {
                id: d.layerName,
                visible: true,
                popupTemplate: popupTemplateObj
              }
            ]
          });

          _layer = mapLayer.sublayers.getItemAt(0);
          this.sharedService.map.add(mapLayer);
          this.sharedService.mapView.whenLayerView((mapLayer)).then(function (layerView) {
            self.loadingMapService.AddToWatch(layerView);
          }).catch(ex => {
            if (ex.name == "cancelled:layerview-create") {

            } else {
              notify(`error on load layer.\r\n  ${ex.message}`, "error", 3500);
            }
          })
        }
        layerID = mapLayer.id;
      }
      else if (layerType == 'feature') {
        let _existLayer = map.layers.find(x => x.type == 'feature' && (x as __esri.FeatureLayer).url.includes(layerUrl));
        if (_existLayer) {
          return;
        }
        _layer = new this.esriService.FeatureLayer(
          {
            url: layerUrl,
            visible: true,
            popupTemplate: popupTemplateObj
          }
        );
        layerID = _layer.id;
        this.sharedService.map.add(_layer);
        this.sharedService.mapView.whenLayerView((_layer as __esri.Layer)).then(function (layerView) {
          self.loadingMapService.AddToWatch(layerView);
          layerView.layer.set<any>("layerView", layerView);
        }).catch(ex => {
          if (ex.name == "cancelled:layerview-create") {

          } else {
            notify(`error on load layer.\r\n  ${ex.message}`, "error", 3500);
          }
        })

      }

      let subTypeField: string = d.subtypeFiledNameInfo;
      let isSubTypeFilter: boolean = false;
      if (d.layerAttributes.find(a => a.hasFilter)) {
        let groupTypeArray: any[] = [];
        let filter = '';
        d.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
          if (filter.length > 0) {
            filter = filter + ' and ';
          }
          let visibledSubtypes = [];
          if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
            isSubTypeFilter = true;
            
            attr.subTypeFilters.forEach(st => {
              if (d.subtypesFilterSelected) {
                let sfs = d.subtypesFilterSelected.find(f => f.id == st.subTypeID);
                if (sfs) {
                  visibledSubtypes.push(st.subTypeID);
                }
              } else {
                visibledSubtypes.push(st.subTypeID);
              }

            });
            (_layer as __esri.Layer).set<number[]>("visibledSubtypes", visibledSubtypes);
            let strFilter = "";
            attr.subTypeFilters //.filter(f => visibledSubtypes.includes(f.subTypeID))
            .forEach(s => {
              if (strFilter.length > 0) {
                strFilter = strFilter + ','
              }
              strFilter = strFilter + s.subTypeID;
            })
            filter += attr.name + " in ( " + strFilter + ")";
          } else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
            let filterKOATUU = attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
            filter += filterKOATUU;//attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
            (_layer as __esri.Layer).set<string>("filterKOATUU", filterKOATUU);
          }////////////////////
          else if (attr.filterType == this.filterAttributeService.TypeGroup) {
            let groupFilter = "";
            attr.groupValueFilters.forEach(g => {
              if (groupFilter.length > 0) {
                groupFilter += ',';
              }
              //if (attr.fieldType == 'string') {
              groupFilter += `N'${g.value}'`;
              //}

            })

            if (groupFilter.length > 0) {
              //if (filter.length > 0) {
              //  filter = filter + ' and ';
              //}
              filter += attr.name + " in ( " + groupFilter + ")";
            }
            let n = {
              name: attr.name,
              group: attr.groupValueFilters
            }
            groupTypeArray.push(n);
          }
          ///////////////////////////////////////////////
        })
        if (groupTypeArray && groupTypeArray.length > 0) {
          (_layer as __esri.Layer).set<any>("filterGroupType", groupTypeArray);
        }
        //sublayer.definitionExpression = `(${filter}) and (${d.layerFilter})`;
        let layerFilter = d.layerFilter ? ` and (${d.layerFilter})` : "";
        _layer.definitionExpression = `(${filter})${layerFilter}`;
        //(_layer as __esri.FeatureLayer).definitionExpression = filter;
      } else {
        if (d.layerFilter) {
          (_layer as __esri.FeatureLayer).definitionExpression = d.layerFilter;
        }
      }
      let bbb: any[];
      let lAttr = d.layerAttributes.find(a => a.filterType == this.filterAttributeService.TypeSubType && a.subTypeFilters);
      if (lAttr) {
        let stf: any[] = lAttr?.subTypeFilters;
        let mmm = d.subtypesFilterSelected?.map(m => {
          return m.id
        })
        bbb = stf.filter(f => mmm?.includes(f.subTypeID));
      }      

      let subTypeFilters: any[] = d.subtypesFilterSelected?.length > 0 ?
        (bbb ? bbb:
            d.subtypesFilterSelected.map(m => {
              return { subTypeID: m.id, subTypeValue: m.name };
            })
        )
        : d.layerAttributes.find(a => a.filterType == this.filterAttributeService.TypeSubType && a.subTypeFilters)?.subTypeFilters;

      //if (!isSubTypeFilter && d.subtypesFilterSelected) {
      //  subTypeFilters = d.subtypesFilterSelected.map(m => { return m.id });
      //  //sublayer.set<number[]>("visibledSubtypes", visibledSubtypes);
      //}
      var self = this;
      let isStartedLoad = false;
      let loadedLayerInfo = new BehaviorSubject<any>(null);
      this.addLegendItem(_url, d.layerName, subTypeFilters).then(legends => {

        legends.forEach(async (legend: LegendItem) => {
          let tmpLegend = legend.defaultValues ? this.sharedService.publicLegends.find(x => x.filterLayerGUID == d.id &&
                                                                                        x.id == legend.id &&
                                                                                        x.defaultValues.find(f => f == legend.defaultValues[0])) :
            this.sharedService.publicLegends.find(x => x.filterLayerGUID == d.id && x.id == legend.id);

          if (tmpLegend) {
            tmpLegend.layerID = layerID;
            tmpLegend.isFilter = true;
            tmpLegend.layerFilterExpression = d.layerFilter;
            tmpLegend.layerDefinitionExpression = (_layer as __esri.FeatureLayer).definitionExpression;
          } else
          {
            this.sharedService.publicLegends.push(legend); // ADDED

            legend.opacity = Number.parseFloat(d.transparency) * 100;
            legend.layerID = layerID;
            legend.name = legend.name ? legend.name : _layer.get<string>('name');            
            legend.SubTypeField = subTypeField;
            legend.layerGuid = d.layerDataID;
            legend.layerURL = layerUrl;// _layer.get<any>('url'); // TODO check
            legend.filterKOATUU = _layer.get<string>('filterKOATUU');
            legend.filterGroupType = _layer.get<any>("filterGroupType");
            legend.canAddObject = d.canAddObject;
            legend.layerFilterExpression = d.layerFilter;// _layer.get<string>('layerFilterExpression'); /// TODO check
            //(_layer as __esri.Sublayer). TO DO
            //legend.layerType = (_layer as __esri.Layer).type == 'feature' ? 'feature' : ((_layer as __esri.Layer).type == 'map-image' ? 'map-image' : null);
            legend.layerType = (_layer as __esri.Layer).type == 'feature' ? 'feature' : 'map-image';
            legend.layerDefinitionExpression = (_layer as __esri.FeatureLayer).definitionExpression;
            legend.isFilter = true;
            legend.filterLayerGUID = d.id;
            tmpLegend = legend;
          }
          //remove old legends.isFilter == true

          if (!tmpLegend.isRaster) {
            if (tmpLegend?.defaultValues && !!!tmpLegend.SubTypeField) {
              if (isStartedLoad) {
                let subj = loadedLayerInfo.subscribe(val => {
                  if (val) {
                    tmpLegend.SubTypeField = val;
                    subj.unsubscribe();
                    self.mapService.getRecordCountByUrl(layerUrl, self.legendsService.getSubQuery(tmpLegend)).then(recordCount => {
                      tmpLegend.totalCount = recordCount;
                    })
                  }
                })
              } else {
                isStartedLoad = true;
                this.getLayerInfo(layerUrl).then(layerInfo => {
                  if (layerInfo.subtypes && layerInfo.subtypes.length > 0) {
                    subTypeField = layerInfo.subtypeFieldName;
                    tmpLegend.SubTypeField = subTypeField;
                    loadedLayerInfo.next(subTypeField);
                  } else {
                    if (layerInfo.drawingInfo?.renderer?.type == "uniqueValue") {
                      let renderer = layerInfo.drawingInfo.renderer;
                      let subTypeFields = renderer.field1 ?? null;
                      if (subTypeFields && renderer.field2) {
                        subTypeFields += ',' + renderer.field2;
                      }
                      if (subTypeFields && renderer.field3) {
                        subTypeFields += ',' + renderer.field3;
                      }
                      tmpLegend.SubTypeField = subTypeFields;
                      loadedLayerInfo.next(subTypeFields);
                    }
                  }
                  self.mapService.getRecordCountByUrl(layerUrl, self.legendsService.getSubQuery(tmpLegend)).then(recordCount => {
                    tmpLegend.totalCount = recordCount;
                  })
                });

              }
            } else {
              self.mapService.getRecordCountByUrl(layerUrl, self.legendsService.getSubQuery(tmpLegend)).then(recordCount => {
                tmpLegend.totalCount = recordCount;
              })
            }
          } else {
            ////////
            this.getLayerInfo(layerUrl).then(layerInfo => {
              tmpLegend.extent = layerInfo.extent;
            })
          }

        })
      });

      if (!!d.transparency) {

        _layer.opacity = Number.parseFloat(d.transparency);
      }

      //if (!!d.background) {
      //  var rgba = d.background.split(',');
      //  (_layer as __esri.Layer).set<string[]>("layerColor", rgba);
      //}
      let editable = d.layerAttributes.find(x => x.editable);
      (_layer as __esri.Layer).set<boolean>("editable", editable ? true : false);
      let showed = d.layerAttributes.find(x => x.showed);
      (_layer as __esri.Layer).set<boolean>("showed", showed ? true : false);
      (_layer as __esri.Layer).set<string>("LayerGuid", d.layerID);
      (_layer as __esri.Layer).set<string>("LayerDataGUID", d.layerDataID);
      (_layer as __esri.Layer).set<string>("name", d.name);
      (_layer as __esri.Layer).set<string>("layerFilterExpression", d.layerFilter);
      (_layer as __esri.Layer).set<any>("layerAttributes", d.layerAttributes);
      (_layer as __esri.Layer).set<boolean>("canAddObject", d.canAddObject);
      (_layer as __esri.Layer).set<string>("subTypeField", subTypeField);
    });

  }

  collectBookmark(bookmark: UserBookmark) {

    let _data = new Array<UserBookmarkData>();
    let layerList = this.mapLayerServise.prepareLayerList();
    _data = layerList.filter(x => x.isSelected && !!!x.inFilter).map(y => {
      let _item = new UserBookmarkData();
      _item.layerID = y.id;
      _item.userBookmarkId = bookmark.id;
      y.inBookmark = true;
      return _item
    });

    layerList.filter(f => !f.isSelected).forEach(x => {
      x.inBookmark = false;
      x.inFilter = false;
    })

    bookmark.details = _data;
    if (this.sharedService.getCurrentFilterValue()) {
      bookmark.filterID = this.sharedService.getCurrentFilterValue().id;
    } else {
      bookmark.filterID = null;
    }

    bookmark.imageServiceID = this.galleryMapService.getBaseMapImageServiceGUID();
    bookmark.latitude = this.sharedService.mapView.center.latitude;
    bookmark.longitude = this.sharedService.mapView.center.longitude;
    bookmark.scale = this.sharedService.mapView.zoom;

    return bookmark;
  }

  private InitLegendItems(_layer: __esri.FeatureLayer | __esri.Sublayer, layerDataAttr: LayerDataWithAttr, _url, layerUrl, layerID, subTypeField) {
    // let _url = this.configService._baseUrlRegionServices + `${layerDataAttr.serviceName}`;
    //layerID = mapLayer.id;   or layerID = _layer.id;
    // let layerUrl = _url + '/' + layerDataAttr.layerName;
    let bbb: any[] = [];
    if (this.sharedService.getCurrentFilterValue()) {
      let currentFilter = this.sharedService.getCurrentFilterValue();
      let currentLayerFilter = currentFilter.layers.find(f => f.layerDataID == layerDataAttr.id);
      

      let lAttr = currentLayerFilter?.layerAttributes.find(a => a.filterType == this.filterAttributeService.TypeSubType && a.subTypeFilters);
      if (lAttr) {
        let stf: any[] = lAttr?.subTypeFilters;
        let mmm = currentLayerFilter.subtypesFilterSelected?.map(m => {
          return m.id
        })
        bbb = stf.filter(f => mmm?.includes(f.subTypeID));
      }
    }
    let subTypeFilters: any[] = bbb.length > 0 ?
      bbb
      : layerDataAttr.layerAttributes.find(a => a.filterType == this.filterAttributeService.TypeSubType && a.subTypeFilters)?.subTypeFilters;;

    var self = this;
    let isStartedLoad = false;
    let loadedLayerInfo = new BehaviorSubject<any>(null);
    this.addLegendItem(_url, layerDataAttr.layerName, subTypeFilters).then(legends => {

      legends.forEach(async (legend: LegendItem) => {
        let tmpLegend = legend.defaultValues ? this.sharedService.publicLegends.find(x =>
          ((!!!x.isFilter && x.layerGuid == layerDataAttr.id) || (x.isFilter && x.filterLayerGUID == layerDataAttr.id))
          && x.id == legend.id && x.defaultValues?.find(f => f == legend.defaultValues[0])) : // TODO fix x.defaultValues                            
          this.sharedService.publicLegends.find(x => !!!x.isFilter && x.layerGuid == layerDataAttr.id && x.id == legend.id);
        let isExists: boolean = false;
        if (tmpLegend) {
          tmpLegend.layerID = layerID;
          tmpLegend.layerDefinitionExpression = (_layer as __esri.FeatureLayer).definitionExpression;
          //tmpLegend.isFilter = false;
          isExists = true;
        } else {
          this.sharedService.publicLegends.push(legend); // ADDED
          this.sharedService.setPublicLegendChanged(true);

          legend.opacity = Number.parseFloat(layerDataAttr.transparency) * 100;
          legend.layerID = layerID;
          legend.name = legend.name ? legend.name : _layer.get<string>('name');
          legend.canAddObject = layerDataAttr.canAddObject;
          legend.SubTypeField = subTypeField;
          legend.layerGuid = layerDataAttr.id;
          legend.layerURL = layerUrl;// CHECK _layer.get<any>('url');
          legend.filterKOATUU = _layer.get<string>('filterKOATUU');
          legend.filterGroupType = _layer.get<any>("filterGroupType");

          //legend.layerFilterExpression = null;// _layer.get<string>('layerFilterExpression'); /// TODO check
          legend.layerFilterExpression = _layer.get<string>('layerFilterExpression');
          legend.layerType = (_layer as __esri.Layer).type == 'feature' ? 'feature' : 'map-image';
          legend.layerDefinitionExpression = (_layer as __esri.FeatureLayer).definitionExpression;


          tmpLegend = legend;
        }
        
          if (this.sharedService.getCurrentFilterValue()) {
            let currFilter = this.sharedService.getCurrentFilterValue();
            let currLayer = currFilter.layers.find(f => f.layerID == layerDataAttr.layerId && f.layerDataID == layerDataAttr.id);
            if (currLayer) {
              tmpLegend.layerFilterExpression = currLayer.layerFilter;// _layer.get<string>('layerFilterExpression'); /// TODO check                  
              tmpLegend.isFilter = true;
              tmpLegend.filterLayerGUID = layerDataAttr.id;
            }
          } else {
            tmpLegend.layerFilterExpression = null;
            tmpLegend.isFilter = false;
            tmpLegend.filterLayerGUID = null;
          }
        if (!tmpLegend.isRaster) {
          if (!isExists || tmpLegend?.isFilter) {

            if (tmpLegend.defaultValues && !!!tmpLegend.SubTypeField) {
              if (isStartedLoad) {
                let subj = loadedLayerInfo.subscribe(val => {
                  if (val) {
                    tmpLegend.SubTypeField = val;
                    subj.unsubscribe();
                    self.mapService.getRecordCountByUrl(layerUrl, self.legendsService.getSubQuery(tmpLegend)).then(recordCount => {
                      tmpLegend.totalCount = recordCount;
                    })
                  }
                })
              } else {
                isStartedLoad = true;
                this.getLayerInfo(layerUrl).then(layerInfo => {
                  if (layerInfo.subtypes && layerInfo.subtypes.length > 0) {
                    subTypeField = layerInfo.subtypeFieldName;
                    tmpLegend.SubTypeField = subTypeField;
                    loadedLayerInfo.next(subTypeField);
                  } else {
                    if (layerInfo.drawingInfo?.renderer?.type == "uniqueValue") {
                      let renderer = layerInfo.drawingInfo.renderer;
                      let subTypeFields = renderer.field1 ?? null;
                      if (subTypeFields && renderer.field2) {
                        subTypeFields += ',' + renderer.field2;
                      }
                      if (subTypeFields && renderer.field3) {
                        subTypeFields += ',' + renderer.field3;
                      }
                      tmpLegend.SubTypeField = subTypeFields;
                      loadedLayerInfo.next(subTypeFields);
                    }
                  }
                  self.mapService.getRecordCountByUrl(layerUrl, self.legendsService.getSubQuery(tmpLegend)).then(recordCount => {
                    tmpLegend.totalCount = recordCount;
                  })
                });

              }
            } else {
              self.mapService.getRecordCountByUrl(layerUrl, self.legendsService.getSubQuery(tmpLegend)).then(recordCount => {
                tmpLegend.totalCount = recordCount;
              })
            }
          }          
        } else {
          /////
          this.getLayerInfo(layerUrl).then(layerInfo => {
            tmpLegend.extent = layerInfo.extent;
          })
        }
        
      })
    });
    (_layer as __esri.Layer).set<string>("subTypeField", subTypeField);
  }

  private initLayerByAttributes(d: LayerDataWithAttr, _layer: __esri.FeatureLayer | __esri.Sublayer) {
    let subTypeField;
    if (d.layerAttributes.find(a => a.hasFilter)) {
      let groupTypeArray: any[] = [];
      let filter = '';

      d.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
        if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
          let visibledSubtypes = [];
          attr.subTypeFilters.forEach(st => {
            visibledSubtypes.push(st.subTypeID);
          });
          (_layer as __esri.Layer).set<number[]>("visibledSubtypes", visibledSubtypes);
          let strFilter = "";
          attr.subTypeFilters.forEach(s => {
            if (strFilter.length > 0) {
              strFilter = strFilter + ','
            }
            strFilter = strFilter + s.subTypeID;
          })
          if (filter.length > 0) {
            filter = filter + ' and ';
          }
          filter += attr.name + " in ( " + strFilter + ")";
          subTypeField = attr.name;
        } else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
          let filterKOATUU = attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
          if (filter.length > 0) {
            filter = filter + ' and ';
          }
          filter += filterKOATUU;
          (_layer as __esri.Layer).set<string>("filterKOATUU", filterKOATUU);
        } else if (attr.filterType == this.filterAttributeService.TypeGroup) {
          let groupFilter = "";
          attr.groupValueFilters.forEach(g => {
            if (groupFilter.length > 0) {
              groupFilter += ',';
            }
            groupFilter += `N'${g.value}'`;
          })

          if (groupFilter.length > 0) {
            if (filter.length > 0) {
              filter = filter + ' and ';
            }
            filter += attr.name + " in ( " + groupFilter + ")";
          }
          let n = {
            name: attr.name,
            group: attr.groupValueFilters
          }
          groupTypeArray.push(n);

        }
      })
      if (groupTypeArray && groupTypeArray.length > 0) {
        (_layer as __esri.Layer).set<any>("filterGroupType", groupTypeArray);
      }
      (_layer as __esri.FeatureLayer).definitionExpression = filter;

      if (this.sharedService.getCurrentFilterValue()) {
        let currentFilter = this.sharedService.getCurrentFilterValue();
        let currentLayerFilter = currentFilter.layers.find(f => f.layerDataID == d.id);
        let layerFilter = currentLayerFilter ? ` and (${currentLayerFilter.layerFilter})` : "";
        _layer.definitionExpression = `(${filter})${layerFilter}`;
      }


    } else {
      if (this.sharedService.getCurrentFilterValue()) {
        let currentFilter = this.sharedService.getCurrentFilterValue();
        let currentLayerFilter = currentFilter.layers.find(f => f.layerDataID == d.id);
        _layer.definitionExpression = currentLayerFilter?.layerFilter;
      }
    }
    return { subTypeField: subTypeField };
  }

  private getActions(): any[] {
    const actions = [
      {
        id: "edit-geodata",
        className: "esri-icon-edit",
        title: "Редагувати"
      },
      {
        id: "view-geodata",
        className: "esri-icon-description",
        title: "Інфо"
      },
      {
        id: "has-files",
        className: "esri-icon-attachment",
        title: "Переглянути файли"
      },
      {
        id: "paint-geodata",
        className: "esri-icon-expand2",
        title: "Змінити"
      },
      {
        id: "delete-geodata",
        className: "esri-icon-trash",
        title: "Вилучити"
      }
    ];
    return actions;
  }
}



