<div class="w-tabs-content">
  <dx-list #list
           class="dx-list--min"
           [dataSource]="filterList"
           selectionMode="none"
           [showSelectionControls]="true"
           [activeStateEnabled]="false"
           [focusStateEnabled]="false"
           [hoverStateEnabled]="false"
           [pageLoadMode]="true"
           noDataText="Нема фільтрів">
    <div *dxTemplate="let filter of 'item'" class="filter-item" [ngClass]="{'filter-admin' : filter.userID==null}">
      <dx-switch (onValueChanged)="switchValueChanged($event, filter)"
                  [value]="filter.isSelected"
                  [hoverStateEnabled]="false">
      </dx-switch>
      <span class="item-text"> {{filter.name}} </span>
      <div  class="item-btn">
        <!--<a  (click)="showChart(filter)" class="btn-icon disabled" title="Діаграма">
          <span class="icon-chart"></span>
        </a>-->
        <a (click)="editSettings(filter)" class="btn-icon" title="Налаштування">
          <span class="esri-icon-settings"></span>
        </a>
        <a  (click)="removeFilter(filter)" class="btn-icon" title="Видалити">
          <span class="esri-icon-trash"></span>
        </a>
      </div>
    </div>
  </dx-list>
</div>
