import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";

@Injectable()
export class OverviewmapService {

  constructor(private sharedService: SharedService) {
    
  }

  getOverviewMap(visible: boolean = false) {
    //    this._createOverviewMap(result, visible);

  }

  private _createOverviewMap(OverviewMap, visible) {

    //this.sharedService.overviewMap = new OverviewMap({
    //  map: this.sharedService.map,
    //  visible: visible,
    //  attachTo: "bottom-right"
    //});

    ////overviewMapDijit end
    //console.log("getOverviewMap.startup");
    //this.sharedService.overviewMap.startup();

    //this.sharedService.overviewMap.on("error", msg => {
    //  console.log("overviewMapDijit error:  ", msg);
    //});
  }
}
