
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { EndpointFactory } from '../shared/endpoint-factory.service';
import { Filter } from '../../models/filters/filter.model';

@Injectable()
export class FilterEndpointService extends EndpointFactory {

  private readonly _filtersByRegionUser: string = "/api/filter/filtersusers";
  private readonly _update: string = "/api/filter/filter";

  get filtersByRegionUserUrl() { return this.configService.getApiURI() + this._filtersByRegionUser; }
  get filterUpdateUrl() { return this.configService.getApiURI() + this._update; }


  getFiltersEndpoint(regionId: string, userId: string): Promise<Filter[]> {
    userId = userId == "" ? null : userId;
    let endpointUrl = `${this.filtersByRegionUserUrl}/${regionId}/${userId}`;

    return this.http.get<any>(endpointUrl, this.getAuthHeader())
      .toPromise()
      .then(response => {
        return response
      }).catch(error => {
        return this.handlePromiseError(error, () => this.getFiltersEndpoint(regionId, userId));
      })

  }

  getUpdateFilterEndpoint(filter: any): Observable<Response> {
    let endpointUrl = `${this.filterUpdateUrl}/${filter.id}`;
    let param = JSON.stringify(filter);
    return this.http.put<any>(endpointUrl, param, this.getAuthHeader(true)).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateFilterEndpoint(filter));
      }),);
  }

  getFilterByIdEndpoint(filterId: string): Promise<any> {
    let endpointUrl = `${this.filterUpdateUrl}/${filterId}`;

    return this.http.get<any>(endpointUrl, this.getAuthHeader())
      .toPromise()
      .then(response  => {
        return response
      })
      .catch(error => {
        return this.handlePromiseError(error, () => this.getFilterByIdEndpoint(filterId));
      })

  }

  getAddFilterEndpoint(filter: any): Observable<Response> {

    return this.http.post<any>(this.filterUpdateUrl, JSON.stringify(filter), this.getAuthHeader(true)).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getAddFilterEndpoint(filter));
      }),);
  }

  getDeleteFilterEndpoint(filterId: string): Observable<Response> {

    let endpointUrl = `${this.filterUpdateUrl}/${filterId}`;

    return this.http.delete<any>(endpointUrl, this.getAuthHeader(true)).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteFilterEndpoint(filterId));
      }),);
  }
}
