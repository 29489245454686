<div class="panel-header">

  <a *ngIf="enableLayers" (click)="goToLayers()" class="btn-icon">
    <span class="icon-chart"></span>
  </a>
  <span id="legendList">
    {{regionTitle}}
  </span>
</div>
<div class="legend-list legend-list--public">
  <dx-list [items]="legends"
           [hoverStateEnabled]="false"
           (onItemHold)="onItemHold($event)"
           (onItemReordered)="reorder($event)"
           itemTemplate="legendsTemplate"
           noDataText="Нема даних">
          <!--dxo-item-dragging--! group="legends"
                             [data]="plannedLegends"
                             [allowReordering]="true"
                             [onDragStart]="onDragStart"
                             [onAdd]="onAdd"
                             [onRemove]="onRemove">
          </dxo-item-dragging!-->
    <div *dxTemplate="let legend of 'legendsTemplate'">
      <div class="legend-item" [ngClass]="{'legend-item--active': legend.isOpened}">
        <div *ngIf="!legend.isRaster" class="icon icon--traffic-light icon--btn" [ngClass]="{filled : legend.isFlash=='red', multi : legend.isFlash=='flash' }" (click)="selectLayerByID(legend.id, legend.layerID, legend.isFlash, legend.layerGuid, legend.defaultValues)" title="Вибрати"></div>

        <div *ngIf="!legend.isRaster" class="icon icon--count icon--btn" (click)="navigateToLayer(legend.legendGUID)" title="{{legend.totalCount}}"><span class="count" [ngClass]="{'esri-icon-layer-list' : legend.totalCount==undefined}">{{legend.totalCount}}</span></div>
        <div *ngIf="legend.isRaster" class="icon icon--count icon--btn" (click)="navigateToRaster(legend.legendGUID)" title="Перейти до даних"><span class="count" [ngClass]="{'icon-arrow-right' : legend.totalCount==undefined}"></span></div>
        <div (click)="legend.isOpened=!legend.isOpened" class="legend-item-content">

          <span class="legend-icon {{legend.icon}}" [style.background-color]="legend.color">
            <img *ngIf="!legend.isRaster" src="{{legend.image}}">
            <span *ngIf="legend.isRaster" class="esri-icon-default-action"></span>
          </span>
          <span title="{{ legend.name }}">
            <span>
              {{ legend.name }}
            </span>
          </span>
        </div>
        <div class="legend-item-content-bottom legend-item-content-bottom--public">
          <dx-slider [min]="0"
                     [max]="100"
                     [step]="10"
                     value="{{ legend.opacity }}"
                     hint="Прозорість шару"
                     [name]="legend.id"
                     (onValueChanged)="onValueChanged($event,legend)">
          </dx-slider>
        </div>
      </div>
    </div>
  </dx-list>
  <div id="legendDiv"></div>
</div>
