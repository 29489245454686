<div id="searchId"></div>

<div class="search">
  <div *ngIf="showSearchLayer" id="divSearchLayerID" class="search-layers">
    <dx-scroll-view direction="vertical">
      <ul>
        <li *ngFor="let data of arrSearchLayer">
          <dx-switch (onValueChanged)="switchValueChanged($event, data)"
                     [value]="data.isSelected"
                     [hoverStateEnabled]="false">
          </dx-switch>
          <span> {{data.name}} </span>
        </li>
      </ul>
    </dx-scroll-view>
    <div [ngClass]="{'progress': isSearching}"></div>
  </div>
  <button class="search__btn-layout" [ngClass]="{'search__btn-layout--active': isSelectedAllLayer()}" id="pointButtonSetting" (click)="showLayerMenu(!showSearchLayer)" type="button" title="Вибрати шари">
    <span class="esri-icon-sliders-horizontal"></span>
  </button>
  <dx-text-box [(value)]="searchText"
               [showClearButton]="true"
               height="34"
               name="search"
               placeholder="Пошук"
               valueChangeEvent="keyup"
               (onValueChanged)="onTextChanged($event)"
               (onFocusIn)="onFocusTextBox($event)"
               (onFocusOut)="onFocusOutTextBox($event)">
  </dx-text-box>
  <div *ngIf="showSearchResult" class="search-result">
    <dx-scroll-view direction="vertical">
      <span *ngIf="isNotFound" class="no_search_results">
        Нема результатів пошуку  
      </span>
      <ul>
        <li *ngFor="let data of searchResult" [attr.data-sourceindex]="data.sourceIndex">
          <span>{{data.name}}</span>
          <ul>
            <li *ngFor="let item of data.results">
              <a (click)="selectSearchItem(item)">
                <span *ngIf="item.isSelected" class="result-active"></span>
                <span [innerHTML] ="item.text">
                  
                </span>
              </a>
            </li>
          </ul>
          <div class="search-result-buttons">
            <a *ngIf="data.results.length>5" (click)="setCountByIndex(data.sourceIndex, 5)" title="Показати 5">
              <span>5</span>
            </a>
            <a *ngIf="data.results.length>15" (click)="setCountByIndex(data.sourceIndex, 10)" title="Показати 10">
              <span>10</span>
            </a>
            <a *ngIf="data.results.length>25" (click)="setCountByIndex(data.sourceIndex, 20)" title="Показати 20">
              <span>20</span>
            </a>
            <a *ngIf="!showOnlyLayer && searchResult.length>1" (click)="showByLayer(data.sourceIndex)" title="«Перейти до шару»">
              <span>«Перейти до шару»</span>
            </a>
            <a *ngIf="showOnlyLayer" class="a-primary" (click)="toAllLayers()" title="Показати всі шари">
              <span>«Показати всі шари»</span>
            </a>
            <a *ngIf="data.results.length % 5 == 0 && data.maxSuggestions == data.results.length" (click)="showMoreByIndex(data.sourceIndex, 10)" title="Показати більше +10">
              <span>+10</span>
            </a>
            <a *ngIf="data.results.length % 5 == 0 && data.maxSuggestions == data.results.length" (click)="showMoreByIndex(data.sourceIndex, 20)" title="Показати більше +20">
              <span>+20</span>
            </a>
            <a *ngIf="data.results.length % 5 == 0 && data.maxSuggestions == data.results.length" (click)="showMoreByIndex(data.sourceIndex, 100)" title="Показати більше +100">
              <span>+100</span>
            </a>
          </div>
        </li>
      </ul>
    </dx-scroll-view>
    <div [ngClass]="{'progress': isSearching}"></div>
  </div>
  <button class="search__btn-results" id="pointButtonSetting" (click)="showSearchResult=!showSearchResult" type="button" title="Результати пошуку">
    <span class="esri-icon-search"></span>
  </button>
</div>
