import { AfterContentInit, AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { PublicBookmark, UserBookmark } from "../../models/bookmarks/bookmark.model";
import { Layer } from '../../models/layers/layer.model';
import { AzureStorageService } from '../../services/azurestorage/azure-storage.service';
import { BookmarksService } from '../../services/bookmarks/bookmarks.service';
import { FiltersService } from '../../services/filters/filters.service';
import { GalleryMapService } from '../../services/gallerymap/gallerymap.service';
import { FeatureLayerService } from '../../services/map/feature-layer.service';
import { MapLayersService } from '../../services/map/map.layers.service';
import { SharedService } from '../../services/shared/shared.service';
import { BaseComponent } from "../basecomponent/base.component";
import { BookmarksStoryComponent } from '../bookmarksstory/bookmarks-story.component';


@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent extends BaseComponent implements OnInit, OnDestroy {
  public userBookmarks: UserBookmark[];
  selectedBookmark: UserBookmark;
  selectedBookmarkID: string;
  bookmarkId: string;
  bookamrkTitle: string;
  firstInit: boolean = true;
  storySubscription: Subscription;
  subscriptionParam: Subscription;
  @ViewChild(BookmarksStoryComponent, { static: true }) bookmarkStory: BookmarksStoryComponent;

  constructor(private sharedService: SharedService, private bookmarksService: BookmarksService, private activatedRoute: ActivatedRoute,
    private mapLayersService: MapLayersService,
    private galleryMapService: GalleryMapService,
    private filtersService: FiltersService,
    private featureLayerService: FeatureLayerService,
    private azureStorageService: AzureStorageService
  ) {
    super();
  } 

  ngOnDestroy(): void {
    this.clearData();
    this.subscriptionParam?.unsubscribe();
    this.storySubscription?.unsubscribe();
    }
  ngOnInit(): void {
    this.subscriptionParam = this.activatedRoute.params.subscribe((params: Params) => {
      this.bookmarkId = params['bookmarkid'];
      this.loadData(this.bookmarkId);
    })
  }

  loadData(parentID) {
    this.bookmarksService.getListByParentBookmark(parentID).then(listBookmarks => {
      //listBookmarks.forEach(b => {
      //  b.imageSource = ["../../../assets/nature-les.jpeg", "../../../assets/nature-kuvshinka.jpeg"];
      //})


      this.bookmarkStory.isShow = true;
      this.bookmarkStory.backToBookmark = true;
      this.bookmarkStory.UserBookmarks = listBookmarks;
      
      

      if (this.storySubscription) {

      } else {
        
        this.sharedService.getSelectedBookmark().subscribe(selBookmark => {
          if (selBookmark?.isStory) {
            this.selectedBookmark = selBookmark;
            this.bookmarkStory.setSelectedBookmark(selBookmark as PublicBookmark);
          } else {
            this.selectedBookmark = listBookmarks?.length > 0 ? listBookmarks[0] : null;
            this.bookmarkStory.setSelectedBookmark(this.selectedBookmark as PublicBookmark);
          }

          this.storySubscription = this.bookmarkStory.changeEvent.subscribe(val => {
            this.selectBookmark(val);
          });

        }).unsubscribe();
        
      }

    })

  }

  selectBookmark(bookmark: UserBookmark) {


    if (this.sharedService.getSelectedBookmarkValue()?.id == bookmark.id) {
      return;
    }


    if (this.sharedService.getCurrentFilterValue()) {
      this.filtersService.unselectFilter();
    }

    this.selectedBookmark = bookmark;
    this.sharedService.setSelectedBookmark(bookmark);

    let layers = this.sharedService.getLayerList().getValue();

    layers.forEach(_layer => {
      _layer.isSelected = this.selectedBookmark.details.find(x => x.layerID == _layer.id) != null;
      this.selectChildren(_layer.layerChildren);

    })

    this.sharedService.setLayers(layers);


    layers.forEach(result => {
      this.hideLayers(result.layerChildren);
      if (!result.isSelected) {
        this.mapLayersService.HideLayerDataByLayerIDEx(result.id);
      }
    });

    if (this.selectedBookmark.filterID) {
      this.filtersService.selectFilter(this.selectedBookmark.filterID);
    } else {
      layers.forEach(result => {
        if (result.isSelected) {
          this.featureLayerService.showFeatureServer(result.id);
        }
      }
      );
    }
    

    if (this.selectedBookmark.imageServiceID)
      this.galleryMapService.selectBaseMap(this.selectedBookmark.imageServiceID);

    this.GoTo();
  }

  clearData() {
    let layers = this.sharedService.getLayerList().getValue();
    let layer = layers.filter(x => x.isSelected);
    if (layer.length > 0)
      layer.forEach(x => {
        this.mapLayersService.HideLayerDataByLayerIDEx(x.id);
        //layers.splice(layers.indexOf(x), 1);
        x.isSelected = false;
      })

    if (this.sharedService.getCurrentFilterValue()) {
      this.filtersService.unselectFilter();
    }

    this.sharedService.setLayers(layers);
  }

  hideLayers(layers: Layer[]) {
    layers.forEach(_layer => {
      this.hideLayers(_layer.layerChildren);
      if (!_layer.isSelected)
        this.mapLayersService.HideLayerDataByLayerIDEx(_layer.id);

    })
  }

  selectChildren(layers: Layer[]) {
    layers.forEach(_layer => {
      _layer.isSelected = this.selectedBookmark.details.find(x => x.layerID == _layer.id) != null;
      this.selectChildren(_layer.layerChildren);
    })
  }

  private GoTo() {
    let opts = {
      duration: 500
    };
    let _zoom: number = this.selectedBookmark.scale && this.selectedBookmark.scale != 0 ? this.selectedBookmark.scale : this.sharedService.mapView.scale;
    let latitude = this.selectedBookmark.latitude;
    let longitude = this.selectedBookmark.longitude;
    if (latitude && longitude)
      this.sharedService.mapView.goTo({ center: [longitude, latitude], zoom: _zoom }, opts);
  }

  //setSelectedBookmark(bookmark: PublicBookmark) {
  //  //this.selectedBookmark = bookmark;
  //  this.selectedBookmarkID = bookmark ? bookmark.id : (this.userBookmarks?.length > 0 ? this.userBookmarks[0].id : null); 
  //}

  //selectBookmark(event) {
  //  if (!this.firstInit && event.addedItems?.length > 0) {
      
  //    this.selectedBookmark = this.userBookmarks.find(x => x.id == event.addedItems[0].id);
  //    this.selectedBookmarkID = event.addedItems[0].id; // this.firstInit ? this.selectedBookmark[0].id : event.addedItems[0].id;      
  //    this.changeEvent.emit(this.selectedBookmarkID);
  //  } else {
  //    this.firstInit = false;
  //    this.selectedBookmark = this.userBookmarks.find(x => x.id == this.selectedBookmarkID);
  //    //this.selectedBookmarkID = event.addedItems[0].id; // this.firstInit ? this.selectedBookmark[0].id : event.addedItems[0].id;      
  //    if (event.addedItems[0].id == this.selectedBookmarkID) {
  //      this.changeEvent.emit(this.selectedBookmarkID);
  //    }
      
  //  }   
  //} 
 
}
