<div class="panel-header">
  <a (click)="goBack()" class="btn-icon">
    <i class="icon-close"></i>
  </a>
  <a *ngIf="isEditable" (click)="editGeodata()" class="btn-icon">
    <i class="icon-edit"></i>
  </a>
  <span title="{{layerName}}">
    {{layerName}}
  </span>
</div>
<div class="view-geodate">  
  <dx-scroll-view direction="vertical">
    <ul *ngIf="!fileOnly" class="list-info">
      <li *ngFor="let attr of attributes" class="list-info__item">
        <div class="list-info__item-label">
          {{ attr.alias }}:
        </div>
        <div class="list-info__item-text">
          {{ attr.value }}
        </div>
      </li>
    </ul>
    <br *ngIf="fileOnly" />
    <ul class="list-files">      
      <li *ngFor="let doc of listDocuments" class="list-files__item">
        <a (click)="downloadDoc(doc)" class="list-files__item-link"> {{doc}} </a>
      </li>
    </ul>
  </dx-scroll-view>
</div>
