<div class="app-toolbar">

  <div *ngIf="!showCustomLayer" [ngClass]="{'disableDiv': isDisabled}">
    <button class="action-button" id="customLayer" (click)="CustomLayer(true)" type="button" title="Редагуваня власних об'єктів">
      <span class=" icon-edit"></span>
    </button>
    <button class="action-button" id="createFilter" (click)="createFilter()" type="button" title="Створити фільтр">
      <span class="icon-filter-add"></span>
    </button>
    <button class="action-button" id="createBookmark" (click)="createBookmark()" type="button" title="Зберегти закладку">
      <span class="icon-bookmark-add"></span>
    </button>
    <div *ngIf="isShowCadastralXML" class="action-button" title="Імпорт обміного файлу">
      <span class="icon icon-import-file">
        <input type="file" id="XMLToUpload" (change)="XMLfileEvent($event)" title="Імпорт обміного файлу">
      </span>
    </div>
  </div>

  <div *ngIf="showCustomLayer&&!showSettings">
    <div class="btn-double">
      <button class="btn-double__primary action-button esri-icon-blank-map-pin" id="pointButton" (click)="drawPoint()" type="button" title="Маркер"></button>
      <button class="btn-double__secondary" id="pointButtonSetting" (click)="symbolSetting('point')" type="button" title="Настройки маркера"></button>
    </div>
    <div class="btn-double">
      <button class="btn-double__primary action-button esri-icon-polyline" id="polylineButton" type="button" (click)="drawPolyline()" title="Лінія"></button>
      <button class="btn-double__secondary" id="pointButtonSetting" (click)="symbolSetting('line')" type="button" title="Настройки лінії"></button>
    </div>
    <div class="btn-double">
      <button class="btn-double__primary action-button esri-icon-polygon" id="polygonButton" type="button" (click)="drawPolygon()" title="Полігон"></button>
      <button class="btn-double__secondary" id="pointButtonSetting" (click)="symbolSetting('polygon')" type="button" title="Настройки полігону"></button>
    </div>
    <button class="action-button action-button--close esri-icon-close-circled" id="closeBtn" type="button" (click)="CustomLayer(false)" title="Закрити"></button>

  </div>

  <div *ngIf="showSettings">
    <table>

      <tr>
        <td>
          <dx-color-box [(value)]="CurrentColor"
                        [editAlphaChannel]="true"
                        pplyButtonText="Готово"
                        cancelButtonText="Відміна" width="72"></dx-color-box>
        </td>
        <td>
          <div [hidden]="currentTypeSymbol!='point'">
            <dx-select-box [dataSource]="stylePointDataSource"
                           displayExpr="name"
                           valueExpr="id"
                           placeholder="Cтиль"
                           [(value)]="currentStyle"
                           noDataText="Немає даних" width="120">
              <dx-validator>
                <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>
          <div [hidden]="currentTypeSymbol!='line'">
            <dx-select-box [dataSource]="styleLineDataSource"
                           displayExpr="name"
                           valueExpr="id"
                           placeholder="Cтиль"
                           [(value)]="currentStyleLine"
                           (onOpened)="onOpened($event)"
                           noDataText="Немає даних" width="120">
              <dx-validator>
                <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>
        </td>
        <td>
          <div [hidden]="currentTypeSymbol=='polygon'">
            <dx-number-box [max]="32"
                           [min]="0.1"
                           [step]="0.5"
                           [showSpinButtons]="true"
                           [(value)]="currentWidth"
                           width="64">

            </dx-number-box>
          </div>
        </td>
        <td>
          <button class="action-button action-button--close esri-icon-close-circled" id="closeBtn" type="button" (click)="saveSymbolSettings()" title="Закрити"></button>
        </td>
      </tr>
    </table>

  </div>

</div>
