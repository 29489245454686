import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ConfigService {

  /// url to api - server (backend) . it should be https://api.xgis.com.ua
  _apiURI: string = environment.apiURI;

  // token  for user of ArcGis Server . MUST BE CHANGED EVERY MONTH
  _gisToken: string = environment.gisToken;

  /// url to Arcgis Server Services . it should be https://arcgisserver.xgis.com.ua/gis/rest/services/
  _baseUrlRegionServices: string = environment.baseUrlRegionServices;
  
  /// url to additional Print Service . It should be https://arcgisserver.xgis.com.ua/gis/rest/services/print/{name_of_service}/GPServer/Export%20Web%20Map 
  private printServerUrl = environment.printServerUrl;

  /// Application Insight Instrumentation Key 
  private instrumentationKey = environment.instrumentationKey;

  private exportGeodataServiceUrl = environment.exportGeodataServiceUrl;

  private importGeodataServiceUrl = environment.importGeodataServiceUrl;

  private uploadExcelToImportUrl = environment.uploadExcelToImportUrl;

  private proxyPageUrl = environment.proxyPageUrl;

  private proxyCorsServer = environment.proxyCorsServer;

  private cadastreMapCorsServer = environment.cadastreMapCorsServer;

  private cadastreMapWMSService = environment.cadastreMapWMSService;

  private cadastreGetInfoUrl = environment.cadastreGetInfoUrl;

  private importCadastralXMLUrl = environment.importCadastralXMLUrl;

  private uploadCadastralXMLUrl = environment.uploadCadastralXMLUrl;

  //private account = "xgisstorage";
  //private accountKey = "8IdicGJFVpafA+CZ+9QDLzWsiqH0aJ2d5ufSBR2fltCblCo11f/2Vf6eRhVh++cd7nCLd5sIRoNdbl1wOI5CWA==";
  private azureStorageSASUrl = environment.azureStorageSASUrl;

  private worldTopoMapURL = 'http://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer';
  private worldTopoMapThumbnailURL = '../../../assets/images/topo_map.jpg';
  private worldStreetMapUrl = 'http://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer';
  private worldStreetThumbnailMapUrl = '../../../assets/images/world_street_map.jpg';
  private worldImageryMapUrl = 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer';
  private worldImageryThumbnailMapUrl = '../../../assets/images/tempimagery.jpg';
  private worldGreyMapURL = "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer";
  private worldGreyMapThumbnailURL = '../../../assets/images/gray.jpg';
  private ukraineCenterLongitude = 31.4900927;
  private ukraineCenterLatitude = 48.5501735;

    
  constructor( ) {  }
  get AppInsightsKey()
  {
    return this.instrumentationKey;
  }
  getApiURI() {
    return this._apiURI;
  }

  getGisToken() {
    return this._gisToken;
  }

  setGisToken(token: string) {
    this._gisToken = token;
  }

  getBaseUrlRegionServices() {
    return this._baseUrlRegionServices;
  }

  get WorldTopoMapURL() {
    return this.worldTopoMapURL;
  }

  get WorldTopoMapThumbnailURL() {
    return this.worldTopoMapThumbnailURL;
  }

  get WorldStreetMapUrl() {
    return this.worldStreetMapUrl;
  }

  get WorldStreetThumbnailMapUrl() {
    return this.worldStreetThumbnailMapUrl;
  }

  get WorldImageryMapUrl() {
    return this.worldImageryMapUrl;
  }
  get WorldImageryThumbnailMapUrl() {
    return this.worldImageryThumbnailMapUrl;
  }

  get WorldGreyMapURL() {
    return this.worldGreyMapURL;
  }

  get WorldGreyMapThumbnailURL() {
    return this.worldGreyMapThumbnailURL;
  }

  get UkraineCenterLatitude() {
    return this.ukraineCenterLatitude;
  }

  get UkraineCenterLongitude() {
    return this.ukraineCenterLongitude;
  }

  get PrintServiceUrl() {
    return this.printServerUrl;
  }

  get ExportGeodataServiceUrl() {
    return this.exportGeodataServiceUrl;
  }

  get ImportGeodataServiceUrl() {
    return this.importGeodataServiceUrl;
  }

  get UploadExcelToImportUrl() {
    return this.uploadExcelToImportUrl;
  }

  get ProxyPageUrl() {
    return this.proxyPageUrl;
  }

  get ProxyCorsServer() {
    return this.proxyCorsServer;
  }

  get CadastreMapCorsServer() {
    return this.cadastreMapCorsServer;
  }

  get CadastreMapWMSService() {
    return this.cadastreMapWMSService;
  }

  get CadastreGetInfoUrl() {
    return this.cadastreGetInfoUrl;
  }

  get AzureStorageSASUrl() {
    return this.azureStorageSASUrl;
  }

  get ImportCadastralXMLUrl() {
    return this.importCadastralXMLUrl;
  }

  get UploadCadastralXMLUrl() {
    return this.uploadCadastralXMLUrl;
  }
}
