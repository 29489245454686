import { Component, OnDestroy, OnInit } from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { LoadingMapService } from "../../services/loading/loading.service";
import { Subscription } from 'rxjs';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  isShow: boolean = true;
  isLoading: boolean = true;
  subscribeLoading: Subscription;
  subscribePanel: Subscription;

  constructor(private loadingMapService: LoadingMapService, private sharedService: SharedService) { }

  public nav: object = {};
  public map: object = {};
  mapWidth: number;
  navWidth: number;

  validate(event: ResizeEvent): boolean {
    const min_nav_px: number = 320;
    if (
      event.rectangle.width &&
      (event.rectangle.width < min_nav_px || event.rectangle.width > window.innerWidth - event.rectangle.width)
    ) {
      return false;
    }
    return true;
  }

  onResize(event) {
    this.mapWidth = window.innerWidth - this.navWidth;

    this.map = {
      width: `${this.mapWidth}px`
    };
  }

  onResizeEnd(event: ResizeEvent): void {
    this.nav = {
      width: `${event.rectangle.width}px`
    };
    this.map = {
      width: `${window.innerWidth - event.rectangle.width}px`,
    };

    this.mapWidth = window.innerWidth - event.rectangle.width;
    this.navWidth = event.rectangle.width;
    this.sharedService.setNavigationMenuWidth(this.navWidth);
  }
  
  ngOnInit() {
    this.subscribeLoading= this.loadingMapService.getIsLoading().subscribe(result => {
      this.isLoading = result;
    })

    this.subscribePanel = this.sharedService.getShowPanel().subscribe(value => {
      this.isShow = value;
    })
    this.sharedService.setNavigationMenuWidth(this.navWidth);
  }

  ngOnDestroy() {
    if (this.subscribeLoading) {
      this.subscribeLoading.unsubscribe();
    }
    if (this.subscribePanel) {
      this.subscribePanel.unsubscribe();
    }
  }

}
