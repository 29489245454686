<div *ngIf="backToBookmark" class="panel-header">

  <a  (click)="goToParents()" class="btn-icon">
    <span class="icon-arrow-left"></span>
  </a>
  <span id="legendList" class="title">
    {{bookamrkTitle}}
  </span>
</div>
<div *ngIf="isShow" class="w-scroll">
  <dx-scroll-view direction="vertical">
      <ul>
        <li *ngFor="let bookmark of userBookmarks" class="item-bookmark" [ngClass]="{'js-admin' : !bookmark.isSelected && !bookmark.userID , 'js-active' : bookmark.isSelected}" >
          <div *ngIf="bookmark.userID" class="item-bookmark__btn">
            <a *ngIf="!!!bookmark.parentID" (click)="addStory(bookmark)" class="btn-icon" title="Додати">
              <span class="esri-icon-plus-circled"></span>
            </a>
            <a (click)="editSettings(bookmark)" class="btn-icon" title="Налаштування">
              <span class="esri-icon-settings"></span>
            </a>
            <a (click)="removeBookamrk(bookmark)" class="btn-icon" title="Видалити">
              <span class="esri-icon-trash"></span>
            </a>
          </div>
          <button *ngIf="bookmark.isSelected && (bookmark.imageSource?.length>0 || bookmark.htmlText?.length>0)" class="btn-icon btn-icon--expansion " [ngClass]="{'js-expand' : bookmark.isExpanded}"  (click)="hideBookmarkContent(bookmark)">
            <span class="esri-icon-arrow-down-circled"></span>
          </button>
          <a class="item-bookmark__title" (click)="selectBookmark(bookmark)" title="{{bookmark.name}}"> {{bookmark.name}} </a>

          <div *ngIf="bookmark.isSelected && bookmark.isExpanded && (bookmark.imageSource?.length>0 || bookmark.htmlText?.length>0)" class="item-bookmark-content">
            <div *ngIf="bookmark.imageSource?.length>0" class="item-bookmark-content__gallery">
              <dx-gallery #gallery
                          id="gallery"
                          [dataSource]="bookmark.imageSource"
                          [width]="294"
                          [height]="220"
                          [loop]="true"
                          [showNavButtons]="bookmark.imageSource?.length>1"
                          [showIndicator]="bookmark.imageSource?.length>1"></dx-gallery>
            </div>
            <div class="item-bookmark-content__text" [innerHTML]="bookmark.htmlText"></div>
            <router-outlet></router-outlet>
          </div>
        </li>
      </ul>

  </dx-scroll-view>

</div>
<div *ngIf="!isShow" class="dx-empty-message">
  Нема закладок
</div>

