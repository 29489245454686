import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '../client/app.module';
import { environment } from '../environments/environment';
import * as Sentry from "@sentry/angular-ivy";

Sentry.init({
  dsn: "https://eefd9cc49dc73fb977e25f88f20d7433@o4505942746136576.ingest.us.sentry.io/4507106337816576",
  release: "1.0.4",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled /^http:\/\/localhost\//, 
  tracePropagationTargets: ["localhost", /^https:\/\/www\.webgis\.ua\//, /^https:\/\/webgis\.ua\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
Sentry.setTag("projectName", "webgis");
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
