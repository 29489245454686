import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";
import { UserLayer } from "../../models/userlayer/user-layer.model";
import { UserService } from "../../services/user/user.service";
import { CustomLayerService } from "./custom-layer.service";

import { LocationsEndpoint } from "../../services/locations/locations-endpoint.service";
import notify from 'devextreme/ui/notify';

@Injectable()
export class SketchViewService {

  constructor(private sharedService: SharedService, private esriService: EsriService,
    private userService: UserService, private customLayerService: CustomLayerService,
    private locationsEndpoint: LocationsEndpoint) {
    
  }


  editGraphic: any;
  graphicsLayer: any
  sketchViewModel: any;
  isEdit: boolean;
  listGraphic: any[] = new Array<any>();
  lastObjectID: number = 1;
  jsonList: any;

  userLayer: UserLayer;
  tempResult: any;

  public polygonSymbol: any;//__esri.SimpleFillSymbol;
  public polylineSymbol: any;//__esri.SimpleLineSymbol;
  public pointSymbol: any;// __esri.SimpleMarkerSymbol;

  getSketchViewModel() {

    this.graphicsLayer = new this.esriService.GraphicsLayer({
      id: "customLayerID"
    });

    this.sharedService.map.layers.add(this.graphicsLayer);
  }

  collectGraphics(refresh?: boolean) {
    // JSON.parse(result.data);
    // JSON.stringify(obj)
    let listGraphic = new Array<any>();
    let _graphicLayer = this.sharedService.mapView.layerViews.find(x => x.layer.id == 'customLayerID').layer;
    (_graphicLayer as __esri.GraphicsLayer).graphics.forEach(graphic => {
      let n = graphic.toJSON();
      listGraphic.push(n);
    });
    this.jsonList = JSON.stringify(listGraphic);
    this.userLayer.layerData = this.jsonList;
    this.customLayerService.getSaveLayerData(this.userLayer).subscribe(result => {
      this.tempResult = result;
      if (refresh) {
        this.setGraphics();
      }
    });
    //(_graphicLayer as __esri.GraphicsLayer).graphics.removeAll();
  }

  setGraphics() {
    this.customLayerService.getCustomLayerData(this.sharedService.getRegionIDValue()).subscribe(result => {
      if (result) {
        if (result.length > 0) {
          this.userLayer = result[0];
          this.jsonList = this.userLayer.layerData;
        } else {
          this.userLayer = new UserLayer();
          this.userLayer.layerData = this.jsonList
          this.userLayer.name = "Власні об'єкти";
          this.userLayer.regionId = this.sharedService.getRegionIDValue();
          this.userLayer.userId = this.userService.User.id;
        }
      }
      if (this.jsonList) {
        let _graphicLayer = this.sharedService.mapView.layerViews.find(x => x.layer.id == 'customLayerID').layer;
        (_graphicLayer as __esri.GraphicsLayer).graphics.removeAll();
        let listGraphic: any[] = JSON.parse(this.jsonList);
        listGraphic.forEach(x => {
          let graphic = this.esriService.Graphic.fromJSON(x);
          if (graphic.symbol.style == "x" || graphic.symbol.style == "cross") {
            graphic.symbol.color = graphic.symbol.outline.color;
          }
          let popopCustomContent = '<ul class="esri-popup__list">' +
            `<li><span class=esri-popup__text-secondary>Назва</span> : <span class="esri-popup__text-primary">{Name}</span></li>` +
            `<li><span class=esri-popup__text-secondary>Опис</span> : <span class="esri-popup__text-primary">{Description}</span></li>` +
            `<li><span class=esri-popup__text-secondary>Площа м</span><sup>2</sup> : <span class="esri-popup__text-primary">{Square}</span></li>` +
            `<li><span class=esri-popup__text-secondary>Довжина м</span> : <span class="esri-popup__text-primary">{Length}</span></li>` +
            `<li><span class=esri-popup__text-secondary>Довгота</span> : <span class="esri-popup__text-primary">{Longitude}</span></li>` +
            `<li><span class=esri-popup__text-secondary>Широта</span> : <span class="esri-popup__text-primary">{Latitude}</span></li>` +
            '</ul>';
          let popupTemplate = {
            title: "Власні об'єкти", content: popopCustomContent,
            actions: [{
              id: "edit-geodata",
              className: "esri-icon-edit",
              title: "Редагувати"
            },
            {
              id: "view-geodata",
              className: "esri-icon-description",
              title: "Інфо"
              },
              {
                id: "paint-geodata",
                className: "esri-icon-expand2",
                title: "Змінити"
              },
            {
              id: "delete-geodata",
              className: "esri-icon-trash",
              title: "Вилучити"
            }
            ]
          };
          graphic.popupTemplate = popupTemplate;
          (_graphicLayer as __esri.GraphicsLayer).graphics.add(graphic);
        })
      }
    }
    );
  }

  drawPoint() {
    if (this.pointSymbol) {
      let pointSymbol = this.pointSymbol;
      switch (this.pointSymbol.style) {
        case "cross":
          this.sketchViewModel.pointSymbol = this.createPointSymbol(pointSymbol.style, pointSymbol.color, pointSymbol.size, pointSymbol.color);
        case "x":          
          this.sketchViewModel.pointSymbol = this.createPointSymbol(pointSymbol.style, pointSymbol.color, pointSymbol.size, pointSymbol.color);
          break;
        case "circle":
        case "square":
        case "diamond":
        default:
          this.sketchViewModel.pointSymbol = this.createPointSymbol(pointSymbol.style, pointSymbol.color, pointSymbol.size);          
          break;
      }      
    } else {
      this.sketchViewModel.pointSymbol = {
        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        style: "square",
        color: [20, 146, 220, 1],
        size: "10px",
        outline: { // autocasts as new SimpleLineSymbol()
          color: [20, 146, 220, 1],
          width: 2
        }
      }
    }
          
    this.sketchViewModel.create("point");
  }
  drawPolyline() {
    if (this.polylineSymbol) {
      let polylineSymbol = {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: this.polylineSymbol.color,
        width: this.polylineSymbol.width,
        style: this.polylineSymbol.style,
        cap: "round",
        join: "round"
      };
      this.sketchViewModel.polylineSymbol = polylineSymbol;
    } else {
      this.sketchViewModel.polylineSymbol = {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
          color: [20, 146, 220],
            width: "2",
              cap: "round",
                join: "round"
      }
    }
    this.sketchViewModel.create("polyline");
  }
  drawPolygon() {
    if (this.polygonSymbol) {
      let polylineSymbol = {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: this.polygonSymbol.color,
        width: "2",
        style: "solid",
        cap: "round",
        join: "round"
      };
      this.sketchViewModel.polylineSymbol = polylineSymbol;
      let polygonSymbol = {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: this.polygonSymbol.color,
        style: "solid",
        outline: {
          color: this.polygonSymbol.color,
          width: 2
        }
      };

      this.sketchViewModel.polygonSymbol = polygonSymbol;
    } else {
      this.sketchViewModel.polylineSymbol = {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: [20, 146, 220],
        width: "2",
        cap: "round",
        join: "round"
      }

      this.sketchViewModel.polygonSymbol = {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [20, 146, 220, 0.26],
        style: "solid",
        outline: {
          color: [20, 146, 220],
          width: 2
        }
      };
    }
    this.sketchViewModel.create("polygon");
  }

  setEditStatus(value) {
    this.isEdit = value;
    if (!value) {
      if (this.sketchViewModel.state == "active" || this.sketchViewModel.state == "ready"
      ) {
        this.sketchViewModel.complete();
      }
      let _graphicLayer = this.sharedService.mapView.layerViews.find(x => x.layer.id == 'customLayerID').layer;
      (_graphicLayer as __esri.FeatureLayer).popupEnabled = true;
      this.sketchViewModel.updateOnGraphicClick = false;
      this.sharedService.setIsEditCustomLayer(false);
      notify(`Режим редагування вимкнено`, "warning", 3000);
      //this.collectGraphics();
    } else {
      this.sharedService.setIsCheckedCustomLayer(true);
      this.sketchViewModel.updateOnGraphicClick = true;
      this.sharedService.setIsEditCustomLayer(true);
      let _graphicLayer = this.sharedService.mapView.layerViews.find(x => x.layer.id == 'customLayerID').layer;
      (_graphicLayer as __esri.FeatureLayer).popupEnabled = false;
      notify(`Режим редагування увімкнено`, "success", 3000);
      if (this.sharedService.mapView.popup.visible) {
        this.sharedService.mapView.popup.close();
      }
      //this.setGraphics();
    }
  }

  getNewID() {
    let result = 1;
    if ((this.graphicsLayer as __esri.GraphicsLayer).graphics.length > 0) {
      this.graphicsLayer.graphics.sort((a, b) => {
        if (a.attributes) {
          if (a.attributes.OBJECTID < b.attributes.OBJECTID) return 1;
          if (a.attributes.OBJECTID > b.attributes.OBJECTID) return -1;
          if (a.attributes.OBJECTID = b.attributes.OBJECTID) return 0;
        } else {
          return -1;
        }        
      });
      if ((this.graphicsLayer as __esri.GraphicsLayer).graphics.length > 1) {
        let lastItem = (this.graphicsLayer as __esri.GraphicsLayer).graphics.getItemAt(1);
        result = lastItem.attributes.OBJECTID;
        result = ++result;
      }
      
    }


    return result;
    //this.lastObjectID += this.lastObjectID;
    //return this.lastObjectID;
  }

  checkCustomLayer() {
    this.customLayerService.getCustomLayerData(this.sharedService.getRegionIDValue()).subscribe(result => {
      if (result) {
        if (result.length > 0) {
          //this.userLayer = result[0];
          //this.jsonList = this.userLayer.layerData;
          this.sharedService.setIsShowedCustomLayer(true);
        } else {
          //this.userLayer = new UserLayer();
          //this.userLayer.layerData = this.jsonList
          //this.userLayer.name = "Власні об'екти";
          //this.userLayer.regionId = this.sharedService.getRegionIDValue();
          //this.userLayer.userId = this.userService.User.id;
        }
      }
      //if (this.jsonList) {
      //  let _graphicLayer = this.sharedService.mapView.layerViews.find(x => x.layer.id == 'customLayerID').layer;
      //  (_graphicLayer as __esri.GraphicsLayer).graphics.removeAll();
      //  let listGraphic: any[] = JSON.parse(this.jsonList);
      //  listGraphic.forEach(x => {
      //    let graphic = this.esriService.Graphic.fromJSON(x);
      //    (_graphicLayer as __esri.GraphicsLayer).graphics.add(graphic);
      //  })
      //}
    }
    );
  }

  removeGraphics() {
    if (this.sharedService.mapView) {
      let layerView = this.sharedService.mapView.layerViews.find(x => x.layer.id == 'customLayerID');
      if (layerView && layerView.layer) {
        (layerView.layer as __esri.GraphicsLayer).graphics.removeAll();
      }
      this.jsonList = null;
    }

  }

  cancelDraw() {

    if (this.sketchViewModel && (this.sketchViewModel.state == "ready" || this.sketchViewModel.state == "updating" || this.sketchViewModel.state == "creating")) {
       this.isEdit = false;       
      
      //(this.sketchViewModel as __esri.SketchViewModel).cancel();// reset();
      
     }
     
  }

  createPointSymbol(style, color, size, outlineColor?) {
    let pointSymbol = new this.esriService.SimpleMarkerSymbol();// __esri.SimpleMarkerSymbol();
    pointSymbol.style = style;
    pointSymbol.color = color;
    
    pointSymbol.size = size;
    if (outlineColor) {
      pointSymbol.outline = new this.esriService.SimpleLineSymbol({
        color: outlineColor,
        width : 2
      });
    }
    return pointSymbol;
  }

  createLineSymbol(style, color, size) {
    let lineSymbol = new this.esriService.SimpleLineSymbol();
    lineSymbol.style = style;
    lineSymbol.color = color;
    lineSymbol.width = size;
    return lineSymbol;
  }

  createPolygonSymbol(color) {
    let polygonSymbol = {
      type: "simple-fill", // autocasts as new SimpleFillSymbol()
      color: color,
      style: "solid",
      outline: {
        color: color,
        width: 2
      }
    };
    return polygonSymbol;
  }
}
