import { Injectable } from '@angular/core';
import { LegendsService } from "../legends/legends.service";
import { LayersService } from "../layers/layers.service";
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { Attributes } from "../../models/layers/attributes";
import { ConfigService } from "../shared/utils/config.service";
import { EsriService } from "../esri/js-esri.service";
import { GalleryMapService } from "../gallerymap/gallerymap.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../user/user.service";

import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { SquareUnits } from '../../models/draw/square-units.model';
import { Layer } from '../../models/layers/layer.model';
import { AzureStorageService } from '../azurestorage/azure-storage.service';
import { asap } from 'rxjs';
import { LayerDataAttribute } from '../../models/layers/layer-data-attribute.models';
import { MapLayersService } from '../map/map.layers.service';
import { LegendItem } from '../../models/layers/legend.model';
import { layer } from 'esri/views/3d/support/LayerPerformanceInfo';


@Injectable()
export class NavigationService {
  constructor(      
    private esriService: EsriService,
    private sharedService: SharedService,        
    private configService: ConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mapLayersService: MapLayersService,
    private legendsService: LegendsService
  ) {
    
  }

  async getNavigationList(id, layerID, layerDataGuid, subtypeid?) {

    let legends = this.sharedService.getLegendsValue()
    let legend = subtypeid ? legends.find(x => x.layerGuid == layerDataGuid && x.id == id && x.defaultValues.find(v => v == subtypeid)) :
      legends.find(x => x.layerGuid == layerDataGuid && x.id == id);
    
    let map = this.sharedService.map as __esri.Map
    let _layer = map.findLayerById(layerID) ;
    if (_layer && (_layer as __esri.MapImageLayer).sublayers && (_layer as __esri.MapImageLayer).sublayers.length > 0) {
      let subLayer = (_layer as __esri.MapImageLayer).sublayers.find(l => l.id == id);
      let attr = subLayer.get<LayerDataAttribute[]>("layerAttributes");
      let subQuery = '';
      await this.mapLayersService.getLayerInfo(legend.layerURL + "/" + legend.id).then(layerInfo => {

        let subtypeFieldName = layerInfo.subtypeFieldName;// subLayer.get<string>("subtypeFieldName");
        let filterKOATUU = legend.filterKOATUU ? legend.filterKOATUU : '';
        let filterSUbType = subtypeFieldName ? subtypeFieldName + "=" + subtypeid : '';
        subQuery = (filterKOATUU ? filterKOATUU : '') + (filterSUbType ? (filterKOATUU ? ' and ' : '') + filterSUbType : '');
        if (legend.filterGroupType && legend.filterGroupType.length > 0) {
          let allGroupFilters = "";
          legend.filterGroupType.forEach(attr => {
            let groupFilter = "";
            attr.group.forEach(g => {
              if (groupFilter.length > 0) {
                groupFilter += ',';
              }
              groupFilter += `N'${g.value}'`;
            })

            if (groupFilter.length > 0) {
              if (allGroupFilters.length > 0) {
                allGroupFilters = allGroupFilters + " and "
              }
              allGroupFilters = attr.name + " in ( " + groupFilter + ")";
            }
          })
          if (allGroupFilters.length > 0) {
            if (subQuery.length > 0) {
              subQuery = subQuery + " and ";
            }
            subQuery = subQuery + allGroupFilters;
          }
        }

        if (legend.layerFilterExpression) {
          subQuery = subQuery ? `(${subQuery}) and ` : '';
          subQuery = `(${subQuery} ${legend.layerFilterExpression})`;
          //subQuery = `((${subQuery}) and ${legend.layerFilterExpression})`
        }

        if (subQuery.length > 0) {
          subQuery = ' and ' + subQuery;
        }
      })

      
      let featureUrl: string = subLayer.url;
      let _url = featureUrl + "?token=" + this.configService.getGisToken();
     
      var query: __esri.Query = new this.esriService.Query();
      query.returnGeometry = true;
      query.where = '1=1 ' + subQuery;
      query.outFields = this.getAttributes(attr)
      query.returnGeometry = true;
      var _self = this;
      let featureLayer: __esri.FeatureLayer = new _self.esriService.FeatureLayer();

      featureLayer.url = featureUrl.replace('MapServer', 'FeatureServer');

      featureLayer.outFields = _self.getAttributes(attr);
      featureLayer.popupTemplate = new _self.esriService.PopupTemplate();
      let result = await featureLayer.queryFeatures(query).then(res => {
        return res;
      })
      featureLayer.visible = false;
      legend.totalCount = result.features.length;           
      let _set = {
        featureSet: result,
        featureLayer: featureLayer,
        subLayer: subLayer,
        legend: legend
      }
      return _set;
     
    }
  }

  async getNavigationListByLegend(legend: LegendItem) {
    //let id = parseInt(legend.id);
    let layerID = legend.layerID;
    //let layerDataGuid;
    //let subtypeid;
    //let legends = this.sharedService.getLegendsValue()
    //let legend = subtypeid ? legends.find(x => x.layerGuid == layerDataGuid && x.id == id && x.defaultValues.find(v => v == subtypeid)) :
    //  legends.find(x => x.layerGuid == layerDataGuid && x.id == id);

    let map = this.sharedService.map as __esri.Map
    let _layer = map.findLayerById(layerID);
    let subLayer: __esri.Sublayer;
    let attr;
    if (legend.layerType == 'map-image' && _layer && (_layer as __esri.MapImageLayer).sublayers && (_layer as __esri.MapImageLayer).sublayers.length > 0) {
      subLayer = (_layer as __esri.MapImageLayer).sublayers.find(l => l.id == parseInt(legend.id));
      attr = subLayer.get<LayerDataAttribute[]>("layerAttributes");

      //let options: __esri.RequestOptions =
      //{
      //  query: 'token=' + this.configService.getGisToken()
      //};      
    } else if (legend.layerType == 'feature') {
      attr = _layer.get<LayerDataAttribute[]>("layerAttributes");
    }
    let subQuery = this.legendsService.getSubQuery(legend);
    let featureUrl: string = legend.layerType == 'map-image' ? subLayer.url : ((_layer as __esri.FeatureLayer).url + '/' + legend.id);
    let _url = featureUrl + "?token=" + this.configService.getGisToken();    
    var query: __esri.Query = new this.esriService.Query();
    query.returnGeometry = true;
    query.where = '1=1 ' + (subQuery ? (' and ' + subQuery) : '');
    query.outFields = this.getAttributes(attr)
    query.returnGeometry = true;
    var _self = this;
    let featureLayer: __esri.FeatureLayer = new _self.esriService.FeatureLayer();

    featureLayer.url = featureUrl.replace('MapServer', 'FeatureServer');

    featureLayer.outFields = _self.getAttributes(attr);
    featureLayer.popupTemplate = new _self.esriService.PopupTemplate();
    let result = await featureLayer.queryFeatures(query).then(res => {
      return res;
    })
    featureLayer.visible = false;
    if (!!!legend.legendGUID && !!!legend.totalCount) {
      legend.totalCount = result.features.length;
    }    
    let _set = {
      featureSet: result,
      featureLayer: featureLayer,
      subLayer: (subLayer ?? _layer as __esri.FeatureLayer),
      legend: legend
    }
    return _set;
  }

  //private createFeatureLayer(_url, x) {
  //  let featureLayer = new this.esriService.FeatureLayer({
  //    url: _url,
  //  });
  //  (featureLayer as __esri.FeatureLayer).set<boolean>("isNavigationLayer", true);
  //  (featureLayer as __esri.FeatureLayer).set<string>("LayerDataGUID", x.id);
  //  (featureLayer as __esri.FeatureLayer).set<string>("name", x.name); // isSearchLayer
  //  let editable = x.layerAttributes.find(x => x.editable);
  //  if (editable)
  //    (featureLayer as __esri.FeatureLayer).set<boolean>("editable", true);
  //  else
  //    (featureLayer as __esri.FeatureLayer).set<boolean>("editable", false);
  //  let showed = x.layerAttributes.find(x => x.showed);

  //  if (showed) {
  //    (featureLayer as __esri.FeatureLayer).set<boolean>("showed", true);
  //  } else {
  //    (featureLayer as __esri.FeatureLayer).set<boolean>("showed", false);
  //  }
  //  (featureLayer as __esri.FeatureLayer).set<boolean>("canAddObject", x.canAddObject);
  //  (featureLayer as __esri.FeatureLayer).set<any>("layerAttributes", x.layerAttributes);
  //  //(featureLayer as __esri.FeatureLayer).
  //  if (x.layerAttributes.find(a => a.hasFilter)) {
  //    let filter = '';
  //    x.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
  //      if (filter.length > 0) {
  //        filter = filter + ' and ';
  //      }
      
  //    })

  //    featureLayer.definitionExpression = filter;
  //  }
  //  return featureLayer;
  //}


  public getAttributes(attributes: LayerDataAttribute[]): any[] {

    let result = [];
    let objAdded: boolean = false;
    attributes.filter(f => f.inMini).forEach(x => {
      if (x.name.toUpperCase() == "OBJECTID") {
        objAdded = true;
      }
      result.push(x.name);
    })
    if (!objAdded) {
      result.push("OBJECTID");
    }
    return result;
  }

}


