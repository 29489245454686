import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit,  } from '@angular/core';
import { LayersService } from "../../services/layers/layers.service";
import { MapLayersService } from "../../services/map/map.layers.service";
import notify from 'devextreme/ui/notify';
import { SharedService } from "../../services/shared/shared.service";
import { UserService } from "../../services/user/user.service";
import { Router, ActivatedRoute, Params} from '@angular/router';
import { Subscription } from "rxjs";
import { BookmarksService } from '../../services/bookmarks/bookmarks.service';
import { GalleryMapService } from '../../services/gallerymap/gallerymap.service';
import { PublicLegendsComponent } from '../../components/publiclegends/public.legends.component';
import { HomebtnService } from '../../services/homebtn/homebtn.service';
import { SearchService } from '../../services/search/search.service';
import { FeatureLayerService } from '../../services/map/feature-layer.service';
import { PublicBookmark } from '../../models/bookmarks/bookmark.model';
import { Layer } from '../../models/layers/layer.model';
import {
  DxTabsComponent
} from 'devextreme-angular';
import { BookmarksStoryComponent } from '../bookmarksstory/bookmarks-story.component';
import { FiltersService } from '../../services/filters/filters.service';

@Component({
  selector: 'app-bookmarkregion',
  templateUrl: './bookmark.region.component.html',
  styleUrls: ['./bookmark.region.component.scss']
})
export class BookmarkRegionComponent implements OnInit, OnDestroy { 

  constructor(
    private layerService: LayersService,
    //private mapLayersService: MapLayersService,
    private sharedService: SharedService,
    private user: UserService, private router: Router,
    private activatedRoute: ActivatedRoute,
    private featureLayerService: FeatureLayerService,
    private galleryMapService: GalleryMapService,
    private homeButtonService: HomebtnService,
    private searchService: SearchService,
    private bookmarksService: BookmarksService,
    private mapLayersService: MapLayersService,
    private filtersService: FiltersService
  ) { }

  subscription: Subscription;
  isStarted: boolean = false;
  enableLayers: boolean = false;
  isGroup: boolean = false; // TO DO remove old tabs
  subscriptionParam: Subscription;
  subscriptionQueryParam: Subscription;
  bookmarkId: any;
  groupIndex: number;
  showLegend: boolean = false;
  showStory: boolean = false;
  storySubscription: Subscription;
  subscriptionBookmarkChanged: Subscription;

  @ViewChild(PublicLegendsComponent, { static: false }) legend: PublicLegendsComponent;
  @ViewChild(DxTabsComponent) tabsComponent;
  @ViewChild(BookmarksStoryComponent, { static: false }) bookmarkStory: BookmarksStoryComponent;

  ngOnInit() {
    this.sharedService.isShowBookmarkApp = true;
    this.subscription = this.sharedService.getStartedDrawing().subscribe(value => {
      this.isStarted = value;
    });
    this.sharedService.setShowBookmark(true);
    this.subscriptionParam = this.activatedRoute.params.subscribe((params: Params) => {
      let existsBookmark: boolean = false;
      if (this.sharedService.PublicBookmarkID) {
        this.bookmarkId = this.sharedService.PublicBookmarkID;
        existsBookmark = true;
        this.subscribeBookmarkChanged();
      } else {
        this.bookmarkId = params['bookmarkid'];
        this.sharedService.PublicBookmarkID = this.bookmarkId;
        this.sharedService.setBookmarkChanged(this.bookmarkId);
      }
      
      this.subscriptionQueryParam = this.activatedRoute.queryParams.subscribe((queryParam: any) => {
        let _group = queryParam['group'];
        if (_group) {
         // this.isGroup = true;
          this.sharedService.publicGroup = true;
          this.sharedService.setShowGroupBookmark(true);
        }
        //if (this.sharedService.publicGroup && this.sharedService.groupBookmarks) {
        //  this.groupIndex = this.groupBookmarks.findIndex(x => x.id == this.bookmarkId);
        //}
      })
      let legendValue = this.sharedService.publicLegends;// getLegendsValue();

      if (this.bookmarkId && (!legendValue || legendValue.length <= 0)) {
        //error on redirect from PublicBoomarkListComponent
        this.removeLayers(this.bookmarkId);
        this.getPublicBookmarkData(this.bookmarkId, (regionId) => { this.initPublicGroup(regionId) });
        
      }
      else if (existsBookmark) {
        this.showLegend = true;
      }
      else if (!this.bookmarkId) {
        notify(`Регіон не знайдено.\r\n `, "error", 3000);
      }

    });

  }

  ngOnDestroy() {
    this.sharedService.isShowBookmarkApp = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscriptionParam) {
      this.subscriptionParam.unsubscribe();
    }

    if (this.subscriptionQueryParam) {
      this.subscriptionQueryParam.unsubscribe();
    }
    if (this.storySubscription) {
      this.storySubscription.unsubscribe();
    }
    this.subscriptionBookmarkChanged?.unsubscribe();
  }

  GoTo(latitude, longitude, _zoom ) {
    let opts = {
      duration: 500
    };
    if (latitude && longitude)
      this.sharedService.mapView.goTo({ center: [longitude, latitude], zoom: _zoom }, opts).then(() => {
        this.homeButtonService.reInit();
      })
  }

  navigateToBookmark(id) {
    this.bookmarkId = id;
    this.sharedService.PublicBookmarkID = id;
    this.sharedService.publicLegends = [];
    this.galleryMapService.clearBasemaps();
    let layers = this.sharedService.getLayerList().getValue();
    layers.forEach(result => {
      this.hideLayers(result.layerChildren);
      if (result.isSelected) {
        result.isSelected = false;
        this.mapLayersService.HideLayerDataByLayerIDEx(result.id);

      }
    });
    this.sharedService.setLayers(layers);
    this.mapLayersService.RemoveAllLayers();
    //this.sharedService.setBookmarkChanged(this.bookmarkId);

    this.getPublicBookmarkData(this.bookmarkId);    

  }

  private getPublicBookmarkData(bookmarkId: string, initPublicGroup?: (regionID: string) => any) {
    this.bookmarksService.getPublicBookmarkData(bookmarkId).then(result => {
      if (result) {
        this.sharedService.CurrentRegionCode = result.code;
        if (result.filterID) {
          this.featureLayerService.registerToken(result);
          this.filtersService.showFeatureByFilter(result.filter);
          this.searchService.InitPublicSearch(result.filter.layers.map(val => {
            return val.layerAttributes;
          }));
        } else {
          this.featureLayerService.showPublicBookmarkByData(result);
          this.searchService.InitPublicSearch(result.layers);
        }
        

        //this.filtersService.selectFilter(result.filter.id);
        
        //this.sharedService.setPublicLegendsTitle(result.name);
        this.showStory = result.isStory;
        this.showLegend = !this.showStory;
        if (this.showLegend) {
          //this.legend.regionTitle = result.name;
          this.sharedService.setPublicLegendsTitle(result.name);
          if (this.legend) {
            this.legend.legends = this.sharedService.publicLegends;
          }
        }
        let parentID = result.parentID ? result.parentID : result.id;

        /////////////////////////////
        
        if (this.showStory && this.sharedService.ParentStoryBookmarkID != parentID) {
          this.sharedService.ParentStoryBookmarkID = parentID;
          this.bookmarksService.getListByParentBookmark(parentID).then(listBookmarks => {
            
            this.bookmarkStory.UserBookmarks = listBookmarks;
            let selectedBookmark = listBookmarks.find(x => x.id == result.id);
            this.bookmarkStory.setSelectedBookmark(selectedBookmark);
            //if (this.storySubscription) {

            //} else {
              this.storySubscription = this.bookmarkStory.changeEvent.subscribe(val => {
                this.navigateToBookmark(val.id);
              });
            //}

          })
          if (parentID == result.id) {
            this.sharedService.setPublicStoryTitle(result.name);
            //this.bookmarkStory.bookamrkTitle = result.name;
          } else if (this.showStory) {
            this.bookmarksService.getPublicBookmarkData(parentID).then(val => {
              if (val) {
                //this.bookmarkStory.bookamrkTitle = val.name;
                this.sharedService.setPublicStoryTitle(val.name);
              }
            })
          }

        }
        if (initPublicGroup) {
          this.sharedService.PublicBookmarkGroupID = parentID;
          initPublicGroup(result.regionID); // this.initPublicGroup(result.regionID);
        }
        
        /////////////////////////////
        if (result.imageServiceID) {
          let localStore = (this.galleryMapService.galleryMap.source as __esri.LocalBasemapsSource);
          let baseMap = localStore.basemaps.find(x => x.get<string>("ImageServiceGUID") == result.imageServiceID);
          if (baseMap) {
            this.galleryMapService.selectBaseMap(result.imageServiceID);
          } else {
            this.galleryMapService.addPublicMap(result.imageServiceID).then(x => {
              this.galleryMapService.selectBaseMap(result.imageServiceID);

            });
          }
        }
        this.GoTo(result.latitude, result.longitude, result.scale);

      } else {
        notify(`Регіон не знайдено.\r\n `, "error", 3000);
      }
    }).catch(err => { notify(`Помилка сервера : ${err}.\r\n `, "error", 3000); });
  }

  private initPublicGroup(regionID: string) {
    if (this.sharedService.publicGroup) {
      this.bookmarksService.getPublicBookmarksByRegion(regionID).then(b => {
        //this.groupBookmarks = b;
        //this.sharedService.groupBookmarks = b;

        this.sharedService.setPublicBookmarkGroup(b);
        this.subscribeBookmarkChanged();
        

      }).catch(ex => {
        notify(`getPublicBookmarksByRegion : ${ex}.\r\n `, "error", 3000);
      })
    }
  }

  hideLayers(layers: Layer[]) {
    layers.forEach(_layer => {
      this.hideLayers(_layer.layerChildren);
      if (_layer.isSelected) {
        _layer.isSelected = false;
        this.mapLayersService.HideLayerDataByLayerIDEx(_layer.id);

      }
    })
  }



  private subscribeBookmarkChanged() {
    let firstCall: boolean = true;
    this.subscriptionBookmarkChanged = this.sharedService.getBookmarkChanged().subscribe(id => {
      if (!firstCall) {
        this.storySubscription?.unsubscribe();
        this.showStory = false;
        this.showLegend = false;
        this.navigateToBookmark(id); // it adds additional call at first time
      }
      firstCall = false;
    })
  }

  private removeLayers(id) {
    //this.bookmarkId = id;
    //this.sharedService.PublicBookmarkID = id;
    //this.sharedService.publicLegends = [];
    this.galleryMapService.clearBasemaps();
    let layers = this.sharedService.getLayerList().getValue();
    layers.forEach(result => {
      this.hideLayers(result.layerChildren);
      if (result.isSelected) {
        result.isSelected = false;
        this.mapLayersService.HideLayerDataByLayerIDEx(result.id);

      }
    });
    this.sharedService.setLayers(layers);
    this.mapLayersService.RemoveAllLayers();
  }
}
