<div class="esri-ui-bottom-right esri-ui-corner">
  <div (click)="printMap()" class="esri-component esri-widget--button" [ngClass]="{'js-btn-active': isShowed}" title="Друк">
    <span class="esri-icon-printer"></span>
  </div>
</div>
<div [hidden]="!isShowed">
  <div class="esri-ui-top-right esri-ui-corner">
    <div id="print-map" class="eesri-component esri-print esri-widget esri-widget--panel print-map">

    </div>
  </div>
</div>
