import { Component, OnInit, ViewChild } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { SharedService } from "../../services/shared/shared.service";
import {
  DxDropDownBoxComponent
} from 'devextreme-angular';
import { PublicBookmark } from '../../models/bookmarks/bookmark.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-publicbookmarklist',
  templateUrl: './public-bookmark-list.component.html',
  styleUrls: ['./public-bookmark-list.component.css']
})
export class PublicBoomarkListComponent implements OnInit {

  publicBookmarks: PublicBookmark[];
  publicBookmarksIds: any[] = [];
  @ViewChild(DxDropDownBoxComponent) listBox;

  constructor(
    private sharedService: SharedService, private router: Router,
  )
    {
     
  }

  ngOnInit() {
    this.sharedService.getPublicBookmarkGroup().subscribe(bookmarks => {
      this.publicBookmarks = bookmarks;
      if (bookmarks && this.sharedService.PublicBookmarkGroupID) {
        this.publicBookmarksIds = [this.sharedService.PublicBookmarkGroupID];
      }
      
    })    
  }
  

  onSelectionChanged(e) {
    let _addedItem = e.addedItems;
    let _ids: any[] = _addedItem.map(x => x.id);
    if (_ids.length > 0) {
      this.sharedService.ParentStoryBookmarkID = null;
      //this.sharedService.setPublicLegendsTitle(e.addedItems[0].name);
      //this.sharedService.publicLegends = [];
      //
      if (!this.sharedService.isShowBookmarkApp) {
        this.sharedService.PublicBookmarkID = null;
        this.sharedService.publicLegends = []
        this.router.navigate(['map', _ids[0] ], { queryParams: { group: '1' } });
      } else {
        this.sharedService.setBookmarkChanged(_ids[0]);
      }
      
    }

    this.listBox.instance.close();
  }
}
