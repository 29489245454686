import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { MapLayersService } from "../map/map.layers.service";
import { EsriService } from "../esri/js-esri.service";
import { ConfigService } from "../shared/utils/config.service";
import notify from 'devextreme/ui/notify';
import { LoadingMapService } from "../loading/loading.service";



@Injectable()
export class CadastreService {

  popupWatchHandler: __esri.WatchHandle;
  zoomWatchHandler: __esri.WatchHandle;
  isMessageShowed: boolean = false;
  cadastreLayerView: any;
  constructor(private sharedService: SharedService,
    private esriService: EsriService,
    private mapLayerService: MapLayersService,
    private configService: ConfigService,
    private loadingMapService: LoadingMapService) {
    
    
  }

  hideCadastreMap() {
    let layer = (this.sharedService.map as __esri.Map).layers.find(x => x.get<boolean>("IsCadastre"));
    if (layer) {
      if (this.popupWatchHandler)
        this.popupWatchHandler.remove();
      this.zoomWatchHandler.remove();
      (this.sharedService.map as __esri.Map).layers.remove(layer);
      this.removeCadastreGraphicLayer();
      this.sharedService.IsShowedCadastre = false;
    }
  }
  getCadastreObject(target): IPromise<any> {
    let result = null;
    let mapPoint = (target.graphic.layer as __esri.Layer).get<any>('cadastreMapPoint');
    let mapView: __esri.MapView = (target.graphic as __esri.Graphic).get<any>('MapView')
    if (!mapView) {
      mapView =(target.graphic.layer as __esri.Layer).get<any>('MapView');
    }
    let formdata = new FormData();

    formdata.append("x", mapPoint.y);
    formdata.append("y", mapPoint.x);
    formdata.append("actLayers[]", "kadastr");
    formdata.append("zoom", (mapView.zoom > 17 ? 17 : mapView.zoom).toString());


    let options: __esri.RequestOptions =
      {
        method: 'post',
      body: formdata 
      };
   
    result = new Promise<any>((resolve, reject) => {

      var _esri = (target.graphic as __esri.Graphic).get<any>('Esri') ;
      if (!_esri) {
        _esri = (target.graphic.layer as __esri.Layer).get<any>('Esri') ;
      }

      var getInfoUrl = (target.graphic as __esri.Graphic).get<any>('getInfoUrl');
      if (!getInfoUrl) {
        getInfoUrl = (target.graphic.layer as __esri.Layer).get<any>('getInfoUrl');
      }

      _esri.Request(getInfoUrl, options).then(function (resultService) {
        if (resultService) {
          let _result = '';
          if (resultService.data.parcel) {
            let parcel = resultService.data.parcel[0];

            _result = '<ul>';
            _result += `<li>Кадастровий номер : ${parcel.cadnum}`;
            _result += `<li>Тип власності : ${parcel.ownership}`;
            _result += `<li>Цільове призначення : ${parcel.purpose}`;
            _result += `<li>Площа : ${parcel.area} ${parcel.unit_area}`;
            _result += `<li>КОАТУУ : ${parcel.koatuu}`;
            _result += '</ul>';
          } else if (resultService.data.ikk) {
            let ikk = resultService.data.ikk;
            _result = '<ul>';
            _result += `<li>Район : ${ikk.district}`;
            _result += `<li>Зона : ${ikk.zona}`;
            _result += `<li>Квартал : ${ikk.kvart}`;
            _result += `<li>КОАТУУ : ${ikk.koatuu}`;
            _result += '</ul>';
          } else if (resultService.data.district) {
            let district = resultService.data.district;
            _result = '<ul>';
            _result += `<li>Область : ${district.natoobl}`;
            _result += `<li>Район : ${district.natoray}`;
            _result += `<li>КОАТУУ : ${district.koatuu}`;
            _result += '</ul>';
          } else if (resultService.data.region) {
            let region = resultService.data.region;
            _result = '<ul>';
            _result += `<li>Область : ${region.natoobl}`;
            _result += `<li>КОАТУУ : ${region.koatuu}`;
            _result += '</ul>';
          }
          resolve(_result);
        } else {
          resolve('Нема даних');
        }
      }).catch(function (error) {
        console.log("Cadastre object is not loaded: ", error.message);
        resolve('Сервіс недоступний');
      });

    });


    return result;
  }


  watchPopupUpdating() {
    let popup = this.sharedService.mapView.popup;
    var self = this;
    let handler = popup.watch("visible", function (value) {
      if (!value) {
       //console.log('popup visible is changed false');                  
          let graphicLayer = (self.sharedService.map as __esri.Map).layers.find(x => x.get<boolean>('cadastreGraphicLayer'));
          let item = (graphicLayer as __esri.FeatureLayer).source.getItemAt(0);
          (graphicLayer as __esri.FeatureLayer).source.remove(item);
          let newItem: __esri.Graphic = new self.esriService.Graphic({
            geometry: self.sharedService.mapView.extent,
          });

          newItem.set<boolean>('IsCadastreItem', true);
          newItem.set<any>('Esri', self.esriService);
          newItem.set<any>('MapView', self.sharedService.mapView);
          (graphicLayer as __esri.FeatureLayer).source.add(newItem);
         // console.log('Popup graphic object is recreated');        
      }
    });

    return handler;
  }

  watchZoomHandler(layer) {
    var self = this;
    return this.esriService.WatchUtils.watch(this.sharedService.mapView, 'scale', function (zoom) {
      if (!self.isMessageShowed && zoom < 9027) {
        layer.disabled = true;
        self.loadingMapService.stopLoad(self.cadastreLayerView.WatchID);
        notify("Публічний кадастр недоступний при даному масштабі", "warning", 3500);
        self.isMessageShowed = true;
        self.loadingMapService.checkLoadStatus();
      } else if (zoom >= 9027) {
        layer.disabled = false;
        self.isMessageShowed = false;
      }
    })
  }

  removeCadastreGraphicLayer() {
    let graphicLayer = (this.sharedService.map as __esri.Map).layers.find(x => x.get<boolean>('cadastreGraphicLayer'));
    if (graphicLayer) {
      (this.sharedService.map as __esri.Map).layers.remove(graphicLayer);
    }
  }

  createCadastrWMSService() {
    var layer = new this.esriService.WMSLayer({
      url: this.configService.CadastreMapWMSService,
      sublayers: [
        {
          name: "kadastr"          
        }
      ],
      imageMaxHeight: 256,
      imageMaxWidth: 256,
      VERSION: '1.3.0'
    });

    const fields = [
      new this.esriService.Field({
        name: "ObjectID",
        alias: "ObjectID",
        type: "oid"
      }), new this.esriService.Field({
        name: "cadastrenum",
        alias: "Кадастровий номер",
        type: "string"
      }), new this.esriService.Field({
        name: "square",
        alias: "Площа",
        type: "string"
      }), new this.esriService.Field({
        name: "appointment",
        alias: "Цільове призначення",
        type: "string"
      }), new this.esriService.Field({
        name: "typeproperty",
        alias: "тип власності",
        type: "string"
      })
    ];
    let _source: __esri.Collection<__esri.Graphic> = new this.esriService.Collection();

    let _symbol = new this.esriService.SimpleFillSymbol(this.esriService.SimpleFillSymbol.STYLE_SOLID,
      new this.esriService.SimpleLineSymbol(this.esriService.SimpleLineSymbol.STYLE_SOLID, new this.esriService.Color([0, 0, 0]), 0),
      new this.esriService.Color([0, 0, 0, 0]));

    var _renderer = {
      type: "simple", // autocasts as new SimpleRenderer()
      symbol: _symbol
    };
    var graphicLayer: __esri.FeatureLayer = new this.esriService.FeatureLayer(
      {
        title: 'Публічний кадастр',
        source: _source,
        renderer: _renderer,
       // geometryType: 'polygon',
        spatialReference: this.sharedService.mapView.spatialReference,
        objectIdField: 'ObjectID',
        fields: fields,
        popupTemplate: { title: 'Публічний кадастр', content: this.getCadastreObject },
        popupEnabled: true,
        outFields: ["*"]
      }
    );
    this.sharedService.mapView.extent.xmax
    var polygon: __esri.Polygon = new this.esriService.Polygon({
      rings: [
        [this.sharedService.mapView.extent.xmin, this.sharedService.mapView.extent.ymax],
        [this.sharedService.mapView.extent.xmax, this.sharedService.mapView.extent.ymax],
        [this.sharedService.mapView.extent.xmax, this.sharedService.mapView.extent.ymin],
        [this.sharedService.mapView.extent.xmin, this.sharedService.mapView.extent.ymin],
        [this.sharedService.mapView.extent.xmin, this.sharedService.mapView.extent.ymax],
      ],
      spatialReference: this.sharedService.mapView.spatialReference
    });

    graphicLayer.legendEnabled = false;
    let item: __esri.Graphic = new this.esriService.Graphic({
      geometry: polygon
    });

    graphicLayer.set<any>('Esri', this.esriService);

    graphicLayer.set<any>('MapView', this.sharedService.mapView);
    graphicLayer.set<any>('getInfoUrl', this.configService.CadastreGetInfoUrl);

    graphicLayer.source.add(item);
    graphicLayer.set<boolean>('cadastreGraphicLayer', true);
    //(this.sharedService.map as __esri.Map).add(graphicLayer);
    (layer as __esri.Layer).set<boolean>("IsCadastre", true);
    (this.sharedService.map as __esri.Map).add(layer);
    var self = this;

    return this.sharedService.mapView.whenLayerView(layer).then(function (layerView) {
      if (layerView) {
        self.cadastreLayerView = layerView;
        self.sharedService.IsShowedCadastre = true;
        if (!self.isMessageShowed && self.sharedService.mapView.scale < 9027) {
          layer.disabled = true;
          self.loadingMapService.stopLoad(layerView.get<any>("WatchID"));
          notify("Публічний кадастр недоступний при даному масштабі", "warning", 3500);
          self.isMessageShowed = true;
        }
        self.loadingMapService.AddToWatch(layerView);
        layerView.watch("updating", function (val) {
          var polygon: __esri.Polygon = new self.esriService.Polygon({
            rings: [
              [self.sharedService.mapView.extent.xmin, self.sharedService.mapView.extent.ymax],
              [self.sharedService.mapView.extent.xmax, self.sharedService.mapView.extent.ymax],
              [self.sharedService.mapView.extent.xmax, self.sharedService.mapView.extent.ymin],
              [self.sharedService.mapView.extent.xmin, self.sharedService.mapView.extent.ymin],
              [self.sharedService.mapView.extent.xmin, self.sharedService.mapView.extent.ymax],
            ],
            spatialReference: self.sharedService.mapView.spatialReference
          });
          if (graphicLayer.source.length > 0) {
            (graphicLayer as __esri.FeatureLayer).queryFeatures().then(result => {

              let item: any[] = result.features;
              let newitem: __esri.Graphic = new self.esriService.Graphic({
                geometry: polygon,
              });
              graphicLayer.applyEdits({
                addFeatures: [newitem],
                deleteFeatures: item
              });
            });

          } else {

            let newitem: __esri.Graphic = new self.esriService.Graphic({
              geometry: polygon,
            });
            graphicLayer.applyEdits({
              addFeatures: [newitem]
            });
          }          
        });
        //self.popupWatchHandler = self.watchPopupUpdating();
        self.zoomWatchHandler = self.watchZoomHandler(layer);
        
        return true;
      }
    }).catch(function (error) {
      console.log("Cadastre is not loaded: ", error.message);
      notify("Неможливо загрузити публічний кадастр. Сервіс недоступний", "error", 5000);
      self.removeCadastreGraphicLayer();
      self.sharedService.IsShowedCadastre = false;
      self.loadingMapService.checkLoadStatus();
      return false;
    });
  }

  createCadastrDynamicMap(): IPromise<boolean> {
    this.loadingMapService.startLoad();

    var CustomWMSLayer = this.esriService.BaseDynamicLayer.createSubclass({

      properties: {
        mapUrl: null,
        mapParameters: null,
        disabled: false
      },

      // Override the getImageUrl() method to generate URL
      // to an image for a given extent, width, and height.
      getImageUrl: function (extent, width, height) {
        if (this.disabled) return '';
        var urlVariables = this._prepareQuery(this.mapParameters,
          extent, width, height);
        var queryString = this._joinUrlVariables(urlVariables);
        return this.mapUrl + "?" + queryString;
      },

      // Prepare query parameters for the URL to an image to be generated
      _prepareQuery: function (queryParameters, extent, width, height) {
        var wkid = extent.spatialReference.isWebMercator ? 3857 :
          extent.spatialReference.wkid;
        var replacers = {
          width: width,
          height: height,
          wkid: wkid,
          xmin: extent.xmin,
          xmax: extent.xmax,
          ymin: extent.ymin,
          ymax: extent.ymax
        };

        var urlVariables = this._replace({}, queryParameters,
          replacers);
        return urlVariables;
      },

      // replace the url variables with the application provided values
      _replace(urlVariables, queryParameters, replacers) {
        Object.keys(queryParameters).forEach(function (key) {
          urlVariables[key] = Object.keys(replacers).reduce(
            function (previous, replacerKey) {
              return previous.replace("{" + replacerKey + "}",
                replacers[replacerKey]);
            }, queryParameters[key]);
        });

        return urlVariables;
      },

      // join the url parameters
      _joinUrlVariables: function (urlVariables) {
        return Object.keys(urlVariables).reduce(function (previous,
          key) {
          return previous + (previous ? "&" : "") + key + "=" +
            urlVariables[key];
        }, "");
      }

    });
    this.isMessageShowed = false;
    let extent = this.sharedService.mapView.extent;
    var layer = new CustomWMSLayer({
      mapUrl: this.configService.CadastreMapWMSService,      
      mapParameters: {
        tiled: 'true',
        SERVICE: "WMS",
        VERSION: '1.3.0',
        REQUEST: 'GetMap',        
        FORMAT: 'image/png',
        TRANSPARENT: 'true', 
        LAYERS: 'kadastr',
        TILED: 'true',
        STYLES :'',
        //ALIAS: 'Кадастровий поділ',
        //ALIAS_E: 'Cadastral Division',
        SRS: 'EPSG:900913',
        WIDTH: '{width}', // '256',
        HEIGHT: '{height}', // '256',
        CRS: 'EPSG:900913',
        //serverType: 'geoserver',
        BBOX: '{xmin},{ymin},{xmax},{ymax}',
      },
      legendEnabled: true,
      popupEnabled: true,
    });
    const fields = [
      new this.esriService.Field({
        name: "ObjectID",
        alias: "ObjectID",
        type: "oid"
      }), new this.esriService.Field({
        name: "cadastrenum",
        alias: "Кадастровий номер",
        type: "string"
      }), new this.esriService.Field({
        name: "square",
        alias: "Площа",
        type: "string"
      }), new this.esriService.Field({
        name: "appointment",
        alias: "Цільове призначення",
        type: "string"
      }), new this.esriService.Field({
        name: "typeproperty",
        alias: "тип власності",
        type: "string"
      })
    ];
    let _source: __esri.Collection<__esri.Graphic> = new this.esriService.Collection();

    let _symbol = new this.esriService.SimpleFillSymbol(this.esriService.SimpleFillSymbol.STYLE_SOLID,
      new this.esriService.SimpleLineSymbol(this.esriService.SimpleLineSymbol.STYLE_SOLID, new this.esriService.Color([0, 0, 0]), 0),
      new this.esriService.Color([0, 0, 0, 0]));

    var _renderer = {
      type: "simple", // autocasts as new SimpleRenderer()
      symbol: _symbol
    };
    var graphicLayer: __esri.FeatureLayer = new this.esriService.FeatureLayer(
      {
        title: 'Публічний кадастр',
        source: _source,
        renderer: _renderer,
        //geometryType: 'polygon',
        spatialReference: this.sharedService.mapView.spatialReference,
        objectIdField: 'ObjectID',
        fields: fields,
        popupTemplate: { title: 'Публічний кадастр', content: this.getCadastreObject },
        popupEnabled: true,
        outFields: ["*"]
      }
    );
    this.sharedService.mapView.extent.xmax
    var polygon: __esri.Polygon = this.esriService.Polygon({
      rings: [
        [this.sharedService.mapView.extent.xmin, this.sharedService.mapView.extent.ymax],
        [this.sharedService.mapView.extent.xmax, this.sharedService.mapView.extent.ymax],        
        [this.sharedService.mapView.extent.xmax, this.sharedService.mapView.extent.ymin],
        [this.sharedService.mapView.extent.xmin, this.sharedService.mapView.extent.ymin],
        [this.sharedService.mapView.extent.xmin, this.sharedService.mapView.extent.ymax],
      ],
      spatialReference: this.sharedService.mapView.spatialReference
    });

    graphicLayer.legendEnabled = false;
    //let item: __esri.Graphic = new this.esriService.Graphic({
    //  geometry: polygon 
    //});

    graphicLayer.set<any>('Esri', this.esriService);

    graphicLayer.set<any>('MapView', this.sharedService.mapView);
    graphicLayer.set<any>('getInfoUrl', this.configService.CadastreGetInfoUrl);

    //graphicLayer.source.add(item);
    
    graphicLayer.set<boolean>('cadastreGraphicLayer', true);
    (this.sharedService.map as __esri.Map).add(graphicLayer);

    (layer as __esri.Layer).set<boolean>("IsCadastre", true);
    (this.sharedService.map as __esri.Map).add(layer);
    var self = this;

    return this.sharedService.mapView.whenLayerView(layer).then(function (layerView) {
      if (layerView) {
        self.cadastreLayerView = layerView;
        self.sharedService.IsShowedCadastre = true;
        if (!self.isMessageShowed && self.sharedService.mapView.scale < 9027) {
          layer.disabled = true;
          self.loadingMapService.stopLoad(layerView.get<any>("WatchID"));
          notify("Публічний кадастр недоступний при даному масштабі", "warning", 3500);
          self.isMessageShowed = true;
        }
        self.loadingMapService.AddToWatch(layerView);
        layerView.watch("updating", function (val) {
            var polygon: __esri.Polygon = self.esriService.Polygon({
              rings: [
                [self.sharedService.mapView.extent.xmin, self.sharedService.mapView.extent.ymax],
                [self.sharedService.mapView.extent.xmax, self.sharedService.mapView.extent.ymax],
                [self.sharedService.mapView.extent.xmax, self.sharedService.mapView.extent.ymin],
                [self.sharedService.mapView.extent.xmin, self.sharedService.mapView.extent.ymin],
                [self.sharedService.mapView.extent.xmin, self.sharedService.mapView.extent.ymax],
              ],
              spatialReference: self.sharedService.mapView.spatialReference
            });
            if (graphicLayer.source.length > 0) {
              (graphicLayer as __esri.FeatureLayer).queryFeatures().then(result => {
                
                let item: any[] = result.features;
                let newitem: __esri.Graphic = new self.esriService.Graphic({
                  geometry: polygon,
                });
                graphicLayer.applyEdits({
                  addFeatures: [newitem],
                  deleteFeatures: item
                });
              }); 
              
            } else {

              let newitem: __esri.Graphic = new self.esriService.Graphic({
                geometry: polygon,
              });
              //  console.log('view is updating. create cadastre item');
              graphicLayer.applyEdits({
                addFeatures: [newitem]
              });
            }            
         //}
        });
        self.popupWatchHandler = self.watchPopupUpdating();
        self.zoomWatchHandler = self.watchZoomHandler(layer);
        
        return true;
      }
    }).catch(function (error) {
      console.log("Cadastre is not loaded: ", error.message);
      notify("Неможливо загрузити публічний кадастр. Сервіс недоступний", "error", 5000);
      self.removeCadastreGraphicLayer();
      self.sharedService.IsShowedCadastre = false;
      self.loadingMapService.checkLoadStatus();
      return false;
    });
  }
}
