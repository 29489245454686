import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";
import { MapLayersEndpoint } from '../map/endpoint-map.layers.service';
import { LayerDataWithAttr } from '../../models/layers/layer.data.model';
import { ConfigService } from '../shared/utils/config.service';
import { layer } from 'esri/views/3d/support/LayerPerformanceInfo';
import { LayerDataAttribute } from '../../models/layers/layer-data-attribute.models';
import { FilterAttributeService } from '../filterattribute/filter-attribute.service';
import { MapLayersService } from '../map/map.layers.service';
import { isNumeric } from 'rxjs/internal/util/isNumeric';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable()
export class SearchService {

  constructor(private sharedService: SharedService, private esriService: EsriService,
    private mapLayersEndpoint: MapLayersEndpoint,
    private configService: ConfigService,
    private filterAttributeService: FilterAttributeService,
    private mapLayersService: MapLayersService
  ) {
  }



  private _onStartSearch = new BehaviorSubject<boolean>(false);
  private _onSuggestComplete = new BehaviorSubject<any>(false);
  private _onClear= new BehaviorSubject<any>(false);

  onStartSearch(): Observable<any> {
    return this._onStartSearch.asObservable();
  }

  onSuggComplete(): Observable<any> {
    return this._onSuggestComplete.asObservable();
  }

  onClear(): Observable<any> {
    return this._onClear.asObservable();
  }
  

  public search: any;// __esri.Search;

  public searchSources: any[] = [];

  getSearch() {
    if (!this.search) {    
      this._createSearch();
    }
  }

  _createSearch() {
    this.search = new this.esriService.SearchViewModel({ //new this.esriService.Search({
      //map: this.sharedService.map //Reference to the map.
      view: this.sharedService.mapView,
      autoSelect: true,
      resultGraphicEnabled: true,
      includeDefaultSources: false,
      container: Constants.searchId,
      suggestionDelay : 1000,
      allPlaceholder : 'Знайти адресу або місце'
    }); //, Constants.searchId);

    this.search.goToOverride = function (view, goToParams) {
      goToParams.options.duration = 300;     
      let _target = goToParams.target;
      return view.goTo({ target: _target.target.geometry, zoom: view.zoom }, goToParams.options);
    };

    //this.search.suggestionDelay = 300; //this.search.viewModel.suggestionDelay = 300;
    this.onSearchComplete();

    this.onSelectResult();
    this.onSuggestComplete();
    this.onSearchClear();

    this.onSearchStart();
    this.onSuggestStart();

    //this.search.on("error", msg => {
    //  console.log("getSearch error:  ", msg);
    //});
  }

  public AddDataSources(value?: any[]) {
    let layers = <LayerDataWithAttr[]>value;
    let allSources: any[] = [];
    layers.
      filter(x => x.inSearch).
      forEach(x => {

      let _url = this.configService._baseUrlRegionServices + `${x.serviceName}` + `/` + `${x.layerName}`;
      _url = _url.replace('MapServer', 'FeatureServer');
      var featureLayer = this.createFeatureLayer(_url, x);
      var self = this;
      let _source = {
        layer: featureLayer,
        searchFields: this.getSearchFields(x.layerAttributes),
        maxSuggestions : 5,
        getSuggestions: async function (param) {          
          var attr = x.layerAttributes;
          var outFields: any[] = self.getOutFields(x.layerAttributes);          
          let params : any[]= param.suggestTerm.split(' ');
          let paramText = '';
          let searchedIds;
          let idx = params.findIndex(f => f == " " || f == "");
          while (idx >= 0) {
            params.splice(idx, 1);
            idx = params.findIndex(f => f == " " || f == "");
          }
          if (params.length > 1) {            
            for (let i = 0; i < params.length-1;  i++) {
              let _param = params[i].replace(/ /g, "");
              if (_param != "") {
                searchedIds = await self.getSearchedIds(_param, _url, featureLayer, x, searchedIds);
              }
              if (searchedIds.length == 0) {
                return [];
              }
            }
            paramText = params[params.length-1];
          } else {
            paramText = param.suggestTerm;
          }
          let _query = self.createSearchFilter(featureLayer, x, paramText, searchedIds);          
          
          return await self.esriService.Request(_url + "/query?f=json", {
            query: {
              where: _query, 
              resultRecordCount: this.maxSuggestions,
              outFields: outFields.toString(),
              returnGeometry: false
            },
            responseType: "json"
          }).then(function (result) {
            let layerInfo = result.data.features.map(function (feature) {
              return {
                key: feature.attributes["OBJECTID"],
                text: self.getSuggestionText(attr, featureLayer.fields, feature, featureLayer ), //  'text', //feature.properties.label,
                sourceIndex: param.sourceIndex,
                maxSuggestions: param.maxSuggestions
              };
            })  //JSON.parse(result.data);
            return layerInfo;
          }).catch(function (error) {
            console.log("informative error message: ", error);
          });
          
          return null;
        },

        getResults: function (param) {
                

          //var attr = x.layerAttributes;
          var outFields: any[] = self.getShowedFields(x.layerAttributes);
          //let _featureLayer = self.createFeatureLayer(_url, x);

          let _query = `OBJECTID=${param.suggestResult.key}`; // self.createSearchFilter(featureLayer, x, param.suggestResult.searchText); // 
          let query = {
            where: _query,
            resultRecordCount: 1,
            outFields: outFields.toString(),
            returnGeometry: true
          };
          return featureLayer.queryFeatures(query).
            then(function (result) {
              let layerInfo = result.features.map(function (_feature) {
                var searchResult = {
                  feature: _feature,
                };
                return searchResult;
              }); 
            return layerInfo;
          }).catch(function (error) {
            console.log("informative error message: ", error);
          });

          return null;
        },

        exactMatch: false,
        outFields: this.getOutFields(x.layerAttributes),
        displayField: this.getOutFields(x.layerAttributes).length > 0 ? this.getOutFields(x.layerAttributes)[0]:'',
        name: x.name,
        placeholder: x.layerName,
        popupTemplate: {
          title: "Пошук - " + x.name,
          content: this.GetPopupContentFeaure,
          overwriteActions : false,
          actions: [{
            id: "edit-feature",
            className: "esri-icon-edit",
            title: "Редагувати"
          },
          {
            id: "view-feature",
            className: "esri-icon-description",
            title: "Інфо"
            },
            {
              id: "has-files",
              className: "esri-icon-attachment",
              title: "Переглянути файли"
            },
          {
            id: "paint-feature",
            className: "esri-icon-expand2",
            title: "Змінити"
          },
          {
            id: "delete-feature",
            className: "esri-icon-trash",
            title: "Вилучити"
          }
          ]
        }
        };

      allSources.push(_source);
    })


    allSources.forEach(x => {
      let url = (x.layer as __esri.FeatureLayer).url + '/' + (x.layer as __esri.FeatureLayer).layerId;
      this.searchSources.push({ isSelected: false, LayerDataGUID: x.layer.LayerDataGUID, searchLayer: x });
      this.mapLayersService.getLayerInfo(url).then(result => {
        if (result) {
          let fields = result.fields;
          let attr = (x.layer as __esri.FeatureLayer).get<any>("layerAttributes");
          if (attr) {
            x.suggestionTemplate = this.getSuggestionTemplate(attr, fields);
            x.searchTemplate = this.getSuggestionTemplate(attr, fields);
          } else {
            console.log('attribute not found url :' + url);
          }

          this.search.sources.push(x);         
        }
        
      }).catch(ex => {
        console.log(`getLayerInfo Error : ${ex._body}`);
      })
    });
    this.sharedService.setSearchInit(true);    
  }

  public InitSearch(regionID) {
    this.getSearch();
    this.search.sources.splice(0, this.search.sources.length);
    this.searchSources.splice(0, this.searchSources.length);
    return this.mapLayersEndpoint.getLayerDataByRegionEndpoint(regionID).then(result => {
      let layers = <LayerDataWithAttr[]>result
      this.sharedService.setlayerData(layers);
      this.AddDataSources(layers);
      return layers;
    })
  }

  InitPublicSearch(layers) {
    this.search?.sources?.splice(0, this.search.sources.length);
    //this.search.sources = [];// .splice(0, this.search.sources.length);
    this.AddDataSources(layers);
  }

  private getAttributes(attributes: LayerDataAttribute[]): any[] {

    let result = [];
    attributes.forEach(x => {
      if (x.name.toUpperCase() != "OBJECTID" && x.name.toUpperCase() != "SHAPE"
        && x.name.toUpperCase() != "SHAPE.STLENGTH()" && x.name.toUpperCase() != "SHAPE.STAREA()") {
          result.push(x.name);
      }
    })
    return result;
  }

  private getSearchFields(attributes: LayerDataAttribute[]): any[] {

    let result = [];
    attributes.filter(a => a.inSearch || a.hasFilter).forEach(x => {
      if (x.name.toUpperCase() != "OBJECTID" && x.name.toUpperCase() != "SHAPE"
        && x.name.toUpperCase() != "SHAPE.STLENGTH()" && x.name.toUpperCase() != "SHAPE.STAREA()") {
        result.push(x.name);
      }
    })
    return result;
  }

  private getOutFields(attributes: LayerDataAttribute[]): any[] {

    let result = [];
    attributes.filter(a => a.inSearch || a.hasFilter).forEach(x => {    
      if (x.name.toUpperCase() != "SHAPE"
        && x.name.toUpperCase() != "SHAPE.STLENGTH()" && x.name.toUpperCase() != "SHAPE.STAREA()") {
        result.push(x.name);
      }
    })
    if (!result.find(x => x.toUpperCase() == "OBJECTID")) {
      result.push("OBJECTID");
    }
    return result;
  }

  private getShowedFields(attributes: LayerDataAttribute[]): any[] {

    let result = [];
    attributes.filter(a => a.inMini).forEach(x => {
      if (x.name.toUpperCase() != "SHAPE"
        && x.name.toUpperCase() != "SHAPE.STLENGTH()" && x.name.toUpperCase() != "SHAPE.STAREA()") {
        result.push(x.name);
      }
    })
    if (!result.find(x => x.toUpperCase() == "OBJECTID")) {
      result.push("OBJECTID");
    }
    return result;
  }

  private getFilterValue(filterType) {
    if (filterType == this.filterAttributeService.TypeKOATUU) {
      return this.sharedService.CurrentRegionCode;
    }

    return '';
  }

  private getSuggestionTemplate(attributes: LayerDataAttribute[], fields) {
    let result = '';
    attributes.forEach(x => {
      if (x.inSearch) {
        let field = fields ? fields.find(f => f.name == x.name) : null;
        result += `${(field ? field.alias : "unknown")} :{${x.name}} `;
      }

    });
    return result;
  }

  private getSuggestionText(attributes: LayerDataAttribute[], fields, feature, sourceLayer: __esri.FeatureLayer) {
    let result = '';
    attributes.forEach(x => {
      if (x.inSearch) {
        let field = fields ? fields.find(f => f.name == x.name) : null;
        let _value = feature.attributes[x.name] ? feature.attributes[x.name] : ""; ;
        if (field.domain) {
          let values: any[] = field.domain.get('codedValues');
          let domainValue = values.map(val => {
            return { id: val.code, name: val.name };
          }).find(f => f.id == feature.attributes[x.name]);
          _value = domainValue ? domainValue?.name : '';
        }
        else if (sourceLayer.typeIdField == x.name || sourceLayer.get<string>("subTypeField") == x.name) {
          let _id = feature.attributes[x.name] ? feature.attributes[x.name] : "";
          let typeValue = sourceLayer.types.find(t => t.id == _id);
          if (typeValue) {
            _value = typeValue.name;
          }
        }
        else {
          _value = feature.attributes[x.name] ? feature.attributes[x.name] : "";
        }
        
        if (_value) {
          result += `${(field ? field.alias : "unknown")} : ${_value} `;
        }
      }

    });
    return result;
  }

  private onSuggestComplete() {
    var self = this;
    
    this.search.on("suggest-complete", function (event) {
      // The results are stored in the event Object[]
      //let _search = self.search;
      let results: any[] = event.results;
      self._onSuggestComplete.next(event);
      //results.forEach(x => {
      //  if (x.results.length > 0) {
      //    let _array: any[] = x.results;
      //    let ttt = _array.map(val => {
      //      return val.key;
      //    });
      //    let layer = x.source.layer;
      //    let url = layer.url + "/" + x.source.layer.layerId;
      //    let query = layer.createQuery();
      //    let ids = "OBJECTID in (" + ttt.toString() + ")";
      //    query.where = layer.definitionExpression ? `${layer.definitionExpression} and ${ids}` :
      //      ids;
      //    layer.queryFeatures(query).then(result => {

      //      self.sharedService.mapView.graphics.addMany(result.features);
      //      let features: any[] = result.features;
      //      features.forEach(f => {
      //        f.popupTemplate = {
      //          title: "Пошук - " + x.name,
      //          content: self.GetPopupContentFeaure,
      //        }
      //      })
      //      console.log("feauters : " + result.features);

      //    });

      //    console.log("suggestion complete - " + url + " - " + ttt.toString());
      //  }

      //})



    });
  }

  private onSelectResult() {
    var _self = this;
    this.search.on("select-result", function (event) {
      // The results are stored in the event Object[]
      
      let opts = {
        duration: 500
      };
      let zoom = _self.sharedService.mapView.zoom;
      _self.sharedService.mapView.goTo({ target: event.result.feature, zoom: zoom }, opts)
    });
  }

  private onSearchComplete() {
    this.search.on("search-complete", function (event) {
      // The results are stored in the event Object[]
      
    });
  }

  private onSearchClear() {
    var self = this;
    this.search.on("search-clear", function (event) {
      // The results are stored in the event Object[]
      self.removeSearchFeatures();
      self._onClear.next(true);
      
    });
  }

  private onSearchStart() {
    
    this.search.on("search-start", function (event) {
      
      
    });
  }

  private onSuggestStart() {
    var _self = this;
    this.search.on("suggest-start", function (event) {
     // event.searchTerm = 'TEST'; // 'N' + event.searchTerm;
      _self._onStartSearch.next(true);
      
    });
  }

  private async GetPopupContentFeaure(feature) {

    let result = '*';
    let _feauture = feature.graphic;// as __esri.Graphic;
    let _sourceLayer = _feauture.sourceLayer;// ? _feauture.sourceLayer : _feauture.layer;
    let attributes = (_sourceLayer as __esri.FeatureLayer).get<any>("layerAttributes");// (_feauture.sourceLayer as __esri.FeatureLayer).get<any>("layerAttributes");
    let fields = (_sourceLayer as __esri.FeatureLayer).fields;
    if (attributes) {
      let sourceLayer = (_feauture.sourceLayer as __esri.FeatureLayer);
      result = '<ul class="esri-popup__list">';
      result += `<span style="display:none">OBJECTID : {OBJECTID}</span>`;
      attributes.forEach(x => {
        if (x.inMini) {
          let field = fields.find(f => f.name == x.name)
          let _value = '';
          if (field.domain) {
            let values: any[] = field.domain.get('codedValues');
            let domainValue = values.map(val => {
              return { id: val.code, name: val.name };
            }).find(f => f.id == _feauture.attributes[x.name]);
            _value = domainValue ? domainValue?.name : '';
          } else
            if (sourceLayer.typeIdField == x.name || sourceLayer.get<string>("subTypeField") == x.name) {
              let _id = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
              let typeValue = sourceLayer.types.find(t => t.id == _id);
              if (typeValue) {
                _value = typeValue.name;
              }
            } else {
              _value = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
              let url;
              let isUrl: boolean = false;
              try {
                url = new URL(_value);
                isUrl = url.protocol === "http:" || url.protocol === "https:";
              } catch (_) {
                isUrl = false;
              }
              if (isUrl) {
                _value = `<a target='blank' href='${_value}'>Більше...</a>`
              }
            }

          result += `<li><span class=esri-popup__text-secondary>${field.alias}</span> : <span class="esri-popup__text-primary">${_value}</span></li>`;
        }

      });
      result += '</ul>';
    }
    return result;
  }

  private async GetPopupContentSearchFeaure(feature, _fields?, _attributes?, _typeIdField?, _types?, _featureAttributes?) {

    let result = '*';
    let _feauture = feature.graphic;// as __esri.Graphic; 
    let attributes = _attributes?? (_feauture.sourceLayer as __esri.FeatureLayer).get<any>("layerAttributes");
    let fields = _fields ?? (_feauture.sourceLayer as __esri.FeatureLayer).fields;
    let featureAttributes = _featureAttributes??_feauture.attributes;
    if (attributes) {
      let sourceLayer = _feauture ? (_feauture.sourceLayer as __esri.FeatureLayer) : null;
      let typeIdField = _typeIdField?? sourceLayer.typeIdField;
      let types: any[] = _types??sourceLayer.types;
      result = '<ul class="esri-popup__list">';
      result += `<span style="display:none">OBJECTID : {OBJECTID}</span>`;
      attributes.forEach(x => {
        if (x.inMini) {
          let field = fields.find(f => f.name == x.name)
          let _value = '';
          //if (sourceLayer.typeIdField && sourceLayer.typeIdField == x.name) {
          if (typeIdField && typeIdField == x.name) {
            let _id = featureAttributes[x.name] ? featureAttributes[x.name] : "";
            let typeValue = types.find(t => t.id == _id);
            if (typeValue) {
              _value = typeValue.name;
            }
          } else {
            _value = featureAttributes[x.name] ? featureAttributes[x.name] : "";
          }

          result += `<li><span class=esri-popup__text-secondary>${field.alias}</span> : <span class="esri-popup__text-primary">${_value}</span></li>`;
        }

      });
      result += '</ul>';
    }
    return result;
  }

  private removeSearchFeatures() {
    this.sharedService.mapView.graphics.removeAll();
  }

  private createFeatureLayer(_url, x) {
    let featureLayer = new this.esriService.FeatureLayer({
      url: _url,
    });
    (featureLayer as __esri.FeatureLayer).set<boolean>("isSearchLayer", true);
    (featureLayer as __esri.FeatureLayer).set<string>("LayerDataGUID", x.id);
    (featureLayer as __esri.FeatureLayer).set<string>("name", x.name); // isSearchLayer
    let editable = x.layerAttributes.find(x => x.editable);
    if (editable)
      (featureLayer as __esri.FeatureLayer).set<boolean>("editable", true);
    else
      (featureLayer as __esri.FeatureLayer).set<boolean>("editable", false);
    let showed = x.layerAttributes.find(x => x.showed);

    if (showed) {
      (featureLayer as __esri.FeatureLayer).set<boolean>("showed", true);
    } else {
      (featureLayer as __esri.FeatureLayer).set<boolean>("showed", false);
    }
    (featureLayer as __esri.FeatureLayer).set<boolean>("canAddObject", x.canAddObject);
    (featureLayer as __esri.FeatureLayer).set<any>("layerAttributes", x.layerAttributes);
    //(featureLayer as __esri.FeatureLayer).
    if (x.layerAttributes.find(a => a.hasFilter)) {
      let filter = '';
      let groupTypeArray: any[] = [];
      x.layerAttributes.filter(f => f.hasFilter).forEach(attr => {
        if (filter.length > 0) {
          filter = filter + ' and ';
        }
        if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
          attr.subTypeFilters.forEach(s => {

          })
          let visibledSubtypes = [];
          attr.subTypeFilters.forEach(st => {
            visibledSubtypes.push(st.subTypeID);
          })
          //subLayer.set<number[]>("visibledSubtypes", visibledSubtypes);
          let strFilter = "";
          attr.subTypeFilters.forEach(s => {
            if (strFilter.length > 0) {
              strFilter = strFilter + ','
            }

            strFilter = strFilter + s.subTypeID;
          })
          filter += attr.name + " in ( " + strFilter + ")";
        } else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
          let filterKOATUU = attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
          filter += filterKOATUU;//attr.name + " = '" + this.getFilterValue(attr.filterType) + "'";
          //subLayer.set<string>("filterKOATUU", filterKOATUU);
        } else if (attr.filterType == this.filterAttributeService.TypeGroup) {
          let groupFilter = "";
          attr.groupValueFilters.forEach(g => {
            if (groupFilter.length > 0) {
              groupFilter += ',';
            }
            //if (attr.fieldType == 'string') {
            groupFilter += `N'${g.value}'`;
            //}

          })

          if (groupFilter.length > 0) {
            //if (filter.length > 0) {
            //  filter = filter + ' and ';
            //}
            filter += attr.name + " in ( " + groupFilter + ")";
          }
          let n = {
            name: attr.name,
            group: attr.groupValueFilters
          }
          groupTypeArray.push(n);

        }
      })
      
      featureLayer.definitionExpression = filter;
    }
    return featureLayer;
  }

  private createSearchFilter(featureLayer, x, suggestTerm, ids: any[]) {
    let _query = '';
    if (ids && ids.length == 0) {
      return "1=0";
    }
    let searchF = this.getSearchFields(x.layerAttributes);
    let str: string;
    searchF.forEach(f => {
      let field = (featureLayer as __esri.FeatureLayer).fields.find(field => field.name == f);
      if (field) {
        switch (field.type) {
          case "string":
            let param: string = suggestTerm.replace("'", "''").replace(/ /g, "");
            if (str) {
              str += ` or ${f} LIKE N'%${param}%' `
            } else {
              str = ` ${f} LIKE N'%${param}%' `;
            }
            break;
          case "small-integer":
          case "integer":
          case "single":
          case "double":
          case "long":
          //case "date":
            if (isNumeric(suggestTerm)) {
              if (str) {
                str += ` or  ${f} = ${suggestTerm} `
              } else {
                str = ` ${f} = ${suggestTerm} `;
              }
            }
            else {
              if (str) {
                str += ` or  1 = 0 `
              } else {
                str = ` 1 = 0 `;
              }
            }
            break;
          default:
            break;
        }

      }


    })
    
    if (str) {
      if (ids?.length > 0) {
        _query = `OBJECTID in (${ids.map(m => { return m.key; }).toString()}) and (${str})`;
      }else if (featureLayer.definitionExpression) {
        _query = `(${featureLayer.definitionExpression}) and (${str})`;
      } else {
        _query = str;
      }
    } else {
      _query = "1=0";
    }
    

    return _query;
  }

  public IncreaseLimit(sourceIndex: number, searchText: string, inc: number) {
    let sources: any[] = this.search.sources.items;
    let source = sources[sourceIndex];    
    source.maxSuggestions = source.maxSuggestions + inc;
    this.search.suggest(searchText);
  }

  public SetSuggestionsLimit(sourceIndex: number, searchText: string, inc: number) {
    let sources: any[] = this.search.sources.items;
    let source = sources[sourceIndex];
    source.maxSuggestions = inc;
    this.search.suggest(searchText);
  }

  public ShowByLayer(sourceIndex, searchText: string) {
    let sources: any[] = this.search.sources.items;

    this.search.activeSourceIndex = sourceIndex;
    this.search.suggest(searchText);
  }

  public SetSearchLayers(selectIDs: any[]) {
    this.search.sources.splice(0, this.search.sources.length);
    if (selectIDs.length > 0) {
      selectIDs.forEach(id => {
        let source = this.searchSources.find(s => s.LayerDataGUID == id);
        if (source) {
          this.search.sources.push(source.searchLayer);
        }
      });
      
    } else {
      this.searchSources.forEach(s => {
        this.search.sources.push(s.searchLayer);
      })
    }
    this.search.activeSourceIndex = -1;
  }

  private async getSearchedIds(param, _url, featureLayer, layerAttributes, searchedIds: any[]) {
    let _query = this.createSearchFilter(featureLayer, layerAttributes, param, searchedIds);
    
    let res = await this.esriService.Request(_url + "/query?f=json", {
      query: {
        where: _query,
        //resultRecordCount: maxSuggestions,
        outFields: "OBJECTID", // outFields.toString(),
        returnGeometry: false
      },
      responseType: "json"
    }).then(async function (result) {
      let layerInfo = result.data.features.map(function (feature) {
        return {
          key: feature.attributes["OBJECTID"],
        };
      })  //JSON.parse(result.data);      
      return layerInfo;
    });
    return res;
  }
}
