import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";

@Injectable()
export class LocatebtnService {

  constructor(private sharedService: SharedService, private esriService: EsriService ) {
    
  }


  private locateBtn: any;

  getLocateBtn() {
    if (!this.locateBtn) {
      this._createButton();      
    }
  }

  _createButton() {
    this.locateBtn = new this.esriService.LocateButton({
      view: this.sharedService.mapView
    },
      Constants.locateButtonId);

    this.sharedService.mapView.ui.add(this.locateBtn,
      "bottom-right"
    );
    


    this.locateBtn.on("error",
      msg => {
        console.log("geoLocate error:  ", msg);
      });
  }
}
