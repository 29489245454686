import { Component } from '@angular/core';
import { OverviewmapService } from "../../services/overviewmap/overviewmap.service";


@Component({
  selector: 'app-overviewmap',
  templateUrl: './overviewmap.component.html',
  styleUrls: ['./overviewmap.component.scss']
})
export class OverviewmapComponent  {


  constructor(private overviewmapService: OverviewmapService) { }

  //ngOnInit() {
  //  //this.overviewmapService.getOverviewMap();
  //}

}
