import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { SharedService } from "../../services/shared/shared.service";
import { Location } from "@angular/common";
import { LayersService } from "../../services/layers/layers.service";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LayerDataAttribute } from "../../models/layers/layer-data-attribute.models";
import { UserService } from "../../services/user/user.service";
import { BaseComponent } from "../basecomponent/base.component";
import { MapLayersService } from '../../services/map/map.layers.service';
import { ChartService } from '../../services/chart/chart.service';
import { ChartInfo, ChartSettings } from '../../models/chart/chart.model';
import { param } from 'jquery';
import notify from 'devextreme/ui/notify';
import { DxChartModule, DxChartComponent } from 'devextreme-angular';
//import { DxPieChartComponent, DxPieChartModule } from 'devextreme-angular';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})

//export class ChartDataInfo {
//  name: string;
//  value: number;
//}

export class ChartComponent extends BaseComponent implements OnInit, OnDestroy {

  attributes: any[];
  chartName: any;
  backUrl: any;
  chartDataSource: any[] = [];
  totalAmount: number;
  chartInfo: ChartInfo;
  currentChartIndex: number = -1;
  listChart: ChartSettings[] = [];
  isGroup: boolean = false;
  bookmarkID: string;
  resizeSubscription: Subscription;
  resizeWidth: number;
  isShowSum: boolean;
  chartDescription: string;

  //@ViewChild('pieChartID', { static: true })
  //pieChartID: DxPieChartComponent;

  @ViewChild('pieChartID', { static: false }) pieChartID: DxChartComponent;
  @ViewChild('barChartID', { static: false }) barChartID: DxChartComponent;

  constructor(
    private sharedService: SharedService,
    private location: Location,
    private layersService: LayersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private mapLayersService: MapLayersService,
    private chartService: ChartService
  ) {
    super();
  }

  ngOnInit() {
    this.backUrl = this.activatedRoute.snapshot.params['backUrl'] || '/';
    this.activatedRoute.params.subscribe((params: Params) => {
      this.bookmarkID = params['bookmarkid'];
      this.chartService.listChartsByPublicBookmark(this.bookmarkID).then(result => { // this.sharedService.PublicBookmarkID
        this.listChart = result;
        if (result?.length > 0) {
          this.isGroup = result.length > 1;
          this.currentChartIndex = 0;
          this.chartService.getChartByLegend(result[0]).then(chartInfo => {
            this.isShowSum = result[0].isShowSum;
            this.chartDescription = result[0].description;
            if (result[0].showCountInLegend) {
              chartInfo.values.forEach(value => {
                value.name = value.name + ' /' + value.value + '/';
              })
            }
            this.initChart(chartInfo);
          }).catch(ex => {
            notify(`error on load legend.\r\n  ${ex._body}`, "error", 3500);
          })

        }
      })
    })

    this.resizeSubscription = this.sharedService.getNavigationMenuWidth().subscribe(val => {
      if (val && val > 0) {
        this.resizeWidth = val;
        var self = this;
        setTimeout(function () {
          self.renderChart();
          //self.pieChartID.instance.render();
        }, 700)
        
      }

      //this.subscribeBookmarkChanged();
      
      //notify(`Ширина: "${val}"`, "error", 1500);
    })
    //this.chartService.getChartByID("id").then(chartInfo => {
    //  this.initChart(chartInfo);
    //})
  }

  private renderChart() {
    switch (this.chartInfo?.chartType) {
      case "pie":
        this.pieChartID.instance.render();
        break;
      case "bar":
        this.barChartID.instance.render();

    }
  }

  private initChart(chartInfo: ChartInfo) {
    if (chartInfo) {
      this.chartInfo = chartInfo;
      this.chartName = chartInfo.name;      
      this.chartDataSource = chartInfo.values;
      this.totalAmount = chartInfo.values.map(val => { return val.value }).reduce((prev, curr) => prev + curr);
    }
  }
 
  goBack() {
    let url = this.backUrl ? this.backUrl : '/legend';
    if (this.sharedService.publicGroup) {
      this.router.navigate([url], { queryParams: { group: '1' } });
    } else {
      this.router.navigate([url]);
    }
    //this.router.navigate([this.backUrl]);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    //this.subscriptionBookmarkChanged?.unsubscribe();
    this.resizeSubscription?.unsubscribe()
  }

  customizeLabel(arg) {
    return arg.valueText + " (" + arg.percentText + ")";
  }

  navigateToChart(id) {
    this.currentChartIndex = this.listChart.findIndex(c => c.id == id);
    this.chartService.getChartByLegend(this.listChart[this.currentChartIndex]).then(chartInfo => {
      this.isShowSum = this.listChart[this.currentChartIndex].isShowSum;
      this.chartDescription = this.listChart[this.currentChartIndex].description;
      if (this.listChart[this.currentChartIndex].showCountInLegend) {
        chartInfo.values.forEach(value => {
          value.name = value.name + ' /' + value.value + '/';
        })
      }
      this.initChart(chartInfo);
    })
  }

  customizeText = (arg: any) => {
    let temp = arg;

    if (arg.value >= 1000 && arg.value < 1000000) {
      return arg.valueText.replace("K", "T");
    }
    return arg.valueText ;
  }

}
