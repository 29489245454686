<div (window:resize)="onResize($event)">
  <nav class="nav"
       [ngClass]="{'nav--active': !isShow}"
       [ngStyle]="nav"
       mwlResizable
       [validateResize]="validate"
       [enableGhostResize]="true"
       [resizeSnapGrid]="{ left: 1, right: 1 }"
       (resizeEnd)="onResizeEnd($event)">
    <div (click)="isShow=!isShow" class="btn-nav">
      <span class="esri-icon-left"></span>
      <span class="esri-icon-drag-horizontal"></span>
    </div>
    <div class="nav__content">
      <div *ngIf="isLoading" class="progress"></div>
      <!--router-outlet name="searchrouter"></!--router-outlet>-->
      <a [routerLink]="['/layers']"> карти </a>
      <router-outlet></router-outlet>
    </div>
    <div class="nav__resize" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
  </nav> 
  <div class="map"
       [ngStyle]="map">    
  </div>
</div>
