import { Injectable } from '@angular/core';
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";
import { ConfigService } from "../shared/utils/config.service";
import * as FileSaver from 'file-saver';
import { PrintFileEndpoint } from "../print/print-file.end-point.service";
import { Observable } from 'rxjs';


@Injectable()
export class ImportGeodataService {

  constructor(private sharedService: SharedService, private esriService: EsriService, private configService: ConfigService,
    private printFileEndpoint: PrintFileEndpoint
  ) {
    
  } 

  importGeodata(filedata, layerId, sublayerID): Observable<string> {
   
    let formdata = new FormData();

    formdata.append("file", filedata);
    let url = this.configService.UploadExcelToImportUrl; // 'https://server.xgis.com.ua/gis/rest/services/test/ExcelToTable/GPServer/uploads/upload';

    let options: __esri.RequestOptions=
      {
        body: formdata,
        responseType: 'document'
      };
    var self = this;
    let observable = new Observable<string>(observer => {
      this.esriService.Request(url, options).then(function (result) {
      // The requested data
      var geoJson = result.data;

      let itemID = geoJson.all[33].text;
      let url = self.configService.ImportGeodataServiceUrl;

      let gp: __esri.Geoprocessor = self.esriService.Geoprocessor(url);
      gp.outSpatialReference = self.sharedService.mapView.spatialReference;

      var params = { 'Input_Excel_File': "{'itemID':" + itemID + "}" };

      let option: __esri.RequestOptions =
        {
         // callbackParamName: 'callback',
          responseType: 'json'
        };

      gp.submitJob(params, option).then(result => {
        let test = result;
        gp.getResultData(result.jobId, 'Output_Table').then(resultData => {
          let tempData: __esri.FeatureSet = resultData.value;

          let map: __esri.Map = self.sharedService.map as __esri.Map;
          let mapLayer: __esri.MapImageLayer = map.findLayerById(layerId) as __esri.MapImageLayer;
          let subLayer = mapLayer.sublayers.find(x => x.id == sublayerID);// getItemAt(0);
          let _url = subLayer.url;
          _url = _url.replace('MapServer', 'FeatureServer');
          
          let layer: __esri.FeatureLayer = new self.esriService.FeatureLayer({
            url: _url,
          });
          let isCorrectFormat = false;
          if (tempData.fields.length > 0) {
            isCorrectFormat = true;

            //tempData.fields.forEach(f => {
            
            //  if (f.name != 'OBJECTID_1' && f.name != 'Shape_Length' && f.name != 'Shape_Area') {
            //    let field = layer.fields.find(lf => lf.name == f.name);
            //    if (!field) {
            //      isCorrectFormat = false;
            //      // break;
            //    }
            //  }
            //})
          }
          
          if (!isCorrectFormat)
          {
            observer.next('Імпорт був завершен з помилкой. Формат файлу не ввідповідіє даним шару.');
            //observer.complete();
            return observer.complete();
          }
          tempData.features.forEach(f => {
            f.layer = layer;
            f.attributes["OBJECTID"] = f.attributes["OBJECTID_1"];
          });
          layer.applyEdits({
            updateFeatures: tempData.features
          }).then(applyResult => {
            let _count = applyResult.updateFeatureResults.length;

            observer.next('Імпорт пройшов успішно. Кількість імпортованих об\'єктів : ' + _count);
            observer.complete();
            }).catch(function (error) {
              observer.next('Імпорт був завершен з помилкой: ' + error.message);
              console.log("informative error message: ", error.message);
              observer.complete();
            });

        }).catch(function (error) {
          observer.next('Імпорт був завершен з помилкой: ' + error.message);
          console.log("informative error message: ", error.message);
          observer.complete();
        });
      });


    }).catch(function (error) {
      observer.next('Імпорт був завершен з помилкой: ' + error.message);
      console.log("informative error message: ", error.message);
      observer.complete();
    });
    }
    );

    
    //this.esriService.Request(url, options).then(function (result) {
    //  // The requested data
    //  var geoJson = result.data;
           
    //  let itemID = geoJson.all[33].text;
    //  let url = self.configService.ImportGeodataServiceUrl;

    //  let gp: __esri.Geoprocessor = self.esriService.Geoprocessor(url);
    //  gp.outSpatialReference = self.sharedService.mapView.spatialReference;

    //  var params = { 'Input_Excel_File': "{'itemID':" + itemID + "}" };

    //  let option: __esri.requestEsriRequestOptions =
    //    {
    //      callbackParamName: 'callback',
    //      responseType: 'json'
    //    };

    //  gp.submitJob(params, option).then(result => {
    //    let test = result;
    //    gp.getResultData(result.jobId, 'Output_Table').then(resultData => {
    //      let tempData: __esri.FeatureSet = resultData.value;
          
    //      let map: __esri.Map = self.sharedService.map as __esri.Map;
    //      let layer: __esri.FeatureLayer = map.findLayerById(layerId) as __esri.FeatureLayer;
    //      tempData.features.forEach(f => {
    //        f.layer = layer;
    //        f.attributes["OBJECTID"] = f.attributes["OBJECTID_1"];
    //      });
    //      layer.applyEdits({
    //        updateFeatures: tempData.features
    //      }).then(applyResult => {
    //          return Observable.of('import is successed');
    //        });
    //    })
    //  });


    //}).catch(function (error) {
      
    //  console.log("informative error message: ", error.message);
     
    //});

    return observable;// Observable.of('import is failed');
  }
}
