<div class="bookmark-header-title">
  <a (click)="goBack()" class="btn-icon">
    <span class="icon-arrow-left"></span>
  </a>
  <span>
    Створити нову закладку
  </span>
</div>
<div class="bookmark-add">
  <div class="text-field">
    <div class="text-field-input">
      <dx-validation-group #validationBookmarkData>
        <dx-text-box [(value)]="newName" placeholder="Назва закладки">
          <dx-validator>
            <dxi-validation-rule type="required" message="Назва закладки обов'язкова"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </dx-validation-group>
    </div>
  </div>
</div>
<div class="bookmark-add">
  <div *ngIf="showBookmarks" class="text-field">
    <div class="text-field-label">
      Або зберегти в закладку
    </div>
    <div class="text-field-input">
      <dx-drop-down-box [(value)]="treeBoxValue"
                        [(opened)]="isTreeBoxOpened"
                        valueExpr="id"
                        displayExpr="name"
                        placeholder="Виберіть закладку..."
                        [showClearButton]="true"
                        [items]="bookmarksTree"
                        (onValueChanged)="syncTreeViewSelection($event)"
                        (onOptionChanged)="onTreeBoxOptionChanged($event)">
        <div *dxTemplate="let data of 'content'">
          <dx-tree-view [items]="bookmarksList"
                        itemsExpr="bookmarks"
                        keyExpr="id"
                        selectionMode="single"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onContentReady)="$event.component.selectItem(treeBoxValue)"
                        (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
          </dx-tree-view>
        </div>
      </dx-drop-down-box>
    </div>
  </div>
</div>
<div class="buttons">
  <!--<dx-button id="cancelBtn"
              text="Відмінити"
              type="normal"
              (onClick)="goBack()"
              [disabled]="isSaving">
  </dx-button>-->
  <dx-button id="saveBtn"
              width="100%"
              text="Зберегти"
              type="default"
              (onClick)="save()"
              [disabled]="isSaving">
  </dx-button>
</div>
