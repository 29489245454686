import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { SharedService } from "../../services/shared/shared.service";
import { Location } from "@angular/common";
import { LayersService } from "../../services/layers/layers.service";
import { DxPopupModule, DxTemplateModule } from 'devextreme-angular';
import { Router } from '@angular/router';
import { LayerDataWithAttr } from '../../models/layers/layer.data.model';
import { LayerDataAttribute } from '../../models/layers/layer-data-attribute.models';
import { EsriService } from "../../services/esri/js-esri.service";
import { BaseComponent } from "../basecomponent/base.component";
import { ConfigService } from "../../services/shared/utils/config.service";
import { SketchViewService } from "../../services/customlayer/sketch-view.service";
import { MapLayersService } from '../../services/map/map.layers.service';
import notify from 'devextreme/ui/notify';
import { AzureStorageService } from '../../services/azurestorage/azure-storage.service';
import { DocumentUploadComponent } from '../documentupload/document.upload.component';
import { UserService } from '../../services/user/user.service';
import { layer } from 'esri/views/3d/support/LayerPerformanceInfo';

@Component({
  selector: 'app-edit-geodata',
  templateUrl: './edit-geodata.component.html',
  styleUrls: ['./edit-geodata.component.scss']
})
export class EditGeodataComponent extends BaseComponent implements OnInit {
  attributes: any[];
  layerAttribute: LayerDataAttribute[];
  featureAttr: any;
  list: any[];
 // mapImageLayer: __esri.MapImageLayer;
  layerName: any;
  //subscription: Subscription;
  popupVisible: boolean = false;
  private selectedFeature: any;// __esri.Graphic;
  cloneFeatureLayer: __esri.FeatureLayer;
  isCustomLayer: boolean = false;
  isNavigationLayer: boolean = false;
  CurrentColor: any;
  currentStyle: any;
  currentWidth: number = 2;
  currentType: string;
  styleDataSource: any[] = [];
  listDocuments: any[];
  //layerTypes: any[] = [];
  // layerTypeID: any;
  documentContainerName: string = "test";
  maxAmountFiles: number;
  isMaxFiles: boolean = false;
  @ViewChild('documentUpload', { static: false }) documentUpload: DocumentUploadComponent;

  constructor(
    private sharedService: SharedService,
    private location: Location,
    private layersService: LayersService,
    private router: Router,
    private esriService: EsriService,
    private configService: ConfigService,
    private sketchViewService: SketchViewService,
    private mapLayersService: MapLayersService,
    private azureStorageService: AzureStorageService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.maxAmountFiles = this.sharedService.RegionAmountFiles;
    this.selectedFeature = this.sharedService.SelectedFeatureValue();
    this.sharedService.setHiddenSearching(true);
    if (this.selectedFeature) {
      this.popupVisible = true;
    } else {
      this.closePopup();
      return;
    }
    if (this.selectedFeature.sourceLayer) {
      let tempLayer = (this.selectedFeature.sourceLayer as __esri.Sublayer);
      //this.mapImageLayer = this.selectedFeature.sourceLayer.layer as __esri.MapImageLayer;
      //let layerDataID = (this.selectedFeature.sourceLayer.layer as __esri.MapImageLayer).get<string>('LayerDataGUID');
      let layerDataID = tempLayer.get<string>('LayerDataGUID');
      //this.layerName = this.mapImageLayer.get<string>('name');
      this.layerName = tempLayer.get<string>('name');


      this.layersService.getLayerData(layerDataID).subscribe((result) => {
        this.documentContainerName = result.id + '-' + this.selectedFeature.attributes["OBJECTID"];
        //this.documentContainerName = this.documentContainerName.replace("/", "");
        this.documentUpload.containerName = this.documentContainerName;
        //this.azureStorageService.getSASToken().then(result => {
        this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
          this.listDocuments = result;
          this.isMaxFiles = this.listDocuments.length >= this.maxAmountFiles;
          this.documentUpload.isDisabled = this.isMaxFiles;
        })
        //})

        this.layerAttribute = result.layerAttributes;
        this.featureAttr = this.selectedFeature.attributes;
        let attributes: any[] = Object.keys(this.selectedFeature.attributes);
        this.attributes = new Array<any>();
        let temp = 0;
        attributes.
          sort((a: any, b: any) => {
            let aIndex = this.layerAttribute.findIndex(y => y.name == a );
            let bIndex = this.layerAttribute.findIndex(y => y.name == b );
            let sortResult = aIndex < bIndex ? -1 : 1;
            return sortResult;
          }).
          forEach(x => {
            let item = this.layerAttribute.find(y => y.name == x && y.editable);
            if (item) {
              //let sublayer = this.mapImageLayer.sublayers.getItemAt(0)
              //let fields: [__esri.Field] = sublayer.get<any>("fields");
              let fields: [__esri.Field] = tempLayer.get<any>("fields");
              let field = fields.find(f => f.name == x);
              let attribute = { 'name': x, 'value': this.featureAttr[x], 'editable': item.editable, 'alias': field.alias, 'isSubType': false, subTypes: [] };
              if (field.domain) {
                let values: any[] = field.domain.get('codedValues');
                attribute.subTypes = values.map(val => {
                  return { id: val.code, name: val.name };
                })
                attribute.isSubType = true;
              } else if (((tempLayer.get<any>("type") == "feature" || tempLayer.get<any>("type") == "graphics") && tempLayer.typeIdField && x == tempLayer.typeIdField)
                || (tempLayer.get<any>("subTypeField") == x)) {
                attribute.subTypes = tempLayer.types;
                attribute.isSubType = true;
              }
              this.attributes.push(attribute);
            }
          });
        
      })
    } else if (this.selectedFeature.layer && this.selectedFeature.layer.id == 'customLayerID') {
      this.isCustomLayer = true;
      this.featureAttr = this.selectedFeature.attributes;
     
      if (this.userService.User) {
        this.documentContainerName = this.sharedService.getRegionIDValue() + '-' + this.selectedFeature.attributes["OBJECTID"];

        this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
          this.listDocuments = result;
          this.documentUpload.containerName = this.documentContainerName;
          this.isMaxFiles = this.listDocuments.length >= this.maxAmountFiles;
          this.documentUpload.isDisabled = this.isMaxFiles;
        })
      }

      this.attributes = new Array<any>();
      this.attributes.push({ 'name': 'Name', 'value': this.featureAttr['Name'], 'editable': true, 'alias': 'Назва' });
      this.attributes.push({ 'name': 'Description', 'value': this.featureAttr['Description'], 'editable': true, 'alias': 'Опис' });

      let color = (this.selectedFeature as __esri.Graphic).symbol.get<any>("color");
      this.currentType = (this.selectedFeature as __esri.Graphic).symbol.type;
      switch (this.currentType) {
        case "simple-marker":
          this.styleDataSource = this.getPointStyles();
          this.currentWidth = (this.selectedFeature as __esri.Graphic).symbol.get<any>("size");
          break;
        case "simple-line":
          this.styleDataSource = this.getLineStyles();
          this.currentWidth = (this.selectedFeature as __esri.Graphic).symbol.get<any>("width");
          break;
      }

      this.currentStyle = (this.selectedFeature as __esri.Graphic).symbol.get<any>("style");

      switch (this.currentStyle) {
        case "cross":
        case "x":
          let symb = (this.selectedFeature as __esri.Graphic).symbol;
          this.CurrentColor = symb.get<any>("outline.color");
          break;
        case "circle":
        case "square":
        case "diamond":
        default:
          this.CurrentColor = color;
          break;
      }
    } else if (this.selectedFeature.layer && this.selectedFeature.layer.id == 'navigationLayerID') {
      this.isNavigationLayer = true;
      let tempLayer = (this.selectedFeature.layer as __esri.FeatureLayer);

      let layerDataID = tempLayer.get<string>('LayerDataGUID');
      this.layerName = tempLayer.get<string>('name');


      this.layersService.getLayerData(layerDataID).subscribe((result) => {
        this.documentContainerName = result.id + '-' + this.selectedFeature.attributes["OBJECTID"];
        this.documentUpload.containerName = this.documentContainerName;
        this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
          this.listDocuments = result;
          this.isMaxFiles = this.listDocuments.length >= this.maxAmountFiles;
          this.documentUpload.isDisabled = this.isMaxFiles;
        })
        this.layerAttribute = result.layerAttributes;
        this.featureAttr = this.selectedFeature.attributes;
        let attributes: any[] = Object.keys(this.selectedFeature.attributes);
        this.attributes = new Array<any>();
        attributes.
          sort((a: any, b: any) => {
            let aIndex = this.layerAttribute.findIndex(y => y.name == a);
            let bIndex = this.layerAttribute.findIndex(y => y.name == b);
            let sortResult = aIndex < bIndex ? -1 : 1;
            return sortResult;
          }).
          forEach(x => {
            let item = this.layerAttribute.find(y => y.name == x && y.editable);
            if (item) {
              let fields: [__esri.Field] = tempLayer.get<any>("fields");
              let field = fields.find(f => f.name == x)
              //this.attributes.push({ 'name': x, 'value': this.featureAttr[x], 'editable': item.editable, 'alias': field.alias });
              let attribute = { 'name': x, 'value': this.featureAttr[x], 'editable': item.editable, 'alias': field.alias, 'isSubType': false, subTypes: [] };
              if (field.domain) {
                let values: any[] = field.domain.get('codedValues');
                attribute.subTypes = values.map(val => {
                  return { id: val.code, name: val.name };
                })
                attribute.isSubType = true;
              } else if (((tempLayer.get<any>("type") == "feature" || tempLayer.get<any>("type") == "graphics") && tempLayer.typeIdField && x == tempLayer.typeIdField)
                || (tempLayer.get<any>("subTypeField") == x)) {
                attribute.subTypes = tempLayer.types;
                attribute.isSubType = true;
              }
              this.attributes.push(attribute);
            }
          });
        let _url = tempLayer.get<string>('subLayerUrl')?.replace('FeatureServer', 'MapServer') + '/' + tempLayer.get<string>('layerId');
        
        this.mapLayersService.getLayerInfo(_url).then(LayerInfo => {
          if (LayerInfo) {
            if (LayerInfo.subtypeFieldName) {
              this.attributes.forEach(atr => {
                if (atr.name == LayerInfo.subtypeFieldName) {
                  atr.subTypes = LayerInfo.types;
                  atr.isSubType = true;
                }
              })
            }
          }
        });
      })
    }
  }

  goBack() {
    //this.location.back();
    this.closePopup();
  }

  closePopup() {
    // Providing a `null` value to the named outlet
    // clears the contents of the named outlet    
    this.router.navigate(['./', { outlets: { 'modalrouter': null } }], { skipLocationChange: true });
  }

  save() {
    
    this.attributes.forEach(x => {
      this.selectedFeature.attributes[x.name] = x.value;
    })

    if (!this.isCustomLayer && !this.isNavigationLayer) {
      let _url = this.selectedFeature.sourceLayer.url;
      if (this.selectedFeature.sourceLayer.type == 'feature') {
        _url += `/${this.selectedFeature.sourceLayer.layerId}`;
      }
      _url = _url.replace('MapServer', 'FeatureServer');
      let tempLayer: __esri.FeatureLayer = new this.esriService.FeatureLayer({
        url: _url,
      });
      let updGraphic: __esri.Graphic = new this.esriService.Graphic();
      updGraphic.attributes = this.selectedFeature.attributes;
      tempLayer.applyEdits({
        //updateFeatures: [this.selectedFeature]
        updateFeatures: [updGraphic]
      }).then(val => {
        let _map = this.sharedService.map as __esri.Map;
        if (this.selectedFeature.sourceLayer.type == 'feature') {
          (this.selectedFeature.sourceLayer as __esri.FeatureLayer).refresh();
        }
        else {
          _map.layers.forEach(layer => {
            if ((layer as __esri.MapImageLayer).sublayers) {
              var self = this;
              let isSublayer = (layer as __esri.MapImageLayer).sublayers.find(function (sub) { return sub.id == self.selectedFeature.sourceLayer.id });
              if (isSublayer) {
                (layer as __esri.MapImageLayer).refresh();
              }
            }
          });
        }
        
      }).catch(ex => {
        notify(`Помилка при збережені даних.\r\n  ${ex._body}`, "error", 3500);
      });

      let temp: __esri.Graphic = new this.esriService.Graphic();
      Object.assign(temp, this.selectedFeature);
      temp.layer = this.selectedFeature.layer;
      temp.attributes = this.selectedFeature.attributes;
      temp.set<any>("sourceLayer", this.selectedFeature.sourceLayer);
      this.sharedService.setSelectedFeature(temp);
     
    } else if (this.isNavigationLayer) {
            
      let _url = this.selectedFeature.layer.subLayerUrl;
      _url = _url.replace('MapServer', 'FeatureServer');
      _url += '/' + this.selectedFeature.layer.layerId;
      let tempLayer: __esri.FeatureLayer = new this.esriService.FeatureLayer({
        url: _url,
      });

      //notify("save url : " + _url, "info", 3000);
      let updGraphic: __esri.Graphic = new this.esriService.Graphic();
      updGraphic.attributes = this.selectedFeature.attributes;
      tempLayer.applyEdits({
        //updateFeatures: [this.selectedFeature]
        updateFeatures: [updGraphic]
      }).then(val => {
        let _map = this.sharedService.map as __esri.Map;

        let allLayers = _map.layers.toArray();
        let sourceLayerUrl = this.selectedFeature.layer.subLayerUrl.replace('FeatureServer', 'MapServer');
        for (var _repaintLayer of allLayers) {
          if (_repaintLayer.get<any>("url") == sourceLayerUrl) {
            (_repaintLayer as __esri.MapImageLayer).refresh();           
            break;
          }
        }
        let tempGraphic: __esri.Graphic = new this.esriService.Graphic();
        Object.assign(tempGraphic, this.selectedFeature);
        tempGraphic.layer = this.selectedFeature.layer;
        tempGraphic.attributes = this.selectedFeature.attributes;
        tempGraphic.set<any>("sourceLayer", this.selectedFeature.sourceLayer);
        this.sharedService.setSelectedFeature(tempGraphic);

        let _navigateLayer = _map.layers.find(layer => layer.id == "navigationLayerID");
        if (_navigateLayer) {
          let tmp = (_navigateLayer as __esri.GraphicsLayer).graphics.find(x => x.attributes["OBJECTID"] == tempGraphic.attributes["OBJECTID"]);

          tmp.attributes = tempGraphic.attributes;
          tmp.popupTemplate.content = this.GetPopupContentFeaure(tmp);
         
        }
       
        this.sharedService.setRefreshNavigationItem(tempGraphic);
           
      }).catch(ex => {
        notify(`Помилка при збережені даних.\r\n  ${ex._body}`, "error", 3500);
      });

    }

    if (this.isCustomLayer) {
      let _graphicLayer = this.sharedService.mapView.layerViews.find(x => x.layer.id == 'customLayerID').layer;
      let updGraphic = (_graphicLayer as __esri.GraphicsLayer).graphics.find(x => x.attributes['OBJECTID'] == this.selectedFeature.attributes['OBJECTID']);
      if (updGraphic) {
        updGraphic.attributes['Name'] = this.selectedFeature.attributes['Name'];
        updGraphic.attributes['Description'] = this.selectedFeature.attributes['Description'];

        switch ((this.selectedFeature as __esri.Graphic).symbol.type) {
          case "simple-marker":
            switch (this.currentStyle) {
              case "cross":
                updGraphic.set<any>("symbol", this.sketchViewService.createPointSymbol(this.currentStyle, this.CurrentColor, this.currentWidth, this.CurrentColor));
                break;
              case "x":
                updGraphic.set<any>("symbol", this.sketchViewService.createPointSymbol(this.currentStyle, this.CurrentColor, this.currentWidth, this.CurrentColor));      
                break;
              case "circle":
              case "square":
              case "diamond":
              case "triangle":
              default:
                updGraphic.set<any>("symbol", this.sketchViewService.createPointSymbol(this.currentStyle, this.CurrentColor, this.currentWidth));
                break;
            }
            break;
          case "simple-line":
            updGraphic.set<any>("symbol", this.sketchViewService.createLineSymbol(this.currentStyle, this.CurrentColor, this.currentWidth));
            break;
          case "simple-fill":
            updGraphic.set<any>("symbol", this.sketchViewService.createPolygonSymbol(this.CurrentColor));
            break;
        }
        // TODO refresh graphic
        //let index = (_graphicLayer as __esri.GraphicsLayer).graphics.findIndex(x => x.attributes['OBJECTID'] == this.selectedFeature.attributes['OBJECTID']);
        //(_graphicLayer as __esri.GraphicsLayer).graphics.splice(index, 1);
        //(_graphicLayer as __esri.GraphicsLayer).graphics.push(updGraphic);
        
        this.sketchViewService.collectGraphics();
      }
    }

    this.closePopup();   
  }

  getPointStyles(): any[] {
    let result: any[] = [{ id: 'circle', name: 'круг' }, { id: 'diamond', name: 'ромб' },  { id: 'triangle', name: 'трикутник' },
      { id: 'square', name: 'квадрат' }, { id: 'x', name: 'X' }, { id: 'cross', name: '+' }];

    return result;
  }

  getLineStyles(): any[] {
    let result: any[] = [{ id: 'dash', name: 'тире' }, { id: 'dash-dot', name: 'тире-крапка' }, { id: 'dot', name: 'крапка' },
      { id: 'long-dash', name: 'довге-тире' }, { id: 'long-dash-dot', name: 'довге-тире-крапка' }, { id: 'long-dash-dot-dot', name: 'довге-тире-крапка-крапка' },
      { id: 'short-dash', name: 'коротке-тире' }, { id: 'short-dash-dot', name: 'коротке-тире-крапка' },
      { id: 'short-dash-dot-dot', name: 'коротке-тире-крапка-крапка' },
      { id: 'short-dot', name: 'коротка-крапка' }, { id: 'solid', name: 'суцільна' }];

    return result;
  }

  downloadDoc(doc) {
    let ext = doc.substring(doc.lastIndexOf('.') + 1);
    this.sharedService.currentDocumentContainer = this.documentContainerName;
    this.sharedService.currentDocumentName = doc;
    switch (ext.toLowerCase()) {
      case "pdf":
        this.router.navigate(['./', { outlets: { viewrouter: null } }], { skipLocationChange: true }).then(val => {
          this.router.navigate(['./', { outlets: { viewrouter: ['viewdoc'] } }], { skipLocationChange: true });
        })
        break;
      case "tif":
      case "tiff":
      case "png":
      case "jpg":
      case "jpeg":
        this.router.navigate(['./', { outlets: { viewrouter: null } }], { skipLocationChange: true }).then(val => {
          this.router.navigate(['./', { outlets: { viewrouter: ['viewimage'] } }], { skipLocationChange: true });
        })
        break;
      default:
        this.azureStorageService.getBlob(this.documentContainerName, doc).then(() => {
          this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
            this.listDocuments = result;
          })
        });
        break;
    }
  }

  deleteDoc(doc) {
    this.azureStorageService.deleteBlob(this.documentContainerName, doc).then(() => {
      this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
        this.listDocuments = result;
        this.isMaxFiles = this.listDocuments.length >= this.maxAmountFiles;
        this.documentUpload.isDisabled = this.isMaxFiles;
      })
    });
  }

  refreshFiles(obj) {
    this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
      this.listDocuments = result;
      this.isMaxFiles = this.listDocuments.length >= this.maxAmountFiles;
      this.documentUpload.isDisabled = this.isMaxFiles;
    })
  }


  private GetPopupContentFeaure(feature) {
    let result = '*';
    let _feauture = feature;
    let sourceLayer = (_feauture.layer as __esri.FeatureLayer);
    let attributes: LayerDataAttribute[] = sourceLayer.get<any>("layerAttributes");  
    let fields = sourceLayer.fields;
    
    result = '<ul class="esri-popup__list">';

    result += `<span style="display:none">OBJECTID : {OBJECTID}</span>`;
    attributes.forEach(x => {
      if (x.inMini) {
        let field = fields.find(f => f.name == x.name)
        let _value = '';
        if (sourceLayer.typeIdField && sourceLayer.typeIdField == x.name) {
          let _id = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
          let typeValue = sourceLayer.types.find(t => t.id == _id);
          if (typeValue) {
            _value = typeValue.name;
          }
        } else {
          _value = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
          let url;
          let isUrl: boolean = false;
          try {
            url = new URL(_value);
            isUrl = url.protocol === "http:" || url.protocol === "https:";
          } catch (_) {
            isUrl = false;
          }
          if (isUrl) {
            _value = `<a target='blank' href='${_value}'>Більше...</a>`
          }
        }

        result += `<li><span class=esri-popup__text-secondary>${field.alias}</span> : <span class="esri-popup__text-primary">${_value}</span></li>`;
      }

    })
    result += '</ul>';
    return result;
  }
}
