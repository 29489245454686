import { Injectable } from '@angular/core';
import { loadScript, loadModules } from 'esri-loader';
import { Constants } from "../shared/data/data.service";


@Injectable()
export class EsriService {

  Map: any;
  MapView: any;
  Request: (url: string, options?: __esri.RequestOptions) => IPromise<__esri.RequestResponse>;
  EsriConfig: any;
  SceneView: any;
  IdentityManager: __esri.IdentityManager;
  Extent: any;
  Query: any;
  QueryTask: any;
  Geoprocessor: any
 // DataFile: any;
  ImageryLayer: any;
  MapImageLayer: any;
  SpatialReference: any;
  Basemap: any;
  LocalBasemapsSource: any;
  ViewPoint: any;
  Polygon: any;
  Polyline: any;
  Point: any
  Projection: any;
  webMercatorUtils: any
  GeometryEngine: any;
  WatchUtils: any;
  Collection: any;
  Action: any;
  ActionButton: any;
  BasemapGallery: any;
  TileLayer: any;
  FeatureLayer: any;
  OpenStreetLayer: any;
  GraphicsLayer: any;
  SubLayer: any;
  WMSLayer: any;
  BaseDynamicLayer: any;
  VectorTileLayer: any;
  Field: any;
  UniqueValues: any;
  SimpleFillSymbol: any;
  SimpleMarkerSymbol: any;
  SimpleRenderer: any;
  Color: any; 
  Graphic: any;
  Draw: any;
  SimpleLineSymbol: any;
  //DojoFx: any;
  //CoreFx: any;
  //DojoLang: any;
  //DojoAspect: any;
  HomeButton: any;
  LocateButton: any;
  Scalebar: any;
  Search: any;
  SearchViewModel: any;
  Popup: any;
  Print: any;
  PopupTemplate: any;
  Zoom: any;
  SketchViewModel: any;
  LOD: any;
  TextContent: any;
  constructor() { }
   
  resolve(): Promise<any[]> {    
    var options = {
      url: 'https://js.arcgis.com/4.22/', 
      //url: 'https://arcgisserver.xgis.com.ua/arcgis_js_api/library/4.9/init.js',
      dojoConfig: {
        locale: 'uk',
        //parseOnLoad: true,
        has: { 'esri-featurelayer-webgl': 1 }
      },
    }
    return loadModules([Constants.map, Constants.mapView, Constants.request, Constants.esriConfig, Constants.identityManager, Constants.extent, Constants.query, Constants.queryTask, Constants.geoprocessor,
      //Constants.dataFile,
    Constants.sceneView,
      Constants.spatialReference, Constants.basemapGallery, Constants.basemap, Constants.localBasemapsSource, Constants.viewpoint,
      Constants.polygon, Constants.polyline, Constants.point, Constants.projection, Constants.webMercatorUtils,
      Constants.geometryEngine, Constants.watchUtils, Constants.collection, Constants.action, Constants.actionButton,
      Constants.imageryLayer, Constants.tileLayer, Constants.featureLayer, Constants.mapImageLayer, Constants.openStreetLayer, Constants.graphicsLayer, Constants.subLayer,
      Constants.wmsLayer, Constants.baseDynamicLayer, Constants.vectorTileLayer, Constants.field,
      Constants.uniqueValues,
        Constants.simpleFillSymbol, Constants.simpleMarkerSymbol, Constants.simpleLineSymbol, 
      Constants.simpleRenderer, Constants.color, Constants.graphic, Constants.draw,
      Constants.homeButton, Constants.locateButton, Constants.scalebar, Constants.search, Constants.searchViewModel, Constants.popup, Constants.print, Constants.popupTemplate, Constants.zoom, Constants.sketchViewModel,
      Constants.lod, Constants.textContent
      ], options).
      then(([map, mapView, request, esriConfig, identityManager, extent, query, queryTask, geoprocessor,
        //dataFile,
        sceneView,
          spatialReference, basemapGallery, basemap, localBasemapsSource, viewpoint,
          polygon, polyline, point, projection, webMercatorUtils,
          geometryEngine, watchUtils, collection, action, actionButton,
          imageryLayer, tileLayer, featureLayer, mapImageLayer, openStreetLayer, graphicsLayer, subLayer,
          wmsLayer, baseDynamicLayer, vectorTileLayer, field,
          uniqueValues,
          simpleFillSymbol, simpleMarkerSymbol, simpleLineSymbol,
          simpleRenderer, color, graphic, draw,
        homeButton, locateButton, scalebar, search, searchViewModel, popup, print, popupTemplate, zoom, sketchViewModel,
        lod, textContent
        ]) => {
          
          this.Map = map;
          this.MapView = mapView;
          this.Request = request;
          this.EsriConfig = esriConfig;
          this.SceneView = sceneView;
          this.IdentityManager = identityManager;
          this.Extent = extent;
          this.Query = query;
          this.QueryTask = queryTask;
          this.Geoprocessor = geoprocessor;
          this.SpatialReference = spatialReference;
          this.BasemapGallery = basemapGallery;
          this.Basemap = basemap;
          this.LocalBasemapsSource = localBasemapsSource;
          this.ViewPoint = viewpoint;
          this.Polygon = polygon;
          this.Polyline = polyline;
          this.Point = point;
          this.Projection = projection;
          this.webMercatorUtils = webMercatorUtils;
          this.GeometryEngine = geometryEngine;
          this.WatchUtils = watchUtils;
          this.Collection = collection;
          this.Action = action;
          this.ActionButton = actionButton;
          this.ImageryLayer = imageryLayer;
          this.TileLayer = tileLayer;
          this.FeatureLayer = featureLayer;
          this.MapImageLayer = mapImageLayer;
          this.OpenStreetLayer = openStreetLayer;
          this.GraphicsLayer = graphicsLayer;
          this.SubLayer = subLayer;
          this.WMSLayer = wmsLayer;
          this.BaseDynamicLayer = baseDynamicLayer;
          this.VectorTileLayer = vectorTileLayer;
          this.Field = field;
          this.UniqueValues = uniqueValues;
          this.SimpleFillSymbol = simpleFillSymbol;
          this.SimpleMarkerSymbol = simpleMarkerSymbol;
          this.SimpleLineSymbol = simpleLineSymbol;
          this.SimpleRenderer = simpleRenderer;
          this.Color = color;          
          this.Graphic = graphic;
          this.Draw = draw;
          this.HomeButton = homeButton;
          this.LocateButton = locateButton;
          this.Scalebar = scalebar;
          this.Search = search;
          this.SearchViewModel = searchViewModel;
          this.Popup = popup;
          this.Print = print;
          this.PopupTemplate = popupTemplate;
          this.Zoom = zoom;         
          this.SketchViewModel = sketchViewModel;
        //this.Bundle(this.DojoNlsCommon);
        this.LOD = lod;
        this.TextContent = textContent;

        return [map, mapView, request, esriConfig, identityManager, extent, query, queryTask, geoprocessor,
          //dataFile,
          sceneView,
          spatialReference, basemapGallery, basemap, localBasemapsSource, viewpoint,
          polygon, polyline, point, projection, geometryEngine, watchUtils, collection, action, actionButton,
          imageryLayer, tileLayer, featureLayer, mapImageLayer, openStreetLayer, graphicsLayer, subLayer,
          wmsLayer, baseDynamicLayer, vectorTileLayer, field,
          uniqueValues,
          simpleFillSymbol, simpleMarkerSymbol, simpleLineSymbol,
          simpleRenderer, color, graphic, draw,
          homeButton, locateButton, scalebar, search, searchViewModel, popup, print, popupTemplate, zoom, sketchViewModel,
          lod, textContent
        ];
        });
  }

  Bundle(bundle) {
    bundle.form.yes = "Yeah!";
  }

  
}
