<app-measurement-toolbar *ngIf="isStarted"></app-measurement-toolbar>
<div *ngIf="isGroup" class="tabs">
  <dx-tabs [items]="groupBookmarks"
           [height]="47"
           [showNavButtons]="true"
           itemTemplate="tabItem"
           [(selectedIndex)]="groupIndex">
    <div *dxTemplate="let itemData of 'tabItem'" (click)="navigateToBookmark(itemData.id)">
      {{itemData.name}}
    </div>

  </dx-tabs>
</div>
<div class="tabs-content">
  <app-publiclegends *ngIf="showLegend"  #legend  ></app-publiclegends>
  <app-bookmarks-story *ngIf="showStory" #bookmarkStory ></app-bookmarks-story>
</div>
