<div class="w-ch" [hidden]="!isShowCustomLayer">
  <div class="dx-checkbox-has-text dx-checkbox dx-widget" [ngClass]="{'filled' : isCheckedCustomLayer, 'dx-state-disabled': isCustomLayereDisabled  }" (click)="selectCustomLayer()">
    <div class="dx-checkbox-container">
      <span class="dx-checkbox-icon"></span>
      <span class="dx-checkbox-text">
        Власні об'єкти
      </span>
    </div>
  </div>
</div>
<div class="w-ch">
  <div class="dx-checkbox-has-text dx-checkbox dx-widget" [ngClass]="{'filled' : isCadastreSelect, 'dx-state-disabled': isCadastreDisabled }" (click)="selectCadastre()">
    <div class="dx-checkbox-container">
      <span class="dx-checkbox-icon"></span>
      <span class="dx-checkbox-text">
        Публічний кадастр
      </span>
    </div>
  </div>
</div>
<div class="w-ch" *ngIf="isPinned">
  <div class="dx-checkbox-has-text dx-checkbox dx-widget" [ngClass]="{'filled' : isPinnedSelect, 'dx-state-disabled': pinnedLayerDisabled}" (click)="selectPinnedLayer()">
    <div class="dx-checkbox-container">
      <span class="dx-checkbox-icon"></span>
      <span class="dx-checkbox-text">
        {{pinnedLayerName}}
      </span>
    </div>
  </div>
</div>
<dx-tree-view #treeView id="selection-treeview"
              [items]="Layers"
              itemsExpr="layerChildren"
              selectedExpr="isSelected"
              disabledExpr="isEmpty"
              expandedExpr="isExpanded"
              [focusStateEnabled]=false
              [selectNodesRecursive]=false
              showCheckBoxesMode="normal"
              noDataText="Нема даних">
  <div *dxTemplate="let data of 'item'" >
    <!--button *ngIf="data && !data.hasItems && data.hasFlash" type="button" class="btn-icon btn-icon--traffic-light icon-traffic-light" (click)="selectLayer(data)"></button-->
    <!--<button *ngIf="data  && data.hasItems" type="button" class="btn-icon btn-icon--esri-icon-collapse esri-icon-collapse" (click)="selectChildrenLayers(data)"></button>-->
    <div  [ngClass]="{'treeview-item' :  true , 'treeview-item--bookmark' : data.inBookmark, 'treeview-item--filter':  data.inFilter  }" >
      <div *ngIf="data  && data.hasItems" class="checkbox-all">
        <div class="dx-checkbox dx-checkbox  dx-widget" [ngClass]="{'filled': data.selectChildrenType=='all'}" (click)="selectChildrenLayers(data)">
          <div [ngClass]="{ 'disabled': data.isEmpty }" class="dx-checkbox-container">
            <span class="dx-checkbox-icon"></span>
          </div>
        </div>
      </div>

      <span>{{data.name}}</span>

    </div>
  </div>
</dx-tree-view>





