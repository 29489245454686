<dx-popup class="popup-panel"
          [width]="320"
          height="100%"
          position="left top"
          [showTitle]="true"
          [shadingColor]="'rgba(1,11,38,.24)'"
          title="{{layerName}}"
          [dragEnabled]="false"
          [shading] ="false"
          [closeOnOutsideClick]="false"
          (onHidden)="goBack()"
          [(visible)]="popupVisible">
  <dxo-animation>
    <dxo-show type="pop"
              [from]="{ scale:1, opacity: 0 }"
              [to]="{ scale:1, opacity: 1 }"></dxo-show>
    <dxo-hide type="pop"
              [from]="{ scale:1, opacity: 1 }"
              [to]="{ scale:1, opacity: 0 }"></dxo-hide>
  </dxo-animation>
  <div *dxTemplate="let data of 'content'">
    <div class="popup-panel__buttons">
      <dx-button id="cancelBtn"
                 text="Відмінити"
                 type="normal"
                 (onClick)="goBack()"
                 [disabled]="isSaving">
      </dx-button>
      <dx-button id="saveBtn"
                 text="Готово"
                 type="default"
                 (onClick)="save()"
                 [disabled]="isSaving">
      </dx-button>
    </div>
    <div class="popup-panel__content">
      <dx-scroll-view [width]="'100%'">
        <div class="text-field" [hidden]="!isCustomLayer">
          <div class="text-field-label">
            Колір
          </div>
          <div class="text-field-input">
            <dx-color-box [(value)]="CurrentColor"
                          [editAlphaChannel]="true"
                          pplyButtonText="Готово"
                          cancelButtonText="Відміна"></dx-color-box>
          </div>
        </div>
        <div class="text-field" [hidden]="!(isCustomLayer&&(currentType=='simple-marker'||currentType=='simple-line'))">
          <div class="text-field-label">
            Стиль
          </div>
          <div class="text-field-input">
            <dx-select-box [dataSource]="styleDataSource"
                           displayExpr="name"
                           valueExpr="id"
                           placeholder="Виберіть стиль"
                           [(value)]="currentStyle"
                           noDataText="Немає даних">
              <dx-validator>
                <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>
        </div>
        <div class="text-field" [hidden]="!(isCustomLayer&&(currentType=='simple-marker'||currentType=='simple-line'))">
          <div class="text-field-label">
            Розмір
          </div>
          <div class="text-field-input">
            <dx-number-box [max]="32"
                           [min]="0.1"
                           [step]="0.5"
                           [showSpinButtons]="true"
                           [(value)]="currentWidth">

            </dx-number-box>
          </div>
        </div>
        <div *ngFor="let attr of attributes" class="text-field">
          <div class="text-field-label">{{ attr.alias }}</div>
          <div class="text-field-input" [hidden]="!attr.editable">
            <dx-text-box *ngIf="attr.editable&&!attr.isSubType" [(value)]="attr.value">
            </dx-text-box>
            <dx-select-box *ngIf="attr.editable&&attr.isSubType" [dataSource]="attr.subTypes"
                           displayExpr="name"
                           valueExpr="id"
                           placeholder="Виберіть тип"
                           [(value)]="attr.value"
                           noDataText="Немає даних">
              <dx-validator>
                <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>
          <div class="text-field-input text-field-input--disabled" [hidden]="attr.editable">{{ attr.value }}</div>
        </div>

        <div class="text-field">
          <div class="text-field-label">
            Документи
          </div>
          <div class="text-field-input">
            <app-document-upload [isDisabled]="isMaxFiles" (uploadEvent)="refreshFiles($event)" #documentUpload></app-document-upload>
          </div>
          <div *ngIf="isMaxFiles" class="text-field-label">
            <span>Ліміт завантажених файлів {{maxAmountFiles}}</span>
          </div>
        </div>
        <div class="text-field">
          <ul class="list-files">
            <li *ngFor="let doc of listDocuments" class="list-files__item">
              <button type="button" class="list-files__item-btn-delete" (click)="deleteDoc(doc)">
                <span class="esri-icon-trash"></span>
              </button>
              <a (click)="downloadDoc(doc)" class="list-files__item-link" title="{{doc}}"> {{doc}} </a>
            </li>
          </ul>
        </div>



      </dx-scroll-view>
    </div>
  </div>
</dx-popup>
