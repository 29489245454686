<app-measurement-toolbar *ngIf="isStarted"></app-measurement-toolbar>
<app-toolbar *ngIf="true"></app-toolbar>
<dx-tabs #apiTabs
          [dataSource]="tabs"
          (onItemClick)="selectTab($event)"
          (onItemRendered)="onItemRendered($event)"
         >
</dx-tabs>
<div class="w-tabs-content">
  <router-outlet></router-outlet>
</div>
