import { Component, OnInit } from '@angular/core';
import { HomebtnService } from "../../services/homebtn/homebtn.service"

@Component({
  selector: 'app-homebtn',
  templateUrl: './homebtn.component.html',
  styleUrls: ['./homebtn.component.scss']
})
export class HomebtnComponent implements OnInit {


  constructor(private homebtnService: HomebtnService) { }

  ngOnInit() {
    this.homebtnService.getHomeBtn();
  }

}
