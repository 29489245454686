<div #coordsWidgetID *ngIf="isStarted" id="coordsWidget" class="esri-widget esri-component">
    <div>
      <span class="text-secondary">X: </span><span class="text-primary">{{coordX}}° </span><span class="text-secondary">Y: </span><span class="text-primary">{{coordY}}°</span>
    </div>
    <div *ngIf="isPolyline||isPolygon">
      <span class="text-secondary">Довжина: </span><span class="text-primary">{{lengthLine}}</span><span class="text-secondary">м</span>
    </div>
    <div *ngIf="isPolygon">
      <span class="text-secondary">Площа: </span><span class="text-primary">{{areaPolygon}}</span><span class="text-secondary">м<sup>2</sup></span>
    </div>
</div>
