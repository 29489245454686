
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EndpointFactory } from '../shared/endpoint-factory.service';
import { Observable } from 'rxjs';

@Injectable()
export class PrintFileEndpoint extends EndpointFactory {
    

  //get printed file
  getPrintedFileEndpoint(urlFile?: string): Observable<any> {
    const httpOptions = {
      observe: 'body' as const,
      responseType: 'blob' as const,      
    }
    return this.http.get(urlFile, httpOptions).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getPrintedFileEndpoint(urlFile));
      }),);
  }

}
