import { Component, OnInit, ViewChild } from '@angular/core';
import { ScalebarService } from "../../services/scalebar/scalebar.service";
import { SharedService } from "../../services/shared/shared.service";
import { DxSelectBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'app-scalebar',
  templateUrl: './scalebar.component.html',
  styleUrls: ['./scalebar.component.scss']
})
export class ScalebarComponent implements OnInit {
  templateScales: any[] = [500, 1000, 2000, 5000, 10000];
  isShowed: boolean = true;
  scales: any[] = this.templateScales;
  scalesValue: number; 
  changedValue: number;

  @ViewChild(DxSelectBoxComponent) listBox;


  constructor(private scalebarService: ScalebarService, private sharedService: SharedService) { }

  ngOnInit() {
    //this.scalebarService.getScalebar();

    var self = this;
    this.sharedService.mapView.watch("scale", function (result) {      
      self.scalesValue = result.toFixed();
      //console.log('scale: ' + self.scalesValue);
    });
  }

  onClosed(e) {
    this.isShowed = true;
  }

  onClickLabel() {
    this.scales = this.templateScales;
    this.isShowed = false;
    var self = this;
    setTimeout(function () {
      self.listBox.instance.open();
    }, 100);    
  }

  onItemClick(e) {
    this.changedValue = e.itemData;
     
    this.sharedService.mapView.scale = e.itemData;
    this.scales = [];
    //var self = this;
    //setTimeout(function () {
    //  if (self.sharedService.mapView.scale != self.changedValue)
    //    self.sharedService.mapView.scale = self.changedValue;
    //}, 2000);               
  }




}
