<div *ngIf="isGroup" class="tabs">
  <dx-tabs [items]="listChart"
           [height]="47"
           [showNavButtons]="true"
           itemTemplate="tabItem"
           [(selectedIndex)]="currentChartIndex">
    <div *dxTemplate="let itemData of 'tabItem'" (click)="navigateToChart(itemData.id)">
      {{itemData.name}}
    </div>

  </dx-tabs>
</div>
<div class="tabs-content">
  <div class="panel-header">
    <a (click)="goBack()" class="btn-icon">
      <i class="icon-close"></i>
    </a>
  </div>
  <div class="chart">
    <dx-scroll-view direction="vertical">

      <dx-pie-chart #pieChartID *ngIf="chartInfo?.chartType=='pie'" id="pieChartID"
                    title="{{chartName}}"
                    palette="Material"
                    resolveLabelOverlapping="shift"
                    [dataSource]="chartDataSource">

        <dxo-legend orientation="horizontal"
                    itemTextPosition="right"
                    horizontalAlignment="center"
                    verticalAlignment="bottom"></dxo-legend>
        <dxi-series argumentField="name"
                    valueField="value">
          <dxo-label [visible]="true"
                     format="fixedPoint"
                     [customizeText]="customizeLabel">
            <dxo-font [size]="16"></dxo-font>
            <dxo-connector [visible]="true" [width]="1"></dxo-connector>
          </dxo-label>

        </dxi-series>
        <dxo-argument-axis>
          <dxo-label [visible]="true" template="labelTemplate"></dxo-label>
        </dxo-argument-axis>
        <text *dxTemplate="let data of 'labelTemplate'" class="template-text" x="30" y="59" text-anchor="middle">{{data.name}} - 3</text>

      </dx-pie-chart>

      <dx-chart #barChartID *ngIf="chartInfo?.chartType=='bar'" id="barChartID"
                title="{{chartName}}"
                palette="Material"
                [dataSource]="chartDataSource">
        <dxi-series argumentField="name"
                    valueField="value"
                    name="{{chartName}}"
                    type="bar">
          <dxo-label [visible]="true"
                     format="fixedPoint">
            <dxo-font [size]="16"></dxo-font>
            <dxo-connector [visible]="true" [width]="1"></dxo-connector>
          </dxo-label>
        </dxi-series>
        <dxi-value-axis>
          <dxo-label [customizeText]="customizeText"></dxo-label>
        </dxi-value-axis>
        <dxo-argument-axis>
          <dxo-label [visible]="true" displayMode="rotate"></dxo-label>
        </dxo-argument-axis>

      </dx-chart>

      <div *ngIf="isShowSum" class="text">
        <span class="text-secondary">Загальна кількість:</span>
        <span class="text-primary">{{totalAmount}}</span>
      </div>
      <div  class="text">        
        <div class="item__text" [innerHTML]="chartDescription"></div>
      </div>
    </dx-scroll-view>
  </div>
</div>
