<div class="w-measurement">

  <span class="btn-icon" (click)="stopDrawing()" id="delete">
    <span class="icon icon-eraser"></span>
  </span>

  <div class="w-switch w-switch--one">
    <dx-switch [value]="isShowMeasureDistance" (onValueChanged)="showDistance()" [rtlEnabled]="true">
    </dx-switch>
    <span>
      Кроки
    </span>
    <span></span>
  </div>

  <div [hidden]="!isShowSwitcher" class="w-switch w-switch--active">
    <dx-switch [value]="isSquareMeter" (onValueChanged)="changeSquareUnit()" [rtlEnabled]="true">
    </dx-switch>
    <span>
      м<sup>2</sup>
    </span>
    <span>
      Га
    </span>
  </div>

</div>
