import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";
import { ConfigService } from "../shared/utils/config.service";
import * as FileSaver from 'file-saver';
import { PrintFileEndpoint } from "../print/print-file.end-point.service";
import { Observable } from 'rxjs';


@Injectable()
export class CadastralXMLService {

  constructor(private sharedService: SharedService, private esriService: EsriService, private configService: ConfigService,
    private printFileEndpoint: PrintFileEndpoint
  ) {
    
  } 

  importGeodata(filedata): Observable<string> {
   
    let formdata = new FormData();

    formdata.append("file", filedata);
    let url = this.configService.UploadCadastralXMLUrl; // 'https://server.xgis.com.ua/gis/rest/services/test/ExcelToTable/GPServer/uploads/upload';

    let options: __esri.RequestOptions=
      {
        body: formdata,
      responseType: 'document'
      //responseType: 'json'
      };
    var self = this;
    let observable = new Observable<string>(observer => {
      this.esriService.Request(url, options).then(function (result) {
      // The requested data
      var geoJson = result.data;

        let itemID = geoJson.all[33].text;
        let _url = self.configService.ImportCadastralXMLUrl;

        let gp: __esri.Geoprocessor = self.esriService.Geoprocessor(_url);
      gp.outSpatialReference = self.sharedService.mapView.spatialReference;

        var params = { 'input_file': "{'itemID':" + itemID + "}" };

      let option: __esri.RequestOptions =
        {
         // callbackParamName: 'callback',
          responseType: 'json'
        };

      gp.submitJob(params, option).then(_result => {
        let test = _result;
        gp.getResultData(_result.jobId, 'result_msg').then(resultData => {
          let tempData = resultData.value;
            observer.next('Імпорт пройшов успішно');
          observer.complete();        

        }).catch(function (error) {
          //observer.next('Імпорт був завершен з помилкой: ' + error.message);
          observer.next('Імпорт даних був завершен');
          console.log("informative error message: ", error.message);
          observer.complete();
        });
      });


    }).catch(function (error) {
      //observer.next('Імпорт був завершен з помилкой: ' + error.message);
      observer.next('Імпорт файла був завершен');
      console.log("informative error message: ", error.message);
      observer.complete();
    });
    }
    );

    
    //this.esriService.Request(url, options).then(function (result) {
    //  // The requested data
    //  var geoJson = result.data;
           
    //  let itemID = geoJson.all[33].text;
    //  let url = self.configService.ImportGeodataServiceUrl;

    //  let gp: __esri.Geoprocessor = self.esriService.Geoprocessor(url);
    //  gp.outSpatialReference = self.sharedService.mapView.spatialReference;

    //  var params = { 'Input_Excel_File': "{'itemID':" + itemID + "}" };

    //  let option: __esri.requestEsriRequestOptions =
    //    {
    //      callbackParamName: 'callback',
    //      responseType: 'json'
    //    };

    //  gp.submitJob(params, option).then(result => {
    //    let test = result;
    //    gp.getResultData(result.jobId, 'Output_Table').then(resultData => {
    //      let tempData: __esri.FeatureSet = resultData.value;
          
    //      let map: __esri.Map = self.sharedService.map as __esri.Map;
    //      let layer: __esri.FeatureLayer = map.findLayerById(layerId) as __esri.FeatureLayer;
    //      tempData.features.forEach(f => {
    //        f.layer = layer;
    //        f.attributes["OBJECTID"] = f.attributes["OBJECTID_1"];
    //      });
    //      layer.applyEdits({
    //        updateFeatures: tempData.features
    //      }).then(applyResult => {
    //          return Observable.of('import is successed');
    //        });
    //    })
    //  });


    //}).catch(function (error) {
      
    //  console.log("informative error message: ", error.message);
     
    //});

    return observable;// Observable.of('import is failed');
  }
}
