
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EndpointFactory } from '../shared/endpoint-factory.service';
import { Observable } from 'rxjs';
import { ChartLegendInfo, ChartSettings, ChartType } from '../../models/chart/chart.model';

@Injectable()
export class ChartEndpointService extends EndpointFactory {

  private readonly _publicChartUrl: string = "/api/publicbookmark/publiccharts";

  get publicChartUrl() { return this.configService.getApiURI() + this._publicChartUrl; }

  getChartLegendsInfo(id): Observable<ChartSettings> {

    return new Observable(observer => {
      //let legendsInfo: ChartLegendInfo[] = [{ name: "Відсутні дані", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=1" },
      //{ name: "меньше 10 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=2" },
      //{ name: "10...20 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=3" },
      //{ name: "20...50 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=4" },
      //{ name: "50...200 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=5" },
      //{ name: "200...500 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=7" },
      //  { name: "більше 500 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=6" }];

      //observer.next(new ChartSettings("chart test", "chart test", ChartType.pie, legendsInfo));
      observer.complete();
    })
  }

  listChartsByBookmarkID(id): Promise<ChartSettings[]> {
    let endpointUrl = `${this.publicChartUrl}/${id}`;
    let str = JSON.stringify({ id });
    return this.http.post(endpointUrl, str)
      .toPromise().then((response: Response) => {
        return response;
      }).catch(error => {
        return this.handlePromiseError(error, () => this.listChartsByBookmarkID(id));
      });
  }


  listChartsByBookmarkIDTest(id): Observable<ChartSettings[]> {

    return new Observable(observer => {
      let charts: ChartSettings[] = [];

      //let legendsInfo: ChartLegendInfo[] = [{ name: "Відсутні дані", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=1" },
      //{ name: "меньше 10 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=2" },
      //{ name: "10...20 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=3" },
      //{ name: "20...50 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=4" },
      //{ name: "50...200 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=5" },
      //{ name: "200...500 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=7" },
      //{ name: "більше 500 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=6" }];
      
      //charts.push(new ChartSettings("Населення","Населення", ChartType.pie, legendsInfo));

      //let legendsInfo2: ChartLegendInfo[] = [{ name: "Відсутні дані", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=1" },
      //{ name: "до 3 тис грн на особу", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=2" },
      //  { name: "3...5 тис грн на особу", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=3" },
      //  { name: "5...10 тис грн на особу", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=4" },
      //  { name: "10...20 тис грн на особу", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=5" },
      //  { name: "більше 20 тис грн на особу", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=6" }];
      //charts.push(new ChartSettings("Бюджет","Бюджет", ChartType.pie, legendsInfo2));

      //let legendsInfo3: ChartLegendInfo[] = [{ name: "Відсутні дані", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=1" },
      //{ name: "меньше 10 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=2" },
      //{ name: "10...20 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=3" },
      //{ name: "20...50 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=4" },
      //{ name: "50...200 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=5" },
      //{ name: "200...500 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=7" },
      //{ name: "більше 500 тис осіб", url: "Adminpodil/Adminpodil/FeatureServer/21", expression: "Pidtup=6" }];

      //charts.push(new ChartSettings("Населення", "Населення", ChartType.pie, legendsInfo3));

      //let legendsInfo4: ChartLegendInfo[] = [{ name: "Відсутні дані", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=1" },
      //{ name: "до 3 тис грн на особу", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=2" },
      //{ name: "3...5 тис грн на особу", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=3" },
      //{ name: "5...10 тис грн на особу", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=4" },
      //{ name: "10...20 тис грн на особу", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=5" },
      //{ name: "більше 20 тис грн на особу", url: "Adminpodil/Adminpodil/FeatureServer/18", expression: "Pidtup=6" }];
      //charts.push(new ChartSettings("Бюджет", "Бюджет", ChartType.pie, legendsInfo4));

      //observer.next(charts);
      observer.complete();
    })
  }

  //get user regions
  //getUserRegionsEndpoint(userId?: string): Observable<Response> {
  // let endpointUrl = `${this.userRegionUrl}/${userId}`;
  
  //  return this.http.get<any>(endpointUrl, this.getAuthHeader()).pipe(
  //    catchError(error => {
  //      return this.handleError(error, () => this.getUserRegionsEndpoint(userId));
  //    }),);
  //}

}
