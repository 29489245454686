import { Injectable } from '@angular/core';
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";
import { ConfigService } from "../shared/utils/config.service";
import { Observable, interval, timer } from "rxjs";
import { takeWhile, map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';``
import { PrintFileEndpoint } from "./print-file.end-point.service";
import { Subscription } from 'rxjs-compat';


@Injectable()
export class PrintMapService {

  constructor(private sharedService: SharedService,
    private esriService: EsriService,
    private configService: ConfigService,
    private printFileEndpoint: PrintFileEndpoint) {
    
  }

  print: any;
  linkItems: any;
  interval: Subscription;

  getPrintBtn() {

    this.print = new this.esriService.Print({
      view: this.sharedService.mapView,     
      printServiceUrl: this.configService.PrintServiceUrl,
      container: 'print-map',
      templateOptions: {
        title: "Документ",
        scaleEnabled: false
      }
    });

    var self = this;

    this.esriService.WatchUtils.watch(this.print, 'exportedLinks.length', function (result) {
      self.linkItems = self.print.exportedLinks.items;
      let _interval = interval(1000);
      self.interval= _interval.pipe(
        takeWhile(i => i <= 150)
      ).subscribe(x => {
        self.checkLink()
      })

      //let _interval = interval(1000).take(150);
      //self.interval = _interval.subscribe(x => self.checkLink());
    })
    
  }

  checkLink() {
    try {
      if (!this.linkItems || this.linkItems.length == 0 || this.linkItems[this.linkItems.length - 1].state == 'error') {
        this.interval.unsubscribe();
        return;
      }
      if (this.linkItems[this.linkItems.length - 1].state == 'ready') {
        this.interval.unsubscribe();
        let fileUrl = this.linkItems[this.linkItems.length - 1].url;
        let fileName = this.linkItems[this.linkItems.length - 1].formattedName;
        if (!fileName || fileName == '') return;

        this.printFileEndpoint.getPrintedFileEndpoint(fileUrl).subscribe(
          (response) => {           
            FileSaver.saveAs(response, fileName);
          }
        );
      }
    } catch(e) {
      this.interval.unsubscribe();
    }
    
  }

}

