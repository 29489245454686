export class Constants {
  public static map = "esri/Map";
  public static basemap = "esri/Basemap";
  public static mapView = "esri/views/MapView"; 
  public static sceneView = "esri/views/SceneView";
  public static request = "esri/request";
  public static esriConfig = "esri/config";

  public static watchUtils = "esri/core/watchUtils";
  public static collection = "esri/core/Collection";

  public static draw = "esri/views/2d/draw/Draw";

  public static identityManager = "esri/identity/IdentityManager";
  
  public static query = "esri/rest/support/Query";
  public static queryTask = "esri/rest/query";
  public static geoprocessor = "esri/tasks/Geoprocessor";
  public static dataFile = "esri/tasks/support/DataFile";
  public static action = "esri/support/Action";
  public static actionButton = "esri/support/actions/ActionButton"; 

  public static imageryLayer = "esri/layers/ImageryLayer";
  public static mapImageLayer = "esri/layers/MapImageLayer";  
  public static tileLayer = "esri/layers/TileLayer";
  public static featureLayer = 'esri/layers/FeatureLayer';
  public static openStreetLayer = 'esri/layers/OpenStreetMapLayer';
  public static graphicsLayer = 'esri/layers/GraphicsLayer';
  public static subLayer = 'esri/layers/support/Sublayer';
  public static wmsLayer = 'esri/layers/WMSLayer';
  public static baseDynamicLayer = 'esri/layers/BaseDynamicLayer';
  public static vectorTileLayer = "esri/layers/VectorTileLayer"; 
  public static field = 'esri/layers/support/Field';

  public static uniqueValues = 'esri/smartMapping/statistics/uniqueValues';

  public static extent = "esri/geometry/Extent";
  public static polygon = "esri/geometry/Polygon";
  public static polyline = "esri/geometry/Polyline";
  public static geometryEngine = "esri/geometry/geometryEngine";
  public static spatialReference = "esri/geometry/SpatialReference";
  public static viewpoint = "esri/Viewpoint";
  public static point = "esri/geometry/Point";
  public static projection = "esri/geometry/projection";
  public static webMercatorUtils = "esri/geometry/support/webMercatorUtils";

  public static simpleFillSymbol = 'esri/symbols/SimpleFillSymbol';
  public static simpleMarkerSymbol = 'esri/symbols/SimpleMarkerSymbol';
  public static simpleLineSymbol = 'esri/symbols/SimpleLineSymbol';

  public static simpleRenderer = 'esri/renderers/SimpleRenderer';
  public static color = 'esri/Color';
   
  public static dojoFx = "dojox/gfx/fx";
  public static coreFx = "dojo/fx";
  public static dojoAspect = "dojo/aspect";
  public static graphic = "esri/Graphic";
  public static dojoLang = "dojo/_base/lang"; 
  public static dojodomReady = "dojo/domReady!";
  public static dojoNlsCommon = "dojo/i18n!esri/nls/common";

  public static basemapGallery = "esri/widgets/BasemapGallery";
  public static localBasemapsSource = "esri/widgets/BasemapGallery/support/LocalBasemapsSource";
  public static homeButton = "esri/widgets/Home";
  public static locateButton = "esri/widgets/Locate";
  public static scalebar = "esri/widgets/ScaleBar";
  public static search = "esri/widgets/Search";
  public static searchViewModel = "esri/widgets/Search/SearchViewModel";
  public static popup = "esri/widgets/Popup";
  public static print = "esri/widgets/Print";
  public static popupTemplate = "esri/PopupTemplate";
  public static zoom = "esri/widgets/Zoom";
  public static coordinateConversion = "esri/widgets/CoordinateConversion"; 
  public static coordinateConversionViewModel = "esri/widgets/CoordinateConversion/CoordinateConversionViewModel";

  public static sketchViewModel = "esri/widgets/Sketch/SketchViewModel";

  public static printNls = "dojo/i18n!esri/widgets/Print/nls/Print";
  public static searchNls = "dojo/i18n!esri/widgets/Search/nls/Search";
  public static popupNls = "dojo/i18n!esri/widgets/Popup/nls/Popup";
  public static homeButtonNls = "dojo/i18n!esri/widgets/Home/nls/Home";
  public static locateButtonNls = "dojo/i18n!esri/widgets/Locate/nls/Locate";
  public static zoomNls = "dojo/i18n!esri/widgets/Zoom/nls/Zoom";

  public static dojoOn = "dojo/on";
  public static dojoDeferred = "dojo/Deferred";
  
  public static overviewMap = "esri/widgets/OverviewMap";
 
  public static layerSwipe = "esri/dijit/LayerSwipe";
  public static basemapToggle = "esri/dijit/BasemapToggle";

  public static basemapId = "basemap";
  public static homebtnId = "homebtn";
  public static layerListId = "layerList";
  public static legendListId = "legendList";
  public static locateButtonId = "locatebtn";
  public static mapNodeId = "mapNode";
  public static searchId = "searchId";
  public static swipeId = "swipe";
  public static basemapToggleId = "basemaptgl";
  public static coordinateConversionId = "coordinateConversionId";

  public static lod = "esri/layers/support/LOD";

  public static textContent = "esri/popup/content/TextContent";

}

