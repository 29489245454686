import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { SharedService } from "../../../services/shared/shared.service";
import { DrawMeasureService } from "../../../services/draw/draw-measure.service";
import { DxSwitchModule } from 'devextreme-angular';
import { DrawPoligonService } from "../../../services/draw/draw-polygon.service";

@Component({
  selector: 'app-measurement-toolbar',
  templateUrl: './measurement-toolbar.component.html',
  styleUrls: ['./measurement-toolbar.component.scss']
})
export class MeasurementToolbarComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  subscriptionLabel: Subscription;
  subscriptionSquare: Subscription;
  //isStarted: boolean = false;
  isShowMeasureDistance: boolean;
  isShowSwitcher: boolean;
  isSquareMeter: boolean;
  constructor(private sharedService: SharedService, private drawMeasureService: DrawMeasureService, private drawPoligonService: DrawPoligonService) { }

  ngOnInit() {

    this.isShowSwitcher = this.sharedService.isShowSwitcher;

    this.subscription = this.sharedService.isPolygonDrawing().subscribe(value => {
      if (!this.isShowSwitcher) {
        this.isShowSwitcher = value;
        this.sharedService.isShowSwitcher = this.isShowSwitcher;
      }
    })

    this.subscriptionLabel = this.sharedService.ShowMeasureDistance().subscribe(value => {
      this.isShowMeasureDistance = value;
      this.drawMeasureService.setLabelLayer(this.isShowMeasureDistance);
    })

    this.subscriptionSquare = this.sharedService.getCurrentSquareUnit().subscribe(value => {
      this.isSquareMeter = value == "square-meters";
    })

  }

  stopDrawing() {
    this.drawMeasureService.removeGraphic();
  }

  showDistance() {
    //this.isShowMeasureDistance = !this.isShowMeasureDistance;
    this.sharedService.setMeasureDistance(!this.isShowMeasureDistance);     
  }

  changeSquareUnit() {
    if (this.isSquareMeter) {
      this.showHectare();
    } else {
      this.showMeter();
    }
  }

  showMeter() {
    this.sharedService.setCurrentSquareUnit("square-meters");
    this.drawPoligonService.changeSquareLabels("square-meters");
  }

  showHectare() {
    this.sharedService.setCurrentSquareUnit("hectares");
    this.drawPoligonService.changeSquareLabels("hectares");
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.subscriptionLabel.unsubscribe();
    this.subscriptionSquare.unsubscribe();
  }

}
